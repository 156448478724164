import { useMemo, useState } from "react";
import { Button, DatePicker, Flex, Input, Modal, Space, Table } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { format, getRouteByName } from "lib";
import { useDeleteTemplateMutation, useGetTemplatesQuery } from "api/templates";
import { ISort, useObjectState, useSorter } from "hooks";
import { TTemplateShort } from "api/templates.types";
import { ViewTemplateModal } from "../components";

export const TemplatesPage = () => {
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();

  const [viewTemplate, setViewTemplate] = useState<number>();
  const [sort, setSort] = useState<ISort>({
    sortKey: "desc",
    fieldKey: "createdAt",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50)

  const [filters, setFilters] = useObjectState<{
    name?: string;
    "createdAt[after]"?: string;
    "createdAt[before]"?: string;
  }>({});

  const { returnSorterProps } = useSorter(sort, setSort);

  const {
    data: templatesData,
    isLoading: isTemplatesLoading,
    isFetching: isTemplatesFetching,
  } = useGetTemplatesQuery({
    page,
    itemsPerPage: pageSize,
    ...filters,
    [`order[${sort.fieldKey}]`]: sort.sortKey,
  });

  const [deleteMutation] = useDeleteTemplateMutation();

  const columns = useMemo<ColumnsType<TTemplateShort>>(
    () => [
      {
        dataIndex: "name",
        ...returnSorterProps("name", "Template name"),
      },
      {
        dataIndex: "createdAt",
        render: (createdAt) => format(createdAt),
        ...returnSorterProps("createdAt", "Created at"),
      },
      {
        title: "Actions",
        dataIndex: "id",
        width: 100,
        align: "right",
        render: (id) => (
          <Space size={24}>
            <EyeOutlined
              className="with-action"
              onClick={() => setViewTemplate(id)}
            />
            <Link to={getRouteByName("EditTemplatePage", { id })}>
              <EditOutlined />
            </Link>

            <DeleteOutlined
              style={{ color: "var(--ant-red-4)" }}
              onClick={() =>
                modal.confirm({
                  content: "Are you sure about deleting this template?",
                  onOk: () => deleteMutation(id),
                  okButtonProps: { danger: true },
                })
              }
            />
          </Space>
        ),
      },
    ],
    [returnSorterProps, deleteMutation, modal]
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Flex justify="space-between">
        <Space>
          <Input.Search
            placeholder="Search"
            style={{ width: 200 }}
            value={filters.name}
            onChange={(e) => setFilters({ name: e.target.value })}
            allowClear
          />

          <DatePicker.RangePicker
            placeholder={["Created from", "Created to"]}
            value={
              filters["createdAt[after]"] !== undefined
                ? [
                    dayjs(filters["createdAt[after]"], "YYYY-MM-DD"),
                    dayjs(filters["createdAt[before]"], "YYYY-MM-DD"),
                  ]
                : undefined
            }
            onChange={(date) =>
              setFilters({
                "createdAt[after]":
                  date && date[0] ? date[0].format("YYYY-MM-DD") : undefined,
                "createdAt[before]":
                  date && date[1] ? date[1].format("YYYY-MM-DD") : undefined,
              })
            }
          />
        </Space>

        <Button
          type="primary"
          onClick={() => navigate(getRouteByName("CreateTemplatePage"))}
        >
          Create Template
        </Button>
      </Flex>

      <Table
        dataSource={templatesData?.data}
        columns={columns}
        loading={isTemplatesLoading || isTemplatesFetching}
        rowKey="id"
        pagination={{
          total: templatesData?.pagination.totalItems,
          current: templatesData?.pagination.current,
          pageSize: pageSize,
          onChange: (nextPage, pageSize) => {
            if (nextPage) setPage(nextPage);
            if (pageSize) setPageSize(pageSize);
          },
          showSizeChanger: true,
        }}
        scroll={{ x: 800 }}
      />

      {contextHolder}

      {viewTemplate && (
        <ViewTemplateModal
          id={viewTemplate}
          onCancel={() => setViewTemplate(undefined)}
        />
      )}
    </Space>
  );
};
