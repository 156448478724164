import { useEffect, useMemo, useState } from "react";
import {
  App,
  Button,
  DatePicker,
  Dropdown,
  Flex,
  Input,
  Popover,
  Space,
  Spin,
  Table,
  Tag,
  theme,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import {
  FlagOutlined,
  FormOutlined,
  HistoryOutlined,
  ShareAltOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  dateFormat,
  EUserRoles,
  format,
  getRouteByName,
  InspectionStatusColors,
  isThreeBusinessDaysPast,
} from "lib";
import {
  handleRTKQueryError,
  useGetInfiniteAgentsQuery,
  useGetInfiniteClientsQuery,
  useGetInspectionsQuery,
  useSetInspectionStatusMutation,
  useShareSummaryMutation,
} from "api";
import { useAppSelector } from "store";
import { TInspection } from "../api/inspections.types";
import { ISort, useObjectState, useSorter } from "../hooks";
import { SmartSelectPagination } from "../components/SmartSelectWithPagination";
import { SetOnHoldFlag } from "../components";

export const InspectionsPage = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const { modal } = App.useApp();
  const { token } = theme.useToken();

  const [sort, setSort] = useState<ISort>({
    sortKey: "desc",
    fieldKey: "createdAt",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const [filters, setFilters] = useObjectState<{
    search?: string;
    clients?: number[];
    agents?: number[];
    "createdAt[after]"?: string;
    "createdAt[before]"?: string;
  }>({});

  const { returnSorterProps } = useSorter(sort, setSort);

  const { data, isLoading, isFetching, refetch } = useGetInspectionsQuery({
    page,
    itemsPerPage: pageSize,
    ...filters,
    [`order[${sort.fieldKey}]`]: sort.sortKey,
  });

  const [
    updateStatusInspection,
    { data: updateStatusData, error: updateStatusError },
  ] = useSetInspectionStatusMutation();

  const [
    shareSummaryMutation,
    { isSuccess: isShareSummarySuccess, error: shareSummaryError },
  ] = useShareSummaryMutation();

  const { availableStatuses, loggedUserRoles, onLine } = useAppSelector(
    (state) => ({
      loggedUserRoles: state.loggedAdmin.parsedRoles,
      availableStatuses: state.inspectionsApi.queries.getAvailableStatuses
        ?.data as Record<string, string>,
      onLine: state.appSettings.onLine,
    })
  );

  const columns = useMemo<ColumnsType<TInspection>>(
    () => [
      {
        dataIndex: "onHold",
        width: 20,
        render: (onHold, inspection) =>
          onHold && (
            <Tooltip title={inspection.holdReason}>
              <FlagOutlined style={{ color: token.red5 }} />
            </Tooltip>
          ),
      },
      {
        dataIndex: "agent",
        render: (agent) => (
          <>
            {agent.email}({agent.firstName} {agent.lastName})
          </>
        ),
        ...returnSorterProps("agent.email", "Inspector"),
      },
      {
        dataIndex: "address",
        ...returnSorterProps("address", "Address"),
      },
      {
        dataIndex: "createdAt",
        render: (createdAt) => format(createdAt, dateFormat),
        ...returnSorterProps("createdAt", "Date"),
      },
      {
        dataIndex: "status",
        render: (status) => (
          <Tag
            color={
              InspectionStatusColors[
                status as keyof typeof InspectionStatusColors
              ]
            }
          >
            {availableStatuses?.[status]}
          </Tag>
        ),
        ...returnSorterProps("status", "Status"),
      },
      {
        title: "Actions",
        align: "right",
        width: 290,
        dataIndex: "id",
        render: (id, inspection) => (
          <Space size={24}>
            {loggedUserRoles?.[EUserRoles.ROLE_INDOOR_CLIENT] &&
              !inspection.isClientLinkExpired && (
                <Link to={getRouteByName("ClientSummaryPage", { id })}>
                  View summary
                </Link>
              )}

            {!loggedUserRoles?.[EUserRoles.ROLE_INDOOR_CLIENT] &&
              ["COMPLETED_BY_AGENT", "NEED_FINAL_APPROVAL"].includes(
                inspection.status
              ) && <Link to={getRouteByName("SummaryPage", { id })}>View</Link>}

            {!loggedUserRoles?.[EUserRoles.ROLE_INDOOR_CLIENT] &&
              !loggedUserRoles?.[EUserRoles.ROLE_INDOOR_MANAGER] &&
              inspection.status === "CREATED" && (
                <Link to={getRouteByName("CompleteInspectionPage", { id })}>
                  Start inspection
                </Link>
              )}

            {(loggedUserRoles?.[EUserRoles.ROLE_INDOOR_AGENT] ||
              loggedUserRoles?.[EUserRoles.ROLE_INDOOR_ADMIN]) &&
              inspection.status === "APPROVED_TO_SEND" && (
                <ShareAltOutlined
                  className="with-action"
                  onClick={() =>
                    modal.confirm({
                      title: "Share summary",
                      content: (
                        <>
                          Are you sure you want to share this inspection summary
                          for 3 days?
                        </>
                      ),
                      onOk: () => shareSummaryMutation({ id, days: 3 }),
                    })
                  }
                />
              )}

            {(loggedUserRoles?.[EUserRoles.ROLE_INDOOR_AGENT] ||
              loggedUserRoles?.[EUserRoles.ROLE_INDOOR_ADMIN]) && (
              <>
                <FormOutlined
                  className="with-action"
                  onClick={() =>
                    navigate(getRouteByName("EditInspectionPage", { id }))
                  }
                />

                <StopOutlined
                  className="with-action"
                  onClick={() =>
                    updateStatusInspection({ id, status: "CANCELLED" })
                  }
                />

                {availableStatuses && (
                  <Dropdown
                    menu={{
                      items: Object.keys(availableStatuses).map((i) => ({
                        label: availableStatuses[i],
                        key: i,
                        onClick: () =>
                          modal.confirm({
                            title: "Status change",
                            content: (
                              <>
                                Are you sure you want to change inspection
                                status to{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  "{availableStatuses[i]}"
                                </span>
                                ?
                              </>
                            ),
                            onOk: () =>
                              updateStatusInspection({ id, status: i }),
                          }),
                      })),
                    }}
                    trigger={["click"]}
                  >
                    <HistoryOutlined className="with-action" />
                  </Dropdown>
                )}

                <SetOnHoldFlag inspectionId={id} isOnHold={inspection.onHold} />
              </>
            )}
          </Space>
        ),
      },
    ],
    [
      navigate,
      availableStatuses,
      returnSorterProps,
      modal,
      updateStatusInspection,
      loggedUserRoles,
      shareSummaryMutation,
    ]
  );

  useEffect(() => {
    if (updateStatusData) {
      notification.success({ message: "Status was successfully changes" });
      navigate(
        getRouteByName("EditInspectionPage", { id: updateStatusData.id })
      );
    }
  }, [updateStatusData, navigate, notification]);

  useEffect(() => {
    if (updateStatusError) {
      notification.error({ message: "An unexpected error occurred" });
    }
  }, [updateStatusError, notification]);

  useEffect(() => {
    if (isShareSummarySuccess) {
      notification.success({ message: "Summary was successfully shared" });
    }
  }, [isShareSummarySuccess, notification]);

  useEffect(() => {
    if (shareSummaryError) {
      notification.error({ message: handleRTKQueryError(shareSummaryError) });
    }
  }, [shareSummaryError, notification]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!data?.data && onLine) {
        refetch();
      }
    }, 3000);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [data, onLine, refetch]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search placeholder="Search" style={{ width: 200 }} />

          {(loggedUserRoles?.[EUserRoles.ROLE_INDOOR_AGENT] ||
            loggedUserRoles?.[EUserRoles.ROLE_INDOOR_ADMIN]) && (
            <>
              <SmartSelectPagination
                mode="multiple"
                query={useGetInfiniteAgentsQuery}
                value={filters.agents}
                onChange={(val) => setFilters({ agents: val })}
                style={{ minWidth: 150 }}
                placeholder="Inspectors"
                allowClear
              />

              <SmartSelectPagination
                mode="multiple"
                query={useGetInfiniteClientsQuery}
                value={filters.clients}
                onChange={(val) => setFilters({ clients: val })}
                style={{ minWidth: 150 }}
                placeholder="Clients"
                allowClear
              />
            </>
          )}

          <DatePicker.RangePicker
            placeholder={["Created from", "Created to"]}
            value={
              filters["createdAt[after]"] !== undefined
                ? [
                    dayjs(filters["createdAt[after]"], "YYYY-MM-DD"),
                    dayjs(filters["createdAt[before]"], "YYYY-MM-DD"),
                  ]
                : undefined
            }
            onChange={(date) =>
              setFilters({
                "createdAt[after]":
                  date && date[0] ? date[0].format("YYYY-MM-DD") : undefined,
                "createdAt[before]":
                  date && date[1] ? date[1].format("YYYY-MM-DD") : undefined,
              })
            }
          />
        </Space>

        {(loggedUserRoles?.[EUserRoles.ROLE_INDOOR_AGENT] ||
          loggedUserRoles?.[EUserRoles.ROLE_INDOOR_ADMIN]) && (
          <Button
            type="primary"
            onClick={() => navigate(getRouteByName("CreateInspectionPage"))}
          >
            Add inspection
          </Button>
        )}
      </Flex>

      <Table
        dataSource={data?.data}
        columns={columns}
        rowKey="id"
        rowClassName={(record) =>
          !["SENT_TO_CLIENT", "REVIEWED_BY_CLIENT", "CANCELLED"].includes(
            record.status
          ) && isThreeBusinessDaysPast(record.completedAt)
            ? "highlight-red2"
            : ""
        }
        scroll={{ x: 800 }}
        pagination={{
          total: data?.pagination.totalItems,
          current: data?.pagination.current,
          pageSize: pageSize,
          onChange: (nextPage, pageSize) => {
            if (nextPage) setPage(nextPage);
            if (pageSize) setPageSize(pageSize);
          },
          showSizeChanger: true,
        }}
      />
    </Spin>
  );
};
