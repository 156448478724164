import { useEffect, useMemo, useState } from "react";
import {
  App,
  Button,
  Card,
  DatePicker,
  Divider,
  Flex,
  Form,
  Grid,
  Input,
  Select,
  Space,
  Spin,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  IUser,
  IWithPagination,
  useCreateInspectionMutation,
  useGetAvailablePropertyTypesQuery,
  useGetInfiniteAgentsQuery,
  useGetInfiniteClientsQuery,
  useGetInspectionQuery,
  useGetStatesListQuery,
  useUpdateInspectionMutation,
} from "api";
import { getRouteByName, today } from "lib";
import { SmartSelectPagination } from "../SmartSelectWithPagination";
import { EditUserForm } from "../users/EditUserForm";
import { useAppSelector } from "../../store";
import { TInspectionClient } from "../../api/inspections.types";

export const EditInspectionForm = () => {
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { xxl } = Grid.useBreakpoint();
  const { notification } = App.useApp();

  const [showAddClient, setShowAddClient] = useState(false);

  const {
    parsedRoles,
    data: loggedUserData,
    clientsList,
  } = useAppSelector((state) => ({
    parsedRoles: state.loggedAdmin.parsedRoles,
    data: state.loggedAdmin.data,
    clientsList: state.usersApi.queries.getInfiniteClients
      ?.data as IWithPagination<TInspectionClient>,
  }));

  const { data: inspectionData, isLoading: isInspectionDataLoading } =
    useGetInspectionQuery(
      {
        id: Number(params.id),
      },
      { skip: !params.id }
    );

  const { data: propertyTypesData, isLoading: isPropertyTypesLoading } =
    useGetAvailablePropertyTypesQuery();

  const [
    createMutation,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      error: createError,
    },
  ] = useCreateInspectionMutation();

  const [
    updateMutation,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdateInspectionMutation();

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      notification.success({ message: "Inspection was saved" });
      navigate(getRouteByName("InspectionsPage"));
    }
  }, [isCreateSuccess, isUpdateSuccess, notification, navigate]);

  useEffect(() => {
    if (createError || updateError) {
      notification.error({ message: "An unexpected erro occured" });
    }
  }, [createError, updateError, notification]);

  useEffect(() => {
    if (inspectionData) {
      form.setFieldsValue({
        ...inspectionData,
        scheduledDate: dayjs(inspectionData.scheduledDate),
        templates: inspectionData.templates?.map((i: { id: number }) => i.id),
      });
    }
  }, [inspectionData, form]);

  const onFinish = (values: Record<string, any>) => {
    const request = params.id ? updateMutation : createMutation;

    form.validateFields().then(() =>
      request({
        ...values,
        id: params.id,
      })
    );
  };

  const isEditMode = Boolean(params.id);

  const onCreateUserSuccess = (createdUser: IUser) => {
    if (createdUser.id) {
      form.setFieldValue(["client", "id"], createdUser.id);
    }
  };

  const selectedClientId = Form.useWatch(["client", "id"], form);

  const selectedClient = useMemo(
    () =>
      clientsList?.data.find(
        (client: TInspectionClient) => client.id === selectedClientId
      ),
    [selectedClientId, clientsList]
  );

  return (
    <>
      <Spin
        spinning={isCreateLoading || isUpdateLoading || isInspectionDataLoading}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Space
            styles={{ item: { width: xxl ? "32.5%" : "100%" } }}
            style={{ width: "100%", justifyContent: "space-between" }}
            direction="horizontal"
            align="start"
            wrap
          >
            <Card title="Inspector">
              <Form.Item
                name={["agent", "id"]}
                label="Select inspector"
                rules={[{ required: true, message: "This field is required" }]}
                initialValue={
                  parsedRoles?.ROLE_INDOOR_AGENT
                    ? loggedUserData?.id
                    : undefined
                }
              >
                <SmartSelectPagination
                  query={useGetInfiniteAgentsQuery}
                  placeholder="Select inspector"
                />
              </Form.Item>

              <Form.Item
                name="scheduledDate"
                label="Date of Inspection"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker
                  disabledDate={(data) => data < today}
                  showNow={false}
                  format="MM-DD-YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Card>

            <Card title="Client">
              <Form.Item label="Client">
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name={["client", "id"]}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                    noStyle
                  >
                    <SmartSelectPagination
                      query={useGetInfiniteClientsQuery}
                      disabled={isEditMode}
                      placeholder="Select a existent client"
                      allowClear
                    />
                  </Form.Item>

                  <Form.Item noStyle>
                    <Button onClick={() => setShowAddClient(true)}>
                      Add new
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Form.Item>

              {selectedClientId && (
                <>
                  <Divider orientation="left" plain>
                    <strong>Selected client details</strong>
                  </Divider>

                  <Form.Item
                    label={<strong>First name</strong>}
                    rules={[
                      {
                        required: true,
                        message: "This field can not be empty",
                      },
                    ]}
                    layout="horizontal"
                    style={{ marginBottom: 0 }}
                  >
                    {selectedClient?.firstName}
                  </Form.Item>

                  <Form.Item
                    label={<strong>Last name</strong>}
                    rules={[
                      {
                        required: true,
                        message: "This field can not be empty",
                      },
                    ]}
                    layout="horizontal"
                    style={{ marginBottom: 0 }}
                  >
                    {selectedClient?.lastName}
                  </Form.Item>

                  <Form.Item
                    label={<strong>Email</strong>}
                    rules={[
                      {
                        required: true,
                        message: "This field can not be empty",
                      },
                    ]}
                    layout="horizontal"
                    style={{ marginBottom: 0 }}
                  >
                    {selectedClient?.email}
                  </Form.Item>

                  <Form.Item
                    label={<strong>Phone</strong>}
                    rules={[
                      {
                        required: true,
                        message: "This field can not be empty",
                      },
                    ]}
                    layout="horizontal"
                    style={{ marginBottom: 0 }}
                  >
                    {selectedClient?.phone}
                  </Form.Item>

                  <Form.Item
                    label={<strong>Company name</strong>}
                    layout="horizontal"
                    style={{ marginBottom: 0 }}
                  >
                    {selectedClient?.companyName}
                  </Form.Item>

                  <Form.Item
                    label={<strong>CC</strong>}
                    layout="horizontal"
                    style={{ marginBottom: 0 }}
                  >
                    {selectedClient?.ccEmailList?.join(", ")}
                  </Form.Item>
                </>
              )}
            </Card>

            <Card title="Property">
              <Form.Item
                name="propertyType"
                label="Type"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  options={
                    propertyTypesData &&
                    Object.keys(propertyTypesData)?.map((i) => ({
                      label: propertyTypesData[i],
                      value: i,
                    }))
                  }
                  loading={isPropertyTypesLoading}
                  disabled={isEditMode}
                  placeholder="Choose a property type"
                  allowClear
                />
              </Form.Item>

              <Form.Item name="addressState" label="State">
                <SmartSelectPagination
                  query={useGetStatesListQuery}
                  placeholder="Choose a state"
                />
              </Form.Item>

              <Form.Item name="addressCity" label="City">
                <Input placeholder="Write city" />
              </Form.Item>

              <Form.Item name="address" label="Address line">
                <Input placeholder="Write address" />
              </Form.Item>

              <Form.Item name="addressZip" label="Zip">
                <Input placeholder="Choose a state" />
              </Form.Item>
            </Card>
          </Space>

          <Flex justify="end" style={{ marginTop: 12 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </Spin>

      {showAddClient && (
        <EditUserForm
          onClose={() => setShowAddClient(false)}
          onCreateUserSuccess={onCreateUserSuccess}
          isCreateClientMode
        />
      )}
    </>
  );
};
