import { useState } from "react";
import {
  Flex,
  Form,
  Upload,
  UploadFile,
  UploadProps,
  Image,
  Button,
} from "antd";
import { TInspection } from "../../api/inspections.types";
import {
  simplePOSTRequest,
  useGetInfiniteProductsQuery,
  useGetStatesListQuery,
} from "../../api";
import { PlusOutlined } from "@ant-design/icons";
import { FileType, getBase64 } from "../../lib";
import { SmartSelectPagination } from "../SmartSelectWithPagination";

interface IInspectionCoverPage {
  inspection?: TInspection;
  onAddRoomClick?: () => void;
}

export const InspectionCoverPage = ({
  inspection,
  onAddRoomClick,
}: IInspectionCoverPage) => {
  const [form] = Form.useForm();

  const [imageUrl, setImageUrl] = useState<string>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const { data: statesData } = useGetStatesListQuery(undefined);

  const onImageChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType).then((res) =>
        setImageUrl(res)
      );
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  return (
    <Form form={form}>
      <Form.Item label={<strong>Customer name</strong>}>
        {inspection?.client.firstName} {inspection?.client.lastName}
      </Form.Item>

      <Form.Item label={<strong>Location type</strong>}>
        {inspection?.propertyType}
      </Form.Item>

      <Form.Item label={<strong>State</strong>}>
        {
          statesData?.data.find(
            (state) => state.value === inspection?.addressState
          )?.label
        }
      </Form.Item>

      <Form.Item label={<strong>City</strong>}>
        {inspection?.addressCity}
      </Form.Item>

      <Form.Item label={<strong>Address</strong>}>
        {inspection?.address}
      </Form.Item>

      <Form.Item label={<strong>Zip</strong>}>
        {inspection?.addressZip}
      </Form.Item>

      <Form.Item name="image" label={<strong>Image</strong>}>
        <Upload
          accept=".jpg,.png"
          multiple={false}
          listType="picture-card"
          customRequest={async ({ file, onProgress, onSuccess, onError }) => {
            await simplePOSTRequest("s3/generate-url", {
              filename: `inspection/${
                typeof file === "object" && "name" in file ? file.name : ""
              }`,
              expiration: 60 * 5,
            })
              .then((res) => res.json())
              .then((res) => {
                const xhr = new XMLHttpRequest();

                xhr.upload.onprogress = (event) => {
                  if (event.lengthComputable) {
                    const percent = Math.round(
                      (event.loaded / event.total) * 100
                    );
                    onProgress && onProgress({ percent });
                  }
                };

                xhr.onload = () => {
                  if (xhr.status === 200) {
                    onSuccess && onSuccess(xhr.responseText);
                  } else {
                    onError && onError(new Error("Upload failed."));
                  }
                };

                xhr.onerror = () => {
                  onError && onError(new Error("Upload error."));
                };

                xhr.open("PUT", res.url);

                xhr.send(file);
              });
          }}
          onChange={onImageChange}
          onPreview={handlePreview}
          onRemove={() => setImageUrl(undefined)}
        >
          {imageUrl ? null : (
            <Flex gap={2}>
              <PlusOutlined />
              Upload
            </Flex>
          )}
        </Upload>
      </Form.Item>

      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}

      <Flex justify="space-between" gap={12} style={{ marginTop: 48 }}>
        <div />

        <Flex gap={12}>
          <Button onClick={onAddRoomClick} type="primary">
            Add room
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
