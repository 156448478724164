import { useEffect, useMemo } from "react";
import { Form, Input, Modal, ModalProps, notification } from "antd";
import {
  useCreateSampleTypeMutation,
  useGetSampleTypeQuery,
  useUpdateSampleTypeMutation,
} from "api";

const formName = "sample-types-form";

type Props = {
  sampleTypeId?: number;
  onClose: () => void;
} & ModalProps;

export const SampleTypesFormModal = ({
  sampleTypeId,
  onClose,
  ...props
}: Props) => {
  const [form] = Form.useForm();

  const { data, isFetching } = useGetSampleTypeQuery(
    { id: Number(sampleTypeId) },
    { skip: !sampleTypeId }
  );

  useEffect(() => {
    if (data && sampleTypeId) {
      form.setFieldsValue(data);
    }
  }, [data, form, sampleTypeId]);

  const [
    createMutation,
    {
      isSuccess: isCreateSuccess,
      error: createError,
      isLoading: isCreateLoading,
    },
  ] = useCreateSampleTypeMutation();

  const [
    updateMutation,
    {
      isSuccess: isUpdateSuccess,
      error: updateError,
      isLoading: isUpdateLoading,
    },
  ] = useUpdateSampleTypeMutation();

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      notification.success({ message: "Changes was saved" });

      onClose();
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (createError || updateError) {
      notification.error({ message: "Changes can not be saved" });
    }
  }, [createError, updateError]);

  const handleSubmit = (values: { name: string }) =>
    sampleTypeId
      ? updateMutation({ ...values, id: sampleTypeId })
      : createMutation(values);

  const modalText = useMemo(
    () => (sampleTypeId ? "Edit sample type" : "Create sample type"),
    [sampleTypeId]
  );

  return (
    <Modal
      loading={isFetching}
      title={modalText}
      okText={modalText}
      destroyOnClose
      afterClose={form.resetFields}
      onCancel={onClose}
      styles={{ body: { margin: "2rem 0" } }}
      okButtonProps={{
        htmlType: "submit",
        form: formName,
        loading: isCreateLoading || isUpdateLoading,
      }}
      {...props}
    >
      <Form
        form={form}
        name={formName}
        onFinish={handleSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 15 }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
