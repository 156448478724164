import { useEffect } from "react";
import { Badge, Form, FormInstance, InputNumber, Typography } from "antd";
import { TInspectionQuestion } from "api/templates.types";
import { FlagOutlined } from "@ant-design/icons";
import { useInspectionQuestionFlag } from "hooks";

interface IInspectionNumberQuestion {
  question: TInspectionQuestion;
  form: FormInstance;
}

export const InspectionNumberQuestion = ({
  question,
  form,
}: IInspectionNumberQuestion) => {
  const showFlag = useInspectionQuestionFlag(question, form);

  useEffect(() => {
    form.setFields([
      {
        name: ["inspectionAnswers", question.id, "flagged"],
        value: showFlag,
      },
    ]);
  }, [showFlag]);

  return (
    <Form.Item
      name={[question.id, "textResponse"]}
      key={question.id}
      label={displayQuestionLabel(question.text, showFlag)}
      className="input-label-without-overflow"
      required={question.required}
      rules={[
        { required: question.required, message: "This field is required" },
      ]}
      initialValue={question.inspectionQuestionAnswers[0]?.textResponse}
    >
      <InputNumber placeholder="Number answer" style={{ width: 150 }} />
    </Form.Item>
  );
};

export const displayQuestionLabel = (text: string, showFlag: boolean) => {
  const label = <Typography.Text strong>{text}</Typography.Text>;

  if (showFlag) {
    return (
      <>
        <Badge
          count={<FlagOutlined style={{ color: "red" }} />}
          offset={[-10, 0]}
        >
          {" "}
        </Badge>

        {label}
      </>
    );
  }

  return label;
};
