import { useEffect } from "react";
import { save } from "react-cookies";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryParams } from "../hooks";
import { getRouteByName } from "../lib";
import { setAuthTokens, useAppDispatch } from "../store";

export const ClientSummaryRedirectPage = () => {
  const params = useParams();
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (queryParams.token) {
      save("access-token", queryParams.token, { path: "/" });
      dispatch(setAuthTokens({ token: queryParams.token }));

      navigate(getRouteByName("ClientSummaryPage", { id: params.id }));

      return;
    }

    navigate(getRouteByName("LogInPage"));
  }, [queryParams, navigate, dispatch, params]);

  return <></>;
};
