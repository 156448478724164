import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig, IWithPagination } from "./index";
import { defaultQS } from "lib";
import { TProduct } from "./products.types";

export const productsApi = createApi({
  ...defaultApiConfig,
  reducerPath: "productsApi",
  tagTypes: ["Products", "Product"],
  endpoints: (builder) => ({
    getProducts: builder.query<IWithPagination<TProduct>, Record<string, any>>({
      query: (params) => `products?itemsPerPage=10&${defaultQS(params)}`,
      providesTags: ["Products"],
    }),
    getInfiniteProducts: builder.query<
      IWithPagination<{ label: string; value: number }>,
      Record<string, any>
    >({
      query: (params) => `products?itemsPerPage=10&${defaultQS(params)}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData) => {
        if (responseData.pagination.current === 1) {
          currentCacheData.pagination = responseData.pagination;
          currentCacheData.data = responseData.data;

          return;
        }

        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (baseQueryReturnValue: IWithPagination<TProduct>) => ({
        data: baseQueryReturnValue.data.map((user) => ({
          label: user.name,
          value: user.id,
        })),
        pagination: baseQueryReturnValue.pagination,
      }),
      providesTags: ["Products"],
    }),
    getProduct: builder.query<TProduct, { id: number }>({
      query: ({ id }) => `products/${id}`,
      providesTags: ["Product"],
    }),
    createProduct: builder.mutation({
      query: (body) => ({
        url: "products",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Products"],
    }),
    updateProduct: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `products/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Products", "Product"],
    }),
    deleteProduct: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `products/${id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Products"],
    }),
  }),
  keepUnusedDataFor: 60 * 60 * 5,
});

export const {
  useGetProductsQuery,
  useGetInfiniteProductsQuery,
  useGetProductQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useCreateProductMutation,
} = productsApi;
