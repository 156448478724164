import { App, Form, Input, Modal } from "antd";
import {
  TProduct,
  useCreateReadyAnswerMutation,
  useUpdateReadyAnswerMutation,
} from "api";
import { useEffect, useMemo } from "react";
import { setValueIfAdd } from "../templates";
import { EditReadyOption } from "./EditReadyOption";

interface IEditUserForm {
  product?: TProduct;
  onClose: () => void;
}

export const EditReadyAnswersForm = ({ product, onClose }: IEditUserForm) => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const [
    createUserMutation,
    {
      isSuccess: isCreateUserSuccess,
      error: createUserError,
      isLoading: isCreateUserLoading,
    },
  ] = useCreateReadyAnswerMutation();

  const [
    updateUserMutation,
    {
      isSuccess: isUpdateUserSuccess,
      error: updateUserError,
      isLoading: isUpdateUserLoading,
    },
  ] = useUpdateReadyAnswerMutation();

  useEffect(() => {
    if (product)
      form.setFieldsValue({
        ...product,
        image: [{ url: product.image }],
      });
  }, [product]);

  useEffect(() => {
    if (isCreateUserSuccess || isUpdateUserSuccess) {
      notification.success({ message: "Changes was saved" });
      onClose();
    }
  }, [isCreateUserSuccess, isUpdateUserSuccess]);

  useEffect(() => {
    if (createUserError || updateUserError) {
      notification.error({ message: "Changes can not be saved" });
    }
  }, [createUserError, updateUserError]);

  const isEditMode = useMemo(() => Boolean(product), []);
  const modalText = useMemo(
    () => (isEditMode ? "Edit ready answer" : "Create ready answer"),
    [isEditMode]
  );

  const onFinish = () => {
    const formValues = form.getFieldsValue();
    const mutation = isEditMode ? updateUserMutation : createUserMutation;

    form
      .validateFields()
      .then(() => {
        mutation({
          ...formValues,
          id: product?.id,
        });
      })
      .catch((e) => console.log(e, "e"));
  };

  return (
    <Modal
      title={modalText}
      okText={modalText}
      okButtonProps={{
        onClick: onFinish,
        loading: isCreateUserLoading || isUpdateUserLoading,
      }}
      onCancel={onClose}
      styles={{ body: { margin: "2rem 0" } }}
      open
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Options">
          <Form.List
            name={["options"]}
            initialValue={setValueIfAdd([{ text: "" }], isEditMode)}
          >
            {(options, { add, remove }) =>
              options.map((option, key) => (
                <EditReadyOption
                  option={option}
                  onAdd={add}
                  onRemove={remove}
                  key={option.key}
                  isLastField={key + 1 === options.length}
                  form={form}
                  isEditMode={isEditMode}
                />
              ))
            }
          </Form.List>
        </Form.Item>
      </Form>
    </Modal>
  );
};
