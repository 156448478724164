import { Alert, App, Form, Input, Modal } from "antd";
import { useAppSelector } from "store";
import { useUpdatePasswordMutation } from "api";
import { getErrorMessage } from "lib";
import { useEffect } from "react";

interface SetPasswordModalInterface {
  onCancel: () => void;
}

export const SetPasswordModal = ({ onCancel }: SetPasswordModalInterface) => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const loggedAdmin = useAppSelector((state) => state.loggedAdmin);

  const [updatePassMutation, { isLoading, isSuccess, error }] =
    useUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: "Your password was resetted" });
      onCancel();
    }
  }, [isSuccess]);

  return (
    <Modal
      open={true}
      title="Set new password"
      okText="Set"
      cancelText="Close"
      styles={{ body: { margin: "1rem 0" } }}
      onCancel={onCancel}
      okButtonProps={{ loading: isLoading }}
      onOk={() =>
        updatePassMutation({
          id: loggedAdmin.data?.id,
          ...form.getFieldsValue(),
        })
      }
    >
      <Form form={form} layout="vertical">
        {error &&
          getErrorMessage(error)?.errors?.map((i) => (
            <Alert message={i.detail} type="error" />
          ))}

        <Form.Item
          name="oldPassword"
          label="Old password:"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="New password:"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Repeat new password:"
          dependencies={["newPassword"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Parola introdusa nu coincide cu prima!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};
