import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export function enumKeys(enumType: Record<string, any>) {
  return Object.values(enumType).filter((o) => typeof o == "string");
}

export function keys<O extends object>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}

export const getErrorMessage = (
  error?: FetchBaseQueryError | SerializedError
) => {
  if (error) {
    if ("status" in error) {

      return error.data as { errors: { detail: string }[] };
    }
  }
};
