import businessDays from "dayjs-business-days2";

import dayjs from "dayjs";

dayjs.extend(businessDays);

export const today = dayjs();
export const dateFormat = "DD.MM.YYYY";
export const dateFormat2 = "DD MMM, YYYY";
export const dateTimeFormat = "DD.MM.YYYY HH:mm";
export const dateTimeFormatInput = "dd.MM.yyyy HH:mm";
export const dateFormatDMCMY = "D MMM, YYYY";
export const fullDateTimeFormat = "DD MMM YYYY, HH:mm A Z";
export const dateSpecialFormat = "D MMMM YYYY";
export const timeSpecialFormat = "HH:mm A Z";
export const monthFormat = "MMM";
export const chatDateTime = "HH:mm | DD.MM.YYYY";

export const format = (date?: string, format?: string): string =>
  dayjs(date).format(format || dateTimeFormat);

export const isThreeBusinessDaysPast = (
  fromDate: string | dayjs.Dayjs
): boolean => {
  const threeBusinessDaysLater = dayjs(fromDate).businessDaysAdd(3);

  return today.isAfter(threeBusinessDaysLater);
};
