import { Form, FormInstance, Select } from "antd";

interface IAddCheckableComponentLogic {
  form: FormInstance;
  parentName: (string | number)[];
  name: number;
}

export const AddCheckableComponentLogic = ({
  form,
  parentName,
  name
}: IAddCheckableComponentLogic) => {
  const parentQuestionOptions = Form.useWatch(
    [...parentName.slice(0, -1), "questionOptions"],
    form
  );

  const availableOptions = parentQuestionOptions?.filter(
    (i?: { text: string }) => i && i.text
  );

  if (!Boolean(availableOptions?.length)) return <></>;

  return (
    <Form.Item name={[name, "orderRelatedQuestionOption"]} noStyle>
      <Select
        options={availableOptions?.map((i: any) => ({
          label: i.text,
          value: i.orderOption,
        }))}
        style={{ minWidth: 100 }}
        size="small"
        variant="borderless"
        placeholder="Option"
      />
    </Form.Item>
  );
};
