import { useEffect, useState } from "react";
import { Flex, InputNumber, Select } from "antd";

interface IAddNumericQuestionLogic {
  value?: string;
  onChange?: (val: string) => void;
}

export const AddNumericQuestionLogic = ({
  value,
  onChange,
}: IAddNumericQuestionLogic) => {
  const [firstNumber, setFirstNumber] = useState<string | number | null>();
  const [secondNumber, setSecondNumber] = useState<string | number | null>();
  const [type, setType] = useState<string>("lessThan");

  useEffect(() => {
    if (onChange) {
      onChange(JSON.stringify({ firstNumber, type, secondNumber }));
    }
  }, [firstNumber, secondNumber, type]);

  useEffect(() => {
    if (value) {
      const newValues = JSON.parse(value);

      setFirstNumber(newValues.firstNumber);
      setSecondNumber(newValues.secondNumber);
      setType(newValues.type);
    }
  }, []);

  return (
    <Flex align="baseline">
      {type && ["between", "notBetween"].includes(type) && (
        <InputNumber
          controls={false}
          style={{
            height: "fit-content",
            width: 50,
          }}
          value={firstNumber}
          onChange={(val) => setFirstNumber(val)}
          placeholder="100"
          variant="borderless"
          className="input-number-center"
        />
      )}

      <>
        <Select
          options={[
            { label: "X <", value: "lessThan" },
            { label: "X ≤", value: "lessThanOrEqual" },
            { label: "X =", value: "equal" },
            { label: "X ≠", value: "notEqual" },
            { label: "X ≥", value: "greaterThanOrEqual" },
            { label: "X >", value: "greaterThan" },
            { label: "< X <", value: "between" },
            { label: "> X >", value: "notBetween" },
          ]}
          value={type}
          onChange={(val) => setType(val)}
          suffixIcon={null}
          popupClassName="small-padding-dropdown"
          defaultValue="lessThan"
          variant="borderless"
          style={{ width: 60 }}
        />

        <InputNumber
          controls={false}
          style={{
            height: "fit-content",
            width: 60,
            textAlign: "center",
          }}
          value={secondNumber}
          onChange={(val) => setSecondNumber(val)}
          placeholder="100"
          variant="borderless"
          className="input-number-center"
        />
      </>
    </Flex>
  );
};
