import ImportedPhoneInput, { Value } from "react-phone-number-input/input";
import { DefaultInputComponentProps } from "react-phone-number-input/index";

export const PhoneInput = ({
  value,
  onChange,
  disabled,
  ...props
}: DefaultInputComponentProps) => {
  const onPhoneInputChange = (value: Value) => {
    if (onChange) onChange(value);
  };

  return (
    <ImportedPhoneInput
      country="US"
      value={value}
      onChange={onPhoneInputChange}
      className={`phone-input__input ${disabled ? "disabled" : ""}`}
      disabled={disabled}
      {...props}
    />
  );
};
