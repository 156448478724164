import { ColorPickerProps } from "antd";
import { green, grey, presetPalettes, red, yellow } from "@ant-design/colors";

type Presets = Required<ColorPickerProps>["presets"][number];

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map<Presets>(([label, colors]) => ({
    label,
    colors,
  }));

export const selectablePresets = genPresets({
  yellow,
  red,
  green,
  grey,
});

export enum InspectionStatusColors {
  CREATED = "purple",
  IN_PROGRESS = "cyan",
  COMPLETED_BY_AGENT = "blue",
  NEED_FINAL_APPROVAL = "geekblue",
  APPROVED_TO_SEND = "lime",
  SENT_TO_CLIENT = "green",
  REVIEWED_BY_CLIENT = "gold",
  CANCELLED = "volcano",
}
