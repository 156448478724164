import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { getRouteByName } from "lib";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={() => navigate(getRouteByName("InspectionsPage"))}
        >
          Go to inspections page
        </Button>
      }
    />
  );
};
