import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { App, Button, Form, Input, Modal, Upload } from "antd";
import { handleRTKQueryError, useSaveAdditionalInfoMutation } from "api";
import { uploadFileToS3 } from "utils";
import { normFile } from "lib";

interface IAddLabResultsModal {
  onCancel: () => void;
  onOk: () => void;
}

export const AddAdditionalInfoModal = ({
  onCancel,
  onOk,
}: IAddLabResultsModal) => {
  const params = useParams<{ id: string }>();
  const { notification } = App.useApp();
  const [form] = Form.useForm();

  const [mutation, { isSuccess, error, isLoading }] =
    useSaveAdditionalInfoMutation();

  useEffect(() => {
    if (isSuccess) {
      onCancel();
      onOk();
    }
  }, [isSuccess, onCancel, onOk]);

  useEffect(() => {
    if (error) {
      notification.error({ message: handleRTKQueryError(error) });
    }
  }, [error, notification]);

  return (
    <Modal
      open
      title="Add additional info"
      onCancel={onCancel}
      styles={{ body: { margin: "2rem 0" } }}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            const formValues = form.getFieldsValue();

            formValues.filename = formValues.filename[0].name;

            mutation({
              ...formValues,
              id: params.id,
            });
          })
          .catch(() => null);
      }}
      okButtonProps={{ loading: isLoading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Title"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>

        <Form.Item
          name="filename"
          label="Attach file"
          valuePropName="fileList"
          rules={[{ required: true, message: "This field is required" }]}
          getValueFromEvent={normFile}
        >
          <Upload
            customRequest={({ file, onProgress, onSuccess, onError }) =>
              uploadFileToS3(
                file,
                `lab/${
                  typeof file === "object" && "name" in file ? file.name : ""
                }`,
                onProgress,
                onSuccess,
                onError
              )
            }
            maxCount={1}
            accept=".pdf"
          >
            <Button>Attach</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
