import { CheckableButtonsProvider } from "contexts";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";

interface ICheckableButtonWrapper {
  value?: number[];
  onChange?: (selected: number[]) => void;
}

export const CheckableButtonWrapper = ({
  value,
  onChange,
  children,
}: PropsWithChildren<ICheckableButtonWrapper>) => {
  const [selectedOrders, setSelectedOrders] = useState<number[]>(value || []);

  const onSelectedChange = useCallback(
    (newValue: number, type: "radio" | "checkbox") => {
      setSelectedOrders((prevState) => {
        if (type === "checkbox") {
          return prevState.includes(newValue)
            ? prevState.filter((i) => i !== newValue)
            : [...prevState, newValue];
        }

        return [newValue];
      });
    },
    []
  );

  useEffect(() => {
    if (onChange && selectedOrders.length > 0) {
      onChange(selectedOrders);
    }
  }, [onChange, selectedOrders]);

  return (
    <CheckableButtonsProvider
      selected={selectedOrders}
      onChange={onSelectedChange}
    >
      {children}
    </CheckableButtonsProvider>
  );
};
