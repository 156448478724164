import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig, IWithPagination } from "./index";
import { IUser } from "./users.types";
import { defaultQS } from "lib";
import { TInspectionClient } from "./inspections.types";

export const usersApi = createApi({
  ...defaultApiConfig,
  reducerPath: "usersApi",
  tagTypes: ["Users", "User", "MyProfile", "Agents", "Clients"],
  endpoints: (builder) => ({
    getUsers: builder.query<IWithPagination<IUser>, Record<string, any>>({
      query: (params) => `users?${defaultQS(params)}`,
      providesTags: ["Users"],
    }),
    getUsersInfinite: builder.query({
      query: (params) =>
        `admin/users?${defaultQS(params)}&sort=created_at:desc`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        if (otherArgs.arg.search) {
          currentCacheData.pagination = responseData.pagination;
          currentCacheData.data = responseData.data;

          return;
        }

        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getUser: builder.query<IUser, { id: number }>({
      query: ({ id }) => `users/${id}`,
      providesTags: ["User"],
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "users",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users", "Clients"],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `users/${id}`,
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    getMyProfile: builder.query<IUser, void>({
      query: () => "profile",
      providesTags: ["MyProfile"],
    }),
    resetUserPassword: builder.mutation({
      query: ({ id }) => `users/${id}/reset-password`,
    }),
    updatePassword: builder.mutation({
      query: ({ id, ...body }) => ({
        url: "profile/update-password",
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      }),
    }),
    updateMyProfile: builder.mutation({
      query: ({ id, ...body }) => ({
        url: "profile",
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      }),
      invalidatesTags: ["MyProfile"],
    }),
    getInfiniteAgents: builder.query<
      IWithPagination<{ label: string; value: number }>,
      Record<string, any>
    >({
      query: (params) => `agents?${defaultQS(params)}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData) => {
        if (responseData.pagination.current === 1) {
          currentCacheData.pagination = responseData.pagination;
          currentCacheData.data = responseData.data;

          return;
        }

        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (
        baseQueryReturnValue: IWithPagination<TInspectionClient>
      ) => ({
        data: baseQueryReturnValue.data.map((user) => ({
          label: `${user.email}(${user.firstName}${user.lastName})`,
          value: user.id,
        })),
        pagination: baseQueryReturnValue.pagination,
      }),
      providesTags: ["Agents"],
    }),
    getInfiniteClients: builder.query<
      IWithPagination<{ label: string; value: number }>,
      Record<string, any>
    >({
      query: (params) => `clients?${defaultQS(params)}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData) => {
        if (responseData.pagination.current === 1) {
          currentCacheData.pagination = responseData.pagination;
          currentCacheData.data = responseData.data;

          return;
        }

        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (
        baseQueryReturnValue: IWithPagination<TInspectionClient>
      ) => ({
        data: baseQueryReturnValue.data.map((user) => ({
          ...user,
          label: `${user.email}(${user.firstName}${user.lastName})`,
          value: user.id,
        })),
        pagination: baseQueryReturnValue.pagination,
      }),
      providesTags: ["Clients"],
    }),
  }),
  keepUnusedDataFor: 60 * 60 * 5,
});

export const {
  useGetUsersQuery,
  useGetUsersInfiniteQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetMyProfileQuery,
  useUpdateMyProfileMutation,
  useResetUserPasswordMutation,
  useUpdatePasswordMutation,
  useGetInfiniteAgentsQuery,
  useGetInfiniteClientsQuery,
} = usersApi;
