import { useEffect } from "react";
import {
  App,
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Layout,
  Space,
  theme,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { save } from "react-cookies";
import { getRouteByName } from "lib";
import { useLogInMutation } from "api";
import { setAuthTokens, setLoggedAdminData, useAppDispatch } from "store";

import { LogoBig } from "../assets/png";

export const LogInPage = () => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { notification } = App.useApp();

  const [logIn, { data, isLoading, error }] = useLogInMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data?.token) {
      save("access-token", data.token, { path: "/" });

      notification.success({ message: "You are logged in!" });

      dispatch(setAuthTokens(data));
      dispatch(setLoggedAdminData({ roles: data.roles, email: data.email }));

      navigate(getRouteByName("InspectionsPage"));
    }
  }, [data, notification, dispatch, navigate]);

  useEffect(() => {
    const onErrorReceive = () => {
      if (error) {
        notification.error({ message: (error as any).data.error });
      }
    };

    onErrorReceive();
  }, [error, notification]);

  return (
    <Layout
      style={{
        padding: "3rem",
        flex: "inherit",
        borderRadius: ".5rem",
        backgroundColor: "white",
      }}
    >
      <Space direction="vertical" style={{ width: "20rem", gap: 0 }}>
        <Flex justify="center">
          <img src={LogoBig} style={{ width: 225 }} alt="logo" />
        </Flex>

        <Form
          form={form}
          style={{ marginTop: "2rem" }}
          onFinish={(values) => logIn(values)}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input email",
              },
            ]}
          >
            <Input placeholder="User name" size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input password" }]}
          >
            <Input.Password placeholder="Password" size="large" />
          </Form.Item>

          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            <div />

            <Link to={getRouteByName("RequestResetPassPage")}>
              <Typography.Text style={{ color: token.colorPrimary }}>
                Forgot password
              </Typography.Text>
            </Link>
          </Space>

          <Button
            type="primary"
            style={{ width: "100%" }}
            htmlType="submit"
            size="large"
            loading={isLoading}
          >
            Log in
          </Button>
        </Form>
      </Space>
    </Layout>
  );
};
