import {
  Card,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Switch,
  Typography,
} from "antd";
import dayjs from "dayjs";
import {
  AdditionalQuestionSection,
  InspectionCheckableComponent,
} from "components/index";
import { CheckableButtonWrapper } from "../../CheckableButtonWrapper";
import { TAnswer } from "api/inspections.types";
import { QuestionTypes, today } from "lib";

interface IViewTemplatesQuestion {
  answer: TAnswer;
}

export const SummaryAnswer = ({ answer }: IViewTemplatesQuestion) => {
  return (
    <div>
      <Card
        style={{ marginBottom: 16 }}
        title={
          <Flex justify="space-between">
            <Typography.Text strong>
              {answer.inspectionQuestion.text}
            </Typography.Text>

            <Flex align="center" gap={8}>
              <Form.Item
                name={[answer.id, "hidden"]}
                initialValue={answer.hidden}
                noStyle
              >
                <Switch title="Hidden" size="small" />
              </Form.Item>

              <span>Hidden</span>
            </Flex>
          </Flex>
        }
        size="small"
      >
        {generateSummaryQuestion(answer)}

        <Form.Item
          name={[answer.id, "id"]}
          initialValue={answer.id}
          hidden
          noStyle
        >
          <InputNumber />
        </Form.Item>

        <Form.Item name={[answer.id, "mediaFiles"]} initialValue={answer.mediaFiles}>
          <AdditionalQuestionSection
            notes={{ name: [answer.id, "notes"], initialValue: answer.notes }}
          />
        </Form.Item>
      </Card>
    </div>
  );
};

const generateSummaryQuestion = (answer: TAnswer) => {
  switch (answer.inspectionQuestion.type) {
    case QuestionTypes.TEXT_ANSWER:
      return (
        <Form.Item
          name={[answer.id, "textResponse"]}
          required={answer.inspectionQuestion.required}
          initialValue={answer.textResponse}
        >
          <Input.TextArea placeholder="Text answer" />
        </Form.Item>
      );
    case QuestionTypes.NUMBER:
      return (
        <Form.Item
          name={[answer.id, "textResponse"]}
          required={answer.inspectionQuestion.required}
          initialValue={answer.textResponse}
        >
          <InputNumber placeholder="Number answer" style={{ width: 150 }} />
        </Form.Item>
      );
    case QuestionTypes.CHECKBOX:
    case QuestionTypes.RADIOBUTTON:
      return (
        <Form.Item
          name={[answer.id, "questionOptions"]}
          required={answer.inspectionQuestion.required}
          initialValue={answer.questionOptions.map((i) => i.orderOption)}
        >
          <CheckableButtonWrapper>
            <Flex style={{ width: "100%", gap: 8 }} wrap>
              {answer.inspectionQuestion.inspectionQuestionOptions.map(
                (field) => (
                  <InspectionCheckableComponent
                    field={field}
                    type={answer.inspectionQuestion.type}
                    key={field.id}
                  />
                )
              )}
            </Flex>
          </CheckableButtonWrapper>
        </Form.Item>
      );
    case QuestionTypes.DATE_TIME:
      return (
        <Form.Item
          name={[answer.id, "textResponse"]}
          required={answer.inspectionQuestion.required}
          initialValue={answer.textResponse ? dayjs(answer.textResponse) : null}
        >
          <DatePicker
            placeholder="Date/Time"
            disabledDate={(data) => data < today}
            showNow={false}
            showTime
          />
        </Form.Item>
      );
  }
};
