import { Dispatch, DragEvent, SetStateAction, useMemo } from "react";
import { FormInstance } from "antd";

interface IUseDragAndDrop {
  isCollapsed: boolean;
  isChild: boolean;
  setIsOver: Dispatch<SetStateAction<boolean>>;
  parentName: (string | number)[];
  name: number;
  form: FormInstance;
}

export const useDragAndDrop = ({
  isCollapsed,
  isChild,
  setIsOver,
  parentName,
  name,
  form,
}: IUseDragAndDrop) => {
  return useMemo(
    () =>
      !isChild && {
        onDrop: (e: DragEvent<HTMLDivElement>) => {
          const { order: pickedOrder, type } = JSON.parse(
            e.dataTransfer.getData("text/plain")
          );

          if (type === "question") {
            const pickedQuestion = form.getFieldValue([
              ...parentName,
              pickedOrder,
            ]);
            const destinationQuestion = form.getFieldValue([
              ...parentName,
              name,
            ]);

            form.setFieldValue([...parentName, pickedOrder], {
              ...destinationQuestion,
              orderQuestion: pickedOrder,
            });
            form.setFieldValue([...parentName, name], {
              ...pickedQuestion,
              orderQuestion: name,
            });

            setIsOver(false);
          }
        },
        onDragStart: (e: DragEvent<HTMLDivElement>) => {
          if (isCollapsed) {
            e.dataTransfer.setData(
              "text/plain",
              JSON.stringify({ order: name, type: "question" })
            );
          }
        },
        onDragLeave: () => setIsOver(false),
        onDragOver: (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();

          const { type } = JSON.parse(e.dataTransfer.getData("text/plain"));

          if (type === "question") {
            setIsOver(true);
          }
        },
        draggable: isCollapsed,
      },
    [isCollapsed, isChild, name, parentName, setIsOver, form]
  );
};
