import { useContext, useEffect, useMemo, useState } from "react";
import {
  inspectionsApi,
  useGetSummaryQuery,
  useUpdateSummaryMutation,
} from "../api";
import { useNavigate, useParams } from "react-router-dom";
import {
  Anchor,
  App,
  Button,
  Card,
  Collapse,
  ConfigProvider,
  Flex,
  Form,
  Spin,
  theme,
} from "antd";
import { HtmlEditor } from "../components/HtmlEditor";
import {
  HtmlEditorPlaceholder,
  SummaryAnswer,
  SummaryLabResultSection,
  SummaryOverviewSection,
} from "../components";
import { AttachedFile, getRouteByName } from "../lib";
import { MediaUploaderContext } from "../contexts";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ColorLogo } from "../assets/svgs";
import { SummaryAdditionalInfoSection } from "../components/inspections/summary/SummaryAdditionalInfo";

export const SummaryPage = () => {
  const params = useParams<{ id: string }>();
  const { notification } = App.useApp();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isFinish, setIsFinish] = useState(false);
  const [singleUpdatingField, setSingleUpdatingField] = useState<string>();

  const { setFilesToUpload } = useContext(MediaUploaderContext);

  const {
    data: summaryData,
    isLoading,
    refetch,
  } = useGetSummaryQuery({
    id: Number(params.id),
  });

  const [
    updateSummaryMutation,
    {
      isLoading: isUpdateSummaryLoading,
      isSuccess: isUpdateSummarySuccess,
      error: updateSummaryError,
    },
  ] = useUpdateSummaryMutation();

  useEffect(() => {
    if (isUpdateSummarySuccess) {
      inspectionsApi.util.invalidateTags(["Inspections"]);
      notification.success({ message: "Summary was successfully saved" });
      setSingleUpdatingField(undefined);

      if (isFinish) {
        navigate(getRouteByName("InspectionsPage"));
      }
    }
  }, [isUpdateSummarySuccess, isFinish, navigate, notification]);

  useEffect(() => {
    if (updateSummaryError) {
      notification.error({ message: "We were not able to save summery" });
      setSingleUpdatingField(undefined);
    }
  }, [updateSummaryError, notification]);

  const {
    instructionsPrioritized = [],
    instructions = [],
    flaggedAnswers = [],
    restAnswers = [],
    products = [],
  } = useMemo(
    () => ({
      flaggedAnswers:
        summaryData?.flaggedAnswers &&
        Object.keys(summaryData?.flaggedAnswers).map((pageKey) => ({
          key: summaryData?.flaggedAnswers[pageKey].id,
          label: summaryData?.flaggedAnswers[pageKey].title,
          children: (
            <Form.List name="inspectionAnswers">
              {() =>
                summaryData?.flaggedAnswers[pageKey].answers.map((answer) => (
                  <SummaryAnswer answer={answer} key={answer.id} />
                ))
              }
            </Form.List>
          ),
        })),
      restAnswers:
        summaryData?.restAnswers &&
        Object.keys(summaryData?.restAnswers).map((pageKey) => ({
          key: summaryData?.restAnswers[pageKey].id,
          label: summaryData?.restAnswers[pageKey].title,
          children: (
            <Form.List name="inspectionAnswers">
              {() =>
                summaryData?.restAnswers[pageKey].answers.map((answer) => (
                  <SummaryAnswer answer={answer} key={answer.id} />
                ))
              }
            </Form.List>
          ),
        })),
      instructionsPrioritized:
        summaryData?.instructionsPrioritized &&
        Object.keys(summaryData?.instructionsPrioritized).map((pageKey) => ({
          key: summaryData?.instructionsPrioritized[pageKey].id,
          label: summaryData?.instructionsPrioritized[pageKey].title,
          children: (
            <Flex gap={12} vertical>
              {summaryData?.instructionsPrioritized[pageKey].answers.map(
                (answer) => (
                  <Card>
                    <Flex align="center" gap={12}>
                      <ExclamationCircleOutlined
                        style={{ fontSize: 20, color: token.yellow7 }}
                      />

                      <span>{answer.inspectionQuestion.text}</span>
                    </Flex>
                  </Card>
                )
              )}
            </Flex>
          ),
        })),
      instructions:
        summaryData?.instructions &&
        Object.keys(summaryData?.instructions).map((pageKey) => ({
          key: summaryData?.instructions[pageKey].id,
          label: summaryData?.instructions[pageKey].title,
          children: (
            <Flex gap={12} vertical>
              {summaryData?.instructions[pageKey].answers.map((answer) => (
                <Card>
                  <Flex align="center" gap={12}>
                    <ExclamationCircleOutlined
                      style={{ fontSize: 20, color: token.yellow7 }}
                    />

                    <span>{answer.inspectionQuestion.text}</span>
                  </Flex>
                </Card>
              ))}
            </Flex>
          ),
        })),
      products:
        summaryData?.products &&
        Object.keys(summaryData?.products).map((pageKey) => ({
          key: summaryData?.products[pageKey].id,
          label: summaryData?.products[pageKey].title,
          children: (
            <Flex gap={12} vertical>
              {summaryData?.products[pageKey].products.map((product) => (
                <Card>
                  <div>
                    <a href={product.link}>{product.name}</a>
                  </div>

                  <div style={{ color: "gray" }}>{product.description}</div>
                </Card>
              ))}
            </Flex>
          ),
        })),
    }),
    [summaryData]
  );

  const onFinish = (finish?: boolean) => {
    const formValues = form.getFieldsValue(true);

    if (finish) {
      setIsFinish(true);
    }

    form.validateFields().then(() => {
      const filteredInspectionAnswers = formValues.inspectionAnswers?.filter(
        (i: any) => i
      );

      if (setFilesToUpload && filteredInspectionAnswers) {
        setFilesToUpload(
          filteredInspectionAnswers.flatMap((i: any) =>
            i.mediaFiles
              .filter((media: { base64?: string }) => media.base64)
              .map((media: AttachedFile) => ({
                ...media,
                name: `${params.id}/${media.name}`,
              }))
          )
        );
      }

      updateSummaryMutation({
        id: params.id,
        completed: finish,
        ...formValues,
        inspectionAnswers: filteredInspectionAnswers?.map(
          (i: AttachedFile, key: number) => ({
            ...i,
            mediaFiles: filteredInspectionAnswers[key].mediaFiles.map(
              (media: AttachedFile) => ({
                ...media,
                filename:
                  media.filename ||
                  `${process.env.REACT_APP_S3_URL}/${params.id}/${media.name}`,
              })
            ),
          })
        ),
      });
    });
  };

  const submitField = (fieldName: string) => {
    const formValues = form.getFieldsValue();

    setSingleUpdatingField(fieldName);

    updateSummaryMutation({
      id: params.id,
      [fieldName]: formValues[fieldName],
    });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            headerBg: "var(--ant-blue-1)",
          },
          Timeline: {
            itemPaddingBottom: 0,
          },
        },
      }}
    >
      <Spin spinning={isLoading}>
        <Card
          style={{ backgroundColor: "var(--ant-blue-1)", marginBottom: 16 }}
        >
          <Flex justify="space-between">
            <Flex style={{ margin: 12 }} gap={12} vertical>
              <Card
                style={{ background: "white", paddingRight: 36, fontSize: 18 }}
              >
                <div style={{ fontSize: 24 }}>
                  <strong>
                    {summaryData?.inspectionData.client.firstName}{" "}
                    {summaryData?.inspectionData.client.lastName}
                  </strong>
                </div>
                <div>
                  {[
                    summaryData?.inspectionData.addressStateFullName,
                    summaryData?.inspectionData.addressCity,
                    summaryData?.inspectionData.addressZip,
                    summaryData?.inspectionData.address,
                  ].join(", ")}
                </div>
                <div>{summaryData?.inspectionData.client.email}</div>
                <div>{summaryData?.inspectionData.client.phone}</div>
              </Card>

              <Card
                style={{ background: "white", paddingRight: 36, fontSize: 18 }}
              >
                <div style={{ fontSize: 20 }}>
                  <strong>Contact Your Inspector</strong>
                </div>
                <div>
                  {summaryData?.inspectionData.agent.firstName}{" "}
                  {summaryData?.inspectionData.agent.lastName}
                </div>
                <div>{summaryData?.inspectionData.agent.email}</div>
                <div>{summaryData?.inspectionData.agent.phone}</div>
              </Card>
            </Flex>

            <ColorLogo style={{ fontSize: 30 }} />
          </Flex>
        </Card>

        <Flex gap={12}>
          <Form layout="vertical" form={form} style={{ width: "100%" }}>
            <Flex gap={12} vertical>
              <Flex justify="flex-end" gap={12}>
                <Button
                  type="primary"
                  onClick={() => onFinish()}
                  loading={
                    !isFinish && !singleUpdatingField && isUpdateSummaryLoading
                  }
                >
                  Save
                </Button>

                <Button
                  type="primary"
                  onClick={() => onFinish(true)}
                  loading={isFinish && isUpdateSummaryLoading}
                >
                  Save & Finish
                </Button>
              </Flex>

              <Card
                title="Inspection summary"
                styles={{
                  body: {
                    minHeight: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "var(--ant-blue-1)",
                  },
                }}
                id="inspectionSummary"
              >
                <HtmlEditorPlaceholder label="Edit inspection summary">
                  <Form.Item
                    name="inspectionSummary"
                    initialValue={summaryData?.inspectionData.inspectionSummary}
                  >
                    <HtmlEditor
                      initialValue={
                        summaryData?.inspectionData.inspectionSummary
                      }
                    />
                  </Form.Item>

                  <Flex justify="flex-end" style={{ marginTop: 12 }}>
                    <Button
                      type="primary"
                      onClick={() => submitField("inspectionSummary")}
                      loading={
                        singleUpdatingField === "inspectionSummary" &&
                        isUpdateSummaryLoading
                      }
                    >
                      Save
                    </Button>
                  </Flex>
                </HtmlEditorPlaceholder>
              </Card>

              {Boolean(instructionsPrioritized.length) && (
                <Card title="Prioritized instructions " id="restAnswers">
                  <Collapse items={instructionsPrioritized} />
                </Card>
              )}

              {Boolean(flaggedAnswers.length) && (
                <Card title="Flagged items" id="flaggedItems">
                  <Collapse items={flaggedAnswers} />
                </Card>
              )}

              {Boolean(instructions.length) && (
                <Card title="Instructions" id="instructions">
                  <Collapse items={instructions} />
                </Card>
              )}

              {Boolean(restAnswers.length) && (
                <Card title="Inspections" id="restAnswers">
                  <Collapse items={restAnswers} />
                </Card>
              )}

              {Boolean(products.length) && (
                <Card title="Products" id="restAnswers">
                  <Collapse items={products} />
                </Card>
              )}

              <SummaryLabResultSection
                summaryData={summaryData}
                refetch={refetch}
              />

              <SummaryAdditionalInfoSection
                summaryData={summaryData}
                refetch={refetch}
              />

              <SummaryOverviewSection summaryData={summaryData} />
            </Flex>
          </Form>

          <div style={{ width: "min-content" }}>
            <Anchor
              items={[
                {
                  key: "inspectionSummary",
                  href: "#inspectionSummary",
                  title: "Inspection summary",
                },
                {
                  key: "flaggedItems",
                  href: "#flaggedItems",
                  title: "Flagged items",
                },
                {
                  key: "restAnswers",
                  href: "#restAnswers",
                  title: "Rest answers",
                },
                {
                  key: "labResults",
                  href: "#labResults",
                  title: "Lab results",
                },
                {
                  key: "overview",
                  href: "#overview",
                  title: "Overview",
                },
              ]}
            />
          </div>
        </Flex>
      </Spin>
    </ConfigProvider>
  );
};
