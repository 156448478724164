import { useMemo } from "react";
import { TInspectionQuestion, TQuestion } from "../api/templates.types";

export const useShowField = (
  question: TInspectionQuestion | TQuestion,
  parent?: TInspectionQuestion | TQuestion,
  triggerAnswer?: number | number[]
) => {
  return useMemo(() => {
    if (
      typeof triggerAnswer === "number" &&
      question.relatedQuestionCondition
    ) {
      const storedValues = JSON.parse(question.relatedQuestionCondition);

      switch (storedValues.type) {
        case "lessThan":
          return triggerAnswer < storedValues.secondNumber;
        case "lessThanOrEqual":
          return triggerAnswer <= storedValues.secondNumber;
        case "equal":
          return triggerAnswer === storedValues.secondNumber;
        case "notEqual":
          return triggerAnswer !== storedValues.secondNumber;
        case "greaterThanOrEqual":
          return triggerAnswer >= storedValues.secondNumber;
        case "greaterThan":
          return triggerAnswer > storedValues.secondNumber;
        case "between":
          return (
            storedValues.firstNumber < triggerAnswer &&
            triggerAnswer < storedValues.secondNumber
          );
        case "notBetween":
          return (
            storedValues.firstNumber > triggerAnswer &&
            triggerAnswer > storedValues.secondNumber
          );
      }
    }

    if (Array.isArray(triggerAnswer)) {
      if (
        "orderRelatedInspectionQuestionOption" in question &&
        triggerAnswer?.includes(question?.orderRelatedInspectionQuestionOption)
      )
        return true;

      if (
        "orderRelatedQuestionOption" in question &&
        triggerAnswer?.includes(question?.orderRelatedQuestionOption)
      )
        return true;
    }

    return !parent;
  }, [triggerAnswer, parent, question]);
};
