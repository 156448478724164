import { PropsWithChildren, useContext } from "react";
import { Divider, Flex, Form, Grid, Input } from "antd";
import { TQuestionOption } from "api/templates.types";
import { Breakpoint } from "antd/es/_util/responsiveObserver";
import { CheckableButtonsContext } from "../../../contexts";

interface IInspectionCheckableComponent {
  field: TQuestionOption;
}

export const SummaryCheckableComponent = ({
  field,
}: IInspectionCheckableComponent) => {
  const breakpoints = Grid.useBreakpoint();

  const getOptionWidth = ({
    xxl,
    xl,
    md,
  }: Partial<Record<Breakpoint, boolean>>) => {
    if (xxl) return "24.5%";

    if (xl) return "33%";

    if (md) return "49%";

    return "100%";
  };

  return (
    <Flex align="center" style={{ width: getOptionWidth(breakpoints) }}>
      <Flex
        justify="space-between"
        align="center"
        style={{
          width: "100%",
        }}
      >
        <SummaryDiv field={field}>{field.text}</SummaryDiv>
      </Flex>

      <Flex align="center" justify="space-between" style={{ width: "100%" }}>
        {field.hasResponse && (
          <>
            <Divider type="vertical" />
            <Form.Item name={`${field.id}_response`} noStyle>
              <Input placeholder="Additonal" size="small" />
            </Form.Item>
          </>
        )}
      </Flex>
    </Flex>
  );
};

const SummaryDiv = ({
  field,
  children,
  ...props
}: PropsWithChildren<{ field: TQuestionOption }>) => {
  const { selected } = useContext(CheckableButtonsContext);

  const isSelected = selected.includes(field.orderOption);

  return (
    <div
      {...props}
      style={{
        border: `1px solid ${
          isSelected ? field.color || "#d9d9d9" : "var(--ant-color-border)"
        }`,
        padding: "12px 15px",
        borderRadius: "0.5rem",
        backgroundColor: isSelected ? field.color || "#d9d9d9" : "transparent",
        width: "100%",
      }}
    >
      {children}
    </div>
  );
};
