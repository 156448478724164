import { Card, Flex } from "antd";
import { dateFormat2, format } from "lib";
import { TSummary } from "api/inspections.types";

import {
  CalendarOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";

interface ISummaryOverviewSection {
  summaryData?: TSummary;
}

export const SummaryOverviewSection = ({
  summaryData,
}: ISummaryOverviewSection) => {
  return (
    <Card id="overview" title="Overview">
      <Flex justify="space-between" gap={12} wrap>
        <div
          style={{
            textAlign: "center",
            border: "1px solid var(--ant-color-border)",
            borderRadius: 8,
            padding: 12,
            flex: "1 0 auto",
          }}
        >
          <HomeOutlined style={{ fontSize: 24, marginBottom: 8 }} />
          <div>
            {[
              summaryData?.inspectionData.addressStateFullName,
              summaryData?.inspectionData.addressCity,
              summaryData?.inspectionData.addressZip,
              summaryData?.inspectionData.address,
            ].join(", ")}
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            border: "1px solid var(--ant-color-border)",
            borderRadius: 8,
            padding: 12,
            flex: "1 0 auto",
          }}
        >
          <CalendarOutlined style={{ fontSize: 24, marginBottom: 8 }} />
          <div>
            {summaryData?.inspectionData.scheduledDate &&
              format(summaryData?.inspectionData.scheduledDate, dateFormat2)}
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            border: "1px solid var(--ant-color-border)",
            borderRadius: 8,
            padding: 12,
            flex: "1 0 auto",
          }}
        >
          <UserOutlined style={{ fontSize: 24, marginBottom: 8 }} />
          <div>
            {summaryData?.inspectionData.agent.firstName}{" "}
            {summaryData?.inspectionData.agent.lastName}
          </div>
        </div>
      </Flex>
    </Card>
  );
};
