import { useMemo } from "react";
import { Form, FormInstance } from "antd";
import { ENumericFieldConditions } from "lib";
import { TInspectionQuestion } from "../api/templates.types";

export const useInspectionQuestionFlag = (
  question: TInspectionQuestion,
  form: FormInstance
) => {
  const questionValue = Form.useWatch(["inspectionAnswers", question.id], form);

  return useMemo(() => {
    if (
      questionValue?.textResponse &&
      question.flaggedInspectionQuestionCondition
    ) {
      const storedNumericProps = JSON.parse(
        question.flaggedInspectionQuestionCondition
      );

      switch (storedNumericProps.type) {
        case ENumericFieldConditions.DIFFERENT:
          return (
            storedNumericProps.secondNumber !== questionValue?.textResponse
          );
        case ENumericFieldConditions.BETWEEN:
          return (
            storedNumericProps.firstNumber < questionValue?.textResponse &&
            questionValue?.textResponse < storedNumericProps.secondNumber
          );
      }
    }

    return false;
  }, [question.flaggedInspectionQuestionCondition, questionValue]);
};
