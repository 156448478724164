import { useState } from "react";
import { App, Button, Card, Flex } from "antd";
import { downloadFileFromUrl } from "lib";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { TSummary } from "api/inspections.types";
import { useDeleteAdditionalInfoMutation } from "api";
import { AddAdditionalInfoModal } from "./AddAdditionalInfoModal";

interface ISummaryLabResultSection {
  summaryData?: TSummary;
  refetch: () => void;
}

export const SummaryAdditionalInfoSection = ({
  summaryData,
  refetch,
}: ISummaryLabResultSection) => {
  const { modal } = App.useApp();

  const [showAddModal, setShowAddModal] = useState(false);

  const [deleteAdditionalInfoMutation] = useDeleteAdditionalInfoMutation();

  return (
    <>
      <Card title="Additional info" id="labResults">
        <Flex gap={12} vertical>
          {summaryData?.inspectionData.inspectionAdditionalInfoItems.map(
            (additionalInfo, key) => (
              <Card key={key}>
                <Flex justify="space-between" align="center">
                  <div>{additionalInfo.name}</div>

                  <Flex gap={24}>
                    <Button
                      size="small"
                      onClick={() =>
                        downloadFileFromUrl(
                          `${process.env.REACT_APP_S3_URL}/additionalInfo/${additionalInfo.fileName}`,
                          additionalInfo.fileName
                        )
                      }
                    >
                      <DownloadOutlined /> {additionalInfo.fileName}
                    </Button>

                    <DeleteOutlined
                      style={{ color: "red" }}
                      onClick={() =>
                        modal.confirm({
                          title: "Delete additional info",
                          content: (
                            <div style={{ margin: "8px 0" }}>
                              Are you sure you want to delete this additonal
                              info?
                            </div>
                          ),
                          onOk: () =>
                            deleteAdditionalInfoMutation({
                              inspectionId: summaryData.inspectionData.id,
                              additionalInfoId: additionalInfo.id,
                            }),
                        })
                      }
                    />
                  </Flex>
                </Flex>
              </Card>
            )
          )}
        </Flex>

        <Flex justify="center" style={{ marginTop: 12 }}>
          <Button onClick={() => setShowAddModal(true)}>
            Add additional info
          </Button>
        </Flex>
      </Card>

      {showAddModal && (
        <AddAdditionalInfoModal
          onCancel={() => setShowAddModal(false)}
          onOk={refetch}
        />
      )}
    </>
  );
};
