import React, { forwardRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

interface IHtmlEditor {
  value?: string;
  onChange?: (value: string) => void;
  initialValue?: string;
}

export const HtmlEditor = forwardRef<any, IHtmlEditor>(
  ({ onChange, ...props }, ref) => {
    return (
      <Editor
        ref={ref}
        apiKey="g1x520dc6fqyo5fdwonzmwc1usvbtpsgy6ombff3xtiszymz"
        init={{
          plugins: [
            "anchor",
            "autolink",
            "charmap",
            "codesample",
            "emoticons",
            "image",
            "link",
            "lists",
            "media",
            "searchreplace",
            "table",
            "visualblocks",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | checklist numlist bullist indent outdent | emoticons | removeformat",
        }}
        onEditorChange={onChange}
        {...props}
      />
    );
  }
);
