import { useContext } from "react";
import { Button, ButtonProps } from "antd";
import { CheckableButtonsContext } from "contexts";
import { TQuestionOption } from "../api/templates.types";

interface IRadioButton extends ButtonProps {
  field: TQuestionOption;
}

export const RadioButton = ({ field, children, ...props }: IRadioButton) => {
  const { selected, onChange } = useContext(CheckableButtonsContext);

  const isSelected = selected.includes(field.orderOption);

  return (
    <Button
      {...props}
      type={isSelected ? "primary" : "default"}
      onClick={() => onChange && onChange(field.orderOption, "radio")}
      style={{
        border: `1px solid ${
          isSelected ? field.color || "#d9d9d9" : "var(--ant-color-border)"
        }`,
        padding: "20px 8px",
        borderRadius: "0.5rem",
        backgroundColor: isSelected ? field.color || "#d9d9d9" : "transparent",
        width: "100%",
      }}
    >
      {children}
    </Button>
  );
};
