import { createSlice } from "@reduxjs/toolkit";
import { load } from "react-cookies";
import { IUser } from "api";

interface ILoggedAdmin {
  auth: { token?: string };
  data?: IUser;
  parsedRoles?: Record<string, boolean>
}

const initialState: ILoggedAdmin = {
  auth: { token: load("access-token") },
};

export const loggedAdminSlice = createSlice({
  name: "app-settings",
  initialState,
  reducers: {
    setAuthTokens: (state, action) => ({ ...state, auth: action.payload }),
    removeAuthToken: (state) => ({ ...state, auth: { token: undefined } }),
    setLoggedAdminData: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setAuthTokens, removeAuthToken, setLoggedAdminData } =
  loggedAdminSlice.actions;

export default loggedAdminSlice.reducer;
