import { useMemo, useState } from "react";
import {
  Card,
  Checkbox,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Space,
  theme,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  HolderOutlined,
} from "@ant-design/icons";
import { keys, locales, QuestionTypes } from "lib";
import { EditQuestionsForm } from "./EditQuestionsForm";
import { EditCheckableComponent } from "./EditCheckableComponent";
import { PredefinedQuestionOptions } from "./PredefinedQuestionOptions";
import { NumericFieldConditions } from "./NumericFieldConditions";
import { AddCheckableComponentLogic } from "./AddCheckableComponentLogic";
import { AddNumericQuestionLogic } from "./AddNumericQuestionLogic";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import { TQuestion } from "../../api/templates.types";

interface IEditQuestionForm {
  parentName: (string | number)[];
  name: number;
  onRemove: (fieldName: number) => void;
  form: FormInstance;
  isChild?: boolean;
  defaultQuestion?: TQuestion;
}

export const EditQuestionForm = ({
  parentName,
  name,
  onRemove,
  form,
  isChild = true,
  defaultQuestion,
}: IEditQuestionForm) => {
  const { token } = theme.useToken();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOver, setIsOver] = useState(false);

  const currentQuestion = Form.useWatch([...parentName, name]);
  const parentType = Form.useWatch([...parentName.slice(0, -1), "type"]);

  const showChildForm = useMemo(
    () =>
      [
        QuestionTypes.CHECKBOX,
        QuestionTypes.RADIOBUTTON,
        QuestionTypes.NUMBER,
      ].includes(currentQuestion?.type),
    [currentQuestion]
  );

  const draggableProps = useDragAndDrop({
    isCollapsed: isCollapsed || !showChildForm,
    isChild,
    setIsOver,
    parentName,
    name,
    form,
  });

  return (
    <>
      {isOver && (
        <div
          style={{
            height: 3,
            backgroundColor: token.colorPrimary,
            borderRadius: 4,
          }}
        ></div>
      )}

      <Card
        title={
          <Flex justify="space-between" wrap>
            <Flex
              align="center"
              gap={8}
              style={{ width: "60%", minWidth: 300 }}
            >
              {showChildForm && (
                <DownOutlined
                  style={{
                    transform: isCollapsed ? `rotate(-90deg)` : "",
                    transition: ".3s",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsCollapsed((prevState) => !prevState)}
                />
              )}

              {(isCollapsed || !showChildForm) && (
                <HolderOutlined style={{ cursor: "move" }} />
              )}

              {isChild && (
                <>
                  <span>IF</span>

                  {[QuestionTypes.CHECKBOX, QuestionTypes.RADIOBUTTON].includes(
                    parentType
                  ) && (
                    <AddCheckableComponentLogic
                      form={form}
                      parentName={parentName}
                      name={name}
                    />
                  )}

                  {parentType === QuestionTypes.NUMBER && (
                    <Form.Item
                      name={[name, "relatedQuestionCondition"]}
                      noStyle
                    >
                      <AddNumericQuestionLogic />
                    </Form.Item>
                  )}

                  <Divider type="vertical" style={{ height: 20 }} />
                </>
              )}

              <Form.Item
                name={[name, "text"]}
                style={{ marginBottom: 0, width: "100%" }}
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input
                  variant="borderless"
                  placeholder="Question text"
                  style={{ padding: 0, fontSize: 16 }}
                  onPaste={() => undefined}
                  onDrop={(e) => {
                    e.preventDefault();
                  }}
                />
              </Form.Item>
            </Flex>

            <Space size={8}>
              {currentQuestion?.type === QuestionTypes.NUMBER && (
                <>
                  <Form.Item name={[name, "flaggedQuestionCondition"]} noStyle>
                    <NumericFieldConditions />
                  </Form.Item>
                  <Divider type="vertical" style={{ height: 20 }} />
                </>
              )}

              <Form.Item
                name={[name, "required"]}
                valuePropName="checked"
                noStyle
              >
                <Checkbox style={{ fontWeight: 400 }}>Required</Checkbox>
              </Form.Item>

              <Divider type="vertical" style={{ height: 20 }} />

              <Form.Item name={[name, "type"]} noStyle>
                <Select
                  placeholder="Type"
                  style={{ width: 125 }}
                  variant="borderless"
                >
                  {keys(QuestionTypes).map((i) => (
                    <Select.Option key={i} value={QuestionTypes[i]}>
                      {locales[i]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <DeleteOutlined
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => onRemove(name)}
              />
            </Space>
          </Flex>
        }
        size="small"
        style={{ marginBottom: 16, transform: "translate3d(0, 0, 0)" }}
        styles={{
          body: {
            display: showChildForm ? "" : "none",
            height: isCollapsed ? 0 : "auto",
            overflow: "hidden",
            padding: isCollapsed ? 0 : 12,
            transition: "0.3s",
          },
        }}
        {...draggableProps}
      >
        {[QuestionTypes.CHECKBOX, QuestionTypes.RADIOBUTTON].includes(
          currentQuestion?.type
        ) && (
          <Flex
            style={{
              marginBottom: 12,
            }}
            justify="space-between"
            wrap
          >
            <Flex
              style={{ width: "calc(100% - 210px)", marginBottom: "1rem" }}
              gap={2}
              wrap
            >
              <Form.List
                name={[name, "questionOptions"]}
                initialValue={
                  defaultQuestion?.questionOptions !== undefined
                    ? undefined
                    : [{ text: "" }]
                }
              >
                {(options, { add, remove }) =>
                  options.map((option, key) => (
                    <EditCheckableComponent
                      option={option}
                      onAdd={add}
                      onRemove={remove}
                      key={option.key}
                      isLastField={key + 1 === options.length}
                      parentName={[...parentName, name, "questionOptions"]}
                      form={form}
                    />
                  ))
                }
              </Form.List>
            </Flex>

            <span style={{ width: "20%", minWidth: 180, maxWidth: 200 }}>
              <PredefinedQuestionOptions
                form={form}
                nameToChange={[...parentName, name, "questionOptions"]}
              />
            </span>
          </Flex>
        )}

        {showChildForm && (
          <Flex gap={12} style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <EditQuestionsForm
                listKey={[name, "childQuestions"]}
                parentName={parentName}
                form={form}
                defaultQuestion={defaultQuestion?.childQuestions}
              />
            </div>
          </Flex>
        )}

        <Form.Item
          name={[name, "orderQuestion"]}
          initialValue={
            defaultQuestion?.orderQuestion !== undefined ? undefined : name
          }
          hidden
          noStyle
        >
          <InputNumber />
        </Form.Item>
      </Card>
    </>
  );
};
