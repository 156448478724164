import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig, IWithPagination } from "./index";
import { defaultQS } from "lib";
import { TInspection } from "./inspections.types";

export const inspectionsApi = createApi({
  ...defaultApiConfig,
  reducerPath: "inspectionsApi",
  tagTypes: ["Inspections", "Inspection"],
  endpoints: (builder) => ({
    getInspections: builder.query<
      IWithPagination<TInspection>,
      Record<string, string | number | number[]>
    >({
      query: (params) => `inspections?itemsPerPage=10&${defaultQS(params)}`,
      providesTags: ["Inspections"],
    }),
    getInspection: builder.query<TInspection, { id: number }>({
      query: ({ id }) => `inspections/${id}`,
      providesTags: ["Inspection"],
    }),
    createInspection: builder.mutation({
      query: (body) => ({
        url: "inspections",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
    updateInspection: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `inspections/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Inspections", "Inspection"],
    }),
    deleteInspection: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `admin/user/${id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
    getAvailableStatuses: builder.query<Record<string, string>, void>({
      query: () => `inspections/available-status-list`,
      serializeQueryArgs: ({ endpointName }) => endpointName,
    }),
    setInspectionStatus: builder.mutation<TInspection, Record<string, string>>({
      query: ({ id, ...body }) => ({
        url: `inspections/${id}/update-status`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
    getAvailablePropertyTypes: builder.query<Record<string, string>, void>({
      query: () => `inspections/available-property-type-list`,
    }),
    setInspectionAnswers: builder.mutation<any, Record<string, any>>({
      query: ({ id, ...body }) => ({
        url: `/inspections/${id}/save-answers`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
    addInspectionRoom: builder.mutation<any, Record<string, any>>({
      query: ({ id, ...body }) => ({
        url: `/inspections/${id}/add/template`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspection"],
    }),
    deleteInspectionRoom: builder.mutation({
      query: ({ inspectionId, roomId }) => ({
        url: `/inspections/${inspectionId}/inspection-pages/${roomId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Inspection"],
    }),
    getStatesList: builder.query<
      IWithPagination<{ label: string; value: string }>,
      undefined
    >({
      query: () => "/inspections/address-states-list",
      transformResponse: (baseQueryReturnValue: Record<string, string>) => ({
        data: Object.keys(baseQueryReturnValue).map((state) => ({
          label: baseQueryReturnValue[state],
          value: state,
        })),
        pagination: {
          first: 1,
          current: 1,
          last: 1,
          previous: 1,
          next: 1,
          totalItems: 1,
          onPage: 1,
        },
      }),
    }),
    setInspectionHoldFlag: builder.mutation({
      query: ({ inspectionId, ...body }) => ({
        url: `/inspections/${inspectionId}/holding`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
  }),
  keepUnusedDataFor: 60 * 60 * 5,
});

export const {
  useGetInspectionsQuery,
  useGetInspectionQuery,
  useUpdateInspectionMutation,
  useDeleteInspectionMutation,
  useCreateInspectionMutation,
  useGetAvailableStatusesQuery,
  useSetInspectionStatusMutation,
  useGetAvailablePropertyTypesQuery,
  useSetInspectionAnswersMutation,
  useAddInspectionRoomMutation,
  useDeleteInspectionRoomMutation,
  useGetStatesListQuery,
  useSetInspectionHoldFlagMutation,
} = inspectionsApi;
