import { useMemo, useState } from "react";
import { App, Button, Flex, Input, Space, Table } from "antd";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import {
  TSample,
  useGetSampleTypesQuery,
  useDeleteSampleTypeMutation,
} from "api";
import { ISort, useSorter } from "hooks";
import { SampleTypesFormModal } from "../components";
import {
  defaultPaginationConfig,
  defaultPaginationState,
} from "utils/pagination";

export const SamplePage = () => {
  const { modal } = App.useApp();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [sampleTypeId, setSampleTypeId] = useState<number>();
  const [pagination, setPagination] = useState(defaultPaginationState);
  const [search, setSearch] = useState<string>();
  const [sort, setSort] = useState<ISort>({
    sortKey: "desc",
    fieldKey: "",
  });
  const { returnSorterProps } = useSorter(sort, setSort);

  const { data, isLoading, isFetching } = useGetSampleTypesQuery({
    page: pagination.page,
    itemsPerPage: pagination.pageSize,
    search,
    ...(sort?.fieldKey && {
      [`order[${sort.fieldKey}]`]: sort.sortKey,
    }),
  });

  const [deleteMutation] = useDeleteSampleTypeMutation();

  const columns = useMemo<ColumnsType<TSample>>(
    () => [
      {
        dataIndex: "name",
        ...returnSorterProps("name", "Name"),
      },
      {
        title: "Actions",
        align: "right",
        dataIndex: "id",
        render: (id) => (
          <Space size={24}>
            <FormOutlined
              className="with-action"
              onClick={() => {
                setSampleTypeId(id);
                setIsOpenModal(true);
              }}
            />

            <DeleteOutlined
              style={{ color: "var(--ant-red-4)" }}
              onClick={() =>
                modal.confirm({
                  content: "Are you sure about deleting this sample type?",
                  onOk: () => deleteMutation({ id }),
                  okButtonProps: { danger: true },
                })
              }
            />
          </Space>
        ),
      },
    ],
    [deleteMutation, modal, returnSorterProps]
  );

  const handleCloseModal = () => {
    setIsOpenModal(false);
    if (sampleTypeId) {
      setSampleTypeId(undefined);
    }
  };

  return (
    <>
      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search
            placeholder="Search"
            style={{ width: 200 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            allowClear
          />
        </Space>

        <Button type="primary" onClick={() => setIsOpenModal(true)}>
          Add sample
        </Button>
      </Flex>

      <Table
        columns={columns}
        dataSource={data?.data}
        rowKey="id"
        loading={isLoading || isFetching}
        pagination={{
          ...defaultPaginationConfig(data?.pagination),
          onChange: (nextPage, pageSize) =>
            setPagination((prevState: { page: number; pageSize: number }) => ({
              ...prevState,
              page: nextPage,
              pageSize: pageSize,
            })),
        }}
      />

      <SampleTypesFormModal
        sampleTypeId={sampleTypeId}
        open={isOpenModal}
        onClose={handleCloseModal}
      />
    </>
  );
};
