import { useMemo, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Flex,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import dayjs from "dayjs";
import { ISort, useObjectState, useSorter } from "../hooks";
import { ColumnsType } from "antd/lib/table";
import { TTemplateShort } from "../api/templates.types";
import {
  dateFormat,
  defaultQS,
  downloadFile,
  format,
  InspectionStatusColors,
} from "../lib";
import {
  simpleApiRequest,
  useGetAllInspectionsQuery,
  useGetAvailablePropertyTypesQuery,
  useGetInfiniteAgentsQuery,
  useGetInfiniteTemplatesQuery,
} from "../api";
import { SmartSelectPagination } from "../components/SmartSelectWithPagination";
import { selectAvailableStatuses, useAppSelector } from "../store";

export const AllReportsPage = () => {
  const [sort, setSort] = useState<ISort>({
    sortKey: "desc",
    fieldKey: "",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50)
  const [isDownloading, setIsDownloading] = useState(false);

  const [filters, setFilters] = useObjectState<{
    agents?: string;
    "scheduledDate[after]"?: string;
    "scheduledDate[before]"?: string;
    status?: string;
    templates?: number[];
    propertyType?: string;
  }>({});

  const { availableStatuses } = useAppSelector((state) => ({
    availableStatuses: selectAvailableStatuses(state),
  }));

  const {
    data: reportData,
    isLoading: isReportDataLoading,
    isFetching: isReportDataFetching,
  } = useGetAllInspectionsQuery({ page, itemsPerPage: pageSize, ...filters });

  const { data: propertyTypesData, isLoading: isPropertyTypesLoading } =
    useGetAvailablePropertyTypesQuery();

  const { returnSorterProps } = useSorter(sort, setSort);

  const columns = useMemo<ColumnsType<TTemplateShort>>(
    () => [
      {
        dataIndex: "agent",
        render: (agent) => (
          <>
            {agent.email}({agent.firstName} {agent.lastName})
          </>
        ),
        ...returnSorterProps("agent.email", "Inspector"),
      },
      {
        dataIndex: "client",
        render: (client) => (
          <>
            {client.email}({client.firstName} {client.lastName})
          </>
        ),
        ...returnSorterProps("client.email", "Client"),
      },
      {
        dataIndex: "propertyType",
        ...returnSorterProps("propertyType", "Property Type"),
      },
      {
        dataIndex: "companyName",
        ...returnSorterProps("companyName", "Company Name"),
      },
      {
        dataIndex: "address",
        ...returnSorterProps("address", "Address"),
      },
      {
        dataIndex: "scheduledDate",
        render: (createdAt) => format(createdAt, dateFormat),
        ...returnSorterProps("scheduledDate", "Scheduled Date"),
      },
      {
        dataIndex: "startedAt",
        render: (startedAt) => format(startedAt),
        ...returnSorterProps("startedAt", "Started at"),
      },
      {
        dataIndex: "completedAt",
        render: (completedAt) => format(completedAt),
        ...returnSorterProps("completedAt", "Completed at"),
      },
      {
        dataIndex: "status",
        render: (status) => (
          <Tag
            color={
              InspectionStatusColors[
                status as keyof typeof InspectionStatusColors
              ]
            }
          >
            {availableStatuses?.[status]}
          </Tag>
        ),
        ...returnSorterProps("status", "Status"),
      },
    ],
    [returnSorterProps, availableStatuses]
  );

  const onGenerateClick = () => {
    setIsDownloading(true);

    simpleApiRequest(
      "inspections/report",
      defaultQS({
        ...filters,
        file: true,
      })
    )
      .then((res) => {
        if (res.status === 200) {
          return res.blob();
        }
      })
      .then((file) => {
        if (file) {
          downloadFile(
            file,
            `Inspection summary report ${
              filters["scheduledDate[after]"] || ""
            } ${filters["scheduledDate[before]"] || ""}.xlsx`
          );
        }
      })
      .catch((e) => console.log(e, "e"))
      .finally(() => setIsDownloading(false));
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Card title="Generate all inspection report section">
        <Flex justify="space-between">
          <Space>
            <DatePicker.RangePicker
              placeholder={["Scheduled from", "Scheduled to"]}
              value={
                filters["scheduledDate[after]"] !== undefined
                  ? [
                      dayjs(filters["scheduledDate[after]"], "YYYY-MM-DD"),
                      dayjs(filters["scheduledDate[before]"], "YYYY-MM-DD"),
                    ]
                  : undefined
              }
              onChange={(date) =>
                setFilters({
                  "scheduledDate[after]":
                    date && date[0] ? date[0].format("YYYY-MM-DD") : undefined,
                  "scheduledDate[before]":
                    date && date[1] ? date[1].format("YYYY-MM-DD") : undefined,
                })
              }
            />

            <Select
              style={{ minWidth: 175 }}
              placeholder="Status"
              value={filters.status}
              onChange={(status) => setFilters({ status })}
              allowClear
            >
              {availableStatuses &&
                Object.keys(availableStatuses).map((i) => (
                  <Select.Option key={i} value={i}>
                    {availableStatuses[i]}
                  </Select.Option>
                ))}
            </Select>

            <SmartSelectPagination
              mode="multiple"
              query={useGetInfiniteAgentsQuery}
              value={filters.agents}
              onChange={(agents) => setFilters({ agents })}
              style={{ minWidth: 175 }}
              placeholder="Inspectors"
              allowClear
            />

            <SmartSelectPagination
              query={useGetInfiniteTemplatesQuery}
              mode="multiple"
              value={filters.templates}
              onChange={(templates) => setFilters({ templates })}
              style={{ minWidth: 175 }}
              placeholder="Templates"
            />

            <Select
              options={
                propertyTypesData &&
                Object.keys(propertyTypesData)?.map((i) => ({
                  label: propertyTypesData[i],
                  value: i,
                }))
              }
              loading={isPropertyTypesLoading}
              style={{ minWidth: 175 }}
              placeholder="Property type"
              value={filters.propertyType}
              onChange={(propertyType) => setFilters({ propertyType })}
              allowClear
            />
          </Space>

          <Button
            type="primary"
            onClick={onGenerateClick}
            loading={isDownloading}
          >
            Generate xlsx
          </Button>
        </Flex>
      </Card>

      <Table
        dataSource={reportData?.data}
        columns={columns}
        loading={isReportDataLoading || isReportDataFetching}
        rowKey="id"
        pagination={{
          total: reportData?.pagination.totalItems,
          current: reportData?.pagination.current,
          pageSize: pageSize,
          onChange: (nextPage, pageSize) => {
            if (nextPage) setPage(nextPage);
            if (pageSize) setPageSize(pageSize);
          },
          showSizeChanger: true,
        }}
        scroll={{ x: 800 }}
      />
    </Space>
  );
};
