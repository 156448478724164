import { openDB } from "idb";
import {AttachedFile} from "./types";

const dbPromise = openDB("indoor-media-store", 1, {
  upgrade(db) {
    db.createObjectStore("files", { keyPath: "id", autoIncrement: true })
  }
})

export const saveFileToIndexedDB = async (file: AttachedFile) => {
  const db = await dbPromise;

  await db.add('files', { file, createdAt: new Date() })
}

export const getFilesFromIndexedDB = async () => {
  const db = await dbPromise;

  return await db.getAll('files');
};

export const deleteFileFromIndexedDB = async (id: number) => {
  const db = await dbPromise;

  await db.delete('files', id);
};