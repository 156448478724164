import { DragEvent, useState } from "react";
import {
  ColorPicker,
  Dropdown,
  Flex,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Space,
} from "antd";
import {
  EditOutlined,
  FlagOutlined,
  HolderOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { selectablePresets } from "lib";
import { setValueIfAdd } from "./helpers";

interface IEditCheckboxComponent {
  option: { name: number; key: number };
  onAdd: () => void;
  onRemove: (name: number) => void;
  isLastField: boolean;
  parentName: (number | string)[];
  form: FormInstance;
}

export const EditCheckableComponent = ({
  option,
  onAdd,
  onRemove,
  isLastField,
  parentName,
  form,
}: IEditCheckboxComponent) => {
  const [isOver, setIsOver] = useState(false);

  const parentValues = Form.useWatch([...parentName, option.key], form);

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    const { order: pickedOrder, type } = JSON.parse(
      e.dataTransfer.getData("text/plain")
    );

    if (type === "option") {
      const pickedField = form.getFieldValue([...parentName, pickedOrder]);
      const destinationField = form.getFieldValue([...parentName, option.name]);

      form.setFieldValue([...parentName, pickedOrder], {
        ...destinationField,
        orderOption: pickedOrder,
      });
      form.setFieldValue([...parentName, option.name], {
        ...pickedField,
        orderOption: option.name,
      });
    }

    setIsOver(false);
  };

  return (
    <Space
      id={option.name.toString()}
      style={{
        width: "25%",
        minWidth: "220px",
        borderRadius: 4,
        borderLeft: isOver ? `4px solid ${blue.primary}` : "",
        borderBottomLeftRadius: isOver ? 0 : 4,
        borderTopLeftRadius: isOver ? 0 : 4,
      }}
      key={option.name}
      onDragStart={(e) => {
        e.dataTransfer.setData(
          "text/plain",
          JSON.stringify({ order: option.name, type: "option" })
        );
      }}
      onDragLeave={() => setIsOver(false)}
      onDragOver={(e) => {
        e.preventDefault();

        const { type } = JSON.parse(e.dataTransfer.getData("text/plain"));

        if (type === "option") {
          setIsOver(true);
        }
      }}
      onDrop={onDrop}
      draggable
    >
      <HolderOutlined style={{ cursor: "move" }} />

      <Dropdown
        menu={{
          items: [
            {
              label: (
                <ColorPicker
                  trigger={"click"}
                  presets={selectablePresets}
                  onChange={(_, hex) =>
                    form.setFieldValue(
                      [...parentName, option.name, "color"],
                      hex
                    )
                  }
                  panelRender={(panel, extra) => <extra.components.Presets />}
                  destroyTooltipOnHide
                >
                  Highlight
                </ColorPicker>
              ),
              key: 0,
            },
            {
              label: `${parentValues?.flag ? "Remove" : "Set"} flag`,
              key: 1,
              onClick: () =>
                form.setFieldValue(
                  [...parentName, option.name, "flag"],
                  !parentValues?.flag
                ),
            },
            // {
            //   label: `${parentValues?.hasResponse ? "Remove" : "Add"} response`,
            //   key: 2,
            //   onClick: () =>
            //     form.setFieldValue(
            //       [...parentName, option.name, "hasResponse"],
            //       !parentValues?.hasResponse
            //     ),
            // },
            {
              label: "Delete",
              key: 3,
              onClick: () => onRemove(option.name),
            },
          ],
        }}
        trigger={["click"]}
      >
        <SettingOutlined />
      </Dropdown>

      {parentValues?.color && (
        <div
          style={{
            width: 16,
            height: 16,
            backgroundColor: parentValues?.color,
            borderRadius: 4,
          }}
        />
      )}

      {parentValues?.flag && <FlagOutlined style={{ color: "red" }} />}

      {parentValues?.hasResponse && <EditOutlined />}

      <Flex align="center">
        <Form.Item name={[option.name, "text"]} noStyle>
          <Input
            variant="borderless"
            placeholder="Label"
            onClick={isLastField ? () => onAdd() : undefined}
            style={{ width: "100%", minWidth: "200px", paddingLeft: 0 }}
            autoComplete="off"
            onDrop={(e) => {
              e.preventDefault();
            }}
          />
        </Form.Item>

        <Form.Item
          name={[option.name, "orderOption"]}
          // initialValue={option.name}
          hidden
          noStyle
        >
          <InputNumber />
        </Form.Item>
      </Flex>
    </Space>
  );
};
