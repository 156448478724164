import { useMemo } from "react";
import { FormInstance, Spin, Tabs } from "antd";
import { useGetTemplateQuery } from "api/templates";
import { ViewTemplatePage } from "./ViewTemplatePage";

interface IViewTemplate {
  id: number;
  form: FormInstance;
}

export const ViewTemplate = ({ id, form }: IViewTemplate) => {
  const { data: templateData, isLoading: isTemplateLoading } =
    useGetTemplateQuery({ id });

  const pagesItems = useMemo(
    () =>
      templateData?.pages.map((page) => ({
        label: page.title,
        key: page.id.toString(),
        children: <ViewTemplatePage page={page} form={form} />,
      })),
    [templateData]
  );

  return (
    <Spin spinning={isTemplateLoading}>
      <Tabs items={pagesItems} />
    </Spin>
  );
};
