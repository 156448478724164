import { useEffect, useMemo, useState } from "react";
import { App, Modal, Spin, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { useDeleteInspectionRoomMutation, useGetInspectionQuery } from "../api";
import { InspectionPage } from "components/inspections/InspectionPage";
import { TargetKey } from "../lib";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { AddRoomModal, InspectionCoverPage } from "../components";

export const CompleteInspectionPage = () => {
  const { notification } = App.useApp();
  const params = useParams<{ id: string }>();

  const [activeKey, setActiveKey] = useState("cover");
  const [showAddRoomModal, setShowAddRoomModal] = useState(false);
  const [submitPageId, setSubmitPageId] = useState<number>();

  const { data, isLoading, isFetching } = useGetInspectionQuery({
    id: Number(params.id),
  });

  const [
    deleteInspectionRooMutation,
    {
      isLoading: isDeleteInspectionRoomLoading,
      isSuccess: isDeleteInspectionRoomSuccess,
      error: deleteInspectionRoomError,
    },
  ] = useDeleteInspectionRoomMutation();

  const getGeneratedInspectionPages = () => {
    if (!data) return [];

    return data?.inspectionPages.map((page) => ({
      label: page.title,
      key: page.id.toString(),
      children: (
        <InspectionPage
          page={page}
          onAddRoomClick={() => setShowAddRoomModal(true)}
          submitPageId={submitPageId}
          onAfterSubmit={() => setSubmitPageId(undefined)}
        />
      ),
    }));
  };

  const pagesItems = useMemo(
    () => [
      {
        label: "Cover page",
        key: "cover",
        children: <InspectionCoverPage inspection={data} onAddRoomClick={() => setShowAddRoomModal(true)} />,
        closable: false,
      },
      ...getGeneratedInspectionPages(),
    ],
    [data]
  );

  useEffect(() => {
    if (isDeleteInspectionRoomSuccess) {
      notification.success({ message: "Room was deleted" });
      setActiveKey(pagesItems[0]?.key);
    }
  }, [isDeleteInspectionRoomSuccess, notification]);

  useEffect(() => {
    if (deleteInspectionRoomError) {
      notification.error({ message: "We we're not able to delete the room" });
      console.error(deleteInspectionRoomError, "deleteInspectionRoomError");
    }
  }, [deleteInspectionRoomError, notification]);

  const onEdit = (targetKey: TargetKey, action: "add" | "remove") => {
    if (action === "add") {
      setShowAddRoomModal(true);
      return;
    }

    Modal.confirm({
      title: "Are you sure about deleting this page?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () =>
        deleteInspectionRooMutation({
          inspectionId: params.id,
          roomId: targetKey,
        }),
    });
  };

  return (
    <Spin
      spinning={isLoading || isFetching || isDeleteInspectionRoomLoading}
      style={{ minHeight: 400 }}
    >
      <Tabs
        type="editable-card"
        items={pagesItems}
        activeKey={activeKey}
        onEdit={onEdit}
        onChange={(activeKey) => setActiveKey(activeKey)}
      />

      <AddRoomModal
        open={showAddRoomModal}
        onCancel={() => setShowAddRoomModal(false)}
        onAddRoomSuccess={(newRoomId: number) => {
          if (activeKey !== "cover") {
            setSubmitPageId(Number(activeKey));
          }

          setActiveKey(newRoomId.toString());
        }}
      />
    </Spin>
  );
};
