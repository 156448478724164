import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig } from "./index";
import { defaultQS } from "lib";
import { TSummary } from "./inspections.types";

export const summariesApi = createApi({
  ...defaultApiConfig,
  reducerPath: "summariesApi",
  tagTypes: ["Summaries", "Summary"],
  endpoints: (builder) => ({
    getSummary: builder.query<TSummary, { id: number }>({
      query: ({ id }) => `inspections/${id}/summary`,
      providesTags: ["Summary"],
    }),
    updateSummary: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `inspections/${id}/save-summary`,
        method: "POST",
        body,
      }),
    }),
    shareSummary: builder.mutation({
      query: ({ id, ...params }) => ({
        url: `inspections/${id}/summary/share?${defaultQS(params)}`,
        method: "GET",
      }),
    }),
    saveLabResult: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `inspections/${id}/lab-results`,
        method: "POST",
        body,
      }),
    }),
    deleteLabResult: builder.mutation({
      query: ({ inspectionId, labResultId }) => ({
        url: `inspections/${inspectionId}/lab-results/${labResultId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Summary"],
    }),
    saveSummaryComment: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `inspections/${id}/contact`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Summary"],
    }),
    saveAdditionalInfo: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `inspections/${id}/additional-info`,
        method: "POST",
        body,
      }),
    }),
    deleteAdditionalInfo: builder.mutation({
      query: ({ inspectionId, additionalInfoId }) => ({
        url: `inspections/${inspectionId}/additional-info/${additionalInfoId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Summary"],
    }),
  }),
});

export const {
  useGetSummaryQuery,
  useUpdateSummaryMutation,
  useShareSummaryMutation,
  useSaveLabResultMutation,
  useDeleteLabResultMutation,
  useSaveSummaryCommentMutation,
  useSaveAdditionalInfoMutation,
  useDeleteAdditionalInfoMutation,
} = summariesApi;
