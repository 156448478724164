import { PropsWithChildren, useState } from "react";
import { Button, Flex } from "antd";

export const HtmlEditorPlaceholder = ({
  children,
  label,
}: PropsWithChildren<{ label: string }>) => {
  const [showEditor, setShowEditor] = useState(false);

  if (showEditor) {
    return <>{children}</>;
  }

  return (
    <Flex justify="center" align="center">
      <Button onClick={() => setShowEditor(true)}>{label}</Button>
    </Flex>
  );
};
