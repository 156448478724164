import { App, Form, Input, Modal } from "antd";
import { SmartSelectPagination } from "../SmartSelectWithPagination";
import {
  useAddInspectionRoomMutation,
  useGetInfiniteTemplatesQuery,
} from "../../api";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

interface IAddRoomModal {
  open: boolean;
  onCancel: () => void;
  onAddRoomSuccess?: (newRoomId: number) => void;
}

export const AddRoomModal = ({
  open,
  onCancel,
  onAddRoomSuccess,
}: IAddRoomModal) => {
  const { notification } = App.useApp();
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm();

  const [
    addInspectionRoomMutation,
    {
      isLoading: isAddInspectionRoomLoading,
      data: addInspectionRoomData,
      error: addInspectionRoomError,
    },
  ] = useAddInspectionRoomMutation();

  useEffect(() => {
    if (addInspectionRoomData) {
      notification.success({ message: "Room was successfully added" });

      onAddRoomSuccess && onAddRoomSuccess(addInspectionRoomData.id);

      form.resetFields();

      onCancel();
    }
  }, [addInspectionRoomData]);

  useEffect(() => {
    if (addInspectionRoomError) {
      notification.error({ message: "We can not add this room" });
      console.error(addInspectionRoomError, "addInspectionRoomError");
    }
  }, [addInspectionRoomError]);

  const onOk = () => {
    const formValues = form.getFieldsValue();

    addInspectionRoomMutation({ id: params.id, ...formValues });
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Add room"
      styles={{ body: { margin: "2rem 0" } }}
      onOk={onOk}
      okButtonProps={{ loading: isAddInspectionRoomLoading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="pageName" label="Room name">
          <Input placeholder="Room name" />
        </Form.Item>

        <Form.Item
          name="templateId"
          label="Template"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <SmartSelectPagination
            query={useGetInfiniteTemplatesQuery}
            placeholder="Choose template"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
