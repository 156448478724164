import React, { useState, useRef, useMemo, useEffect } from "react";
import { Stage, Layer, Image, Line, Text, Arrow } from "react-konva";
import Konva from "konva";
import { useImage } from "hooks";
import {base64ToArrayBuffer, ToolTypes} from "lib";

interface IDrawingApp {
  imageUrl: string;
  activeTool?: ToolTypes;
  onImageChange: (base42: string, binary: ArrayBufferLike) => void;
  textToolValue?: string;
}

const DrawingApp = ({
  imageUrl,
  activeTool,
  onImageChange,
  textToolValue,
}: IDrawingApp) => {
  const stageRef = useRef<Konva.Stage>(null);

  const [image] = useImage(imageUrl, "Anonymous");
  const [line, setLine] = useState<number[]>([]);
  const [arrow, setArrow] = useState<number[]>([]);
  const [text, setText] = useState<
    | {
        x: number;
        y: number;
        text?: string;
        fontSize: number;
        fill: string;
      }
    | undefined
  >();
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    setArrow([]);
    setLine([]);
    setText(undefined);
  }, [imageUrl]);

  const handleMouseDown = (event: any) => {
    setIsDrawing(true);

    const pos = event.target.getStage().getPointerPosition();

    switch (activeTool) {
      case "pen":
        setLine([pos.x, pos.y]);
        break;
      case "arrow":
        setArrow([pos.x, pos.y, pos.x, pos.y]);
        break;
      case "text":
        setText({
          x: pos.x,
          y: pos.y,
          text: textToolValue,
          fontSize: 20,
          fill: "red",
        });
        break;
    }
  };

  const handleMouseMove = (event: any) => {
    if (!isDrawing) return;

    const point = event.target.getStage().getPointerPosition();

    if (activeTool === "pen") {
      setLine((prevState) => [...prevState, point.x, point.y]);
    }

    if (activeTool === "arrow") {
      setArrow((prevState) => [prevState[0], prevState[1], point.x, point.y]);
    }
  };

  const handleMouseUp = () => {
    if (!isDrawing) return;

    setIsDrawing(false);

    const dataURL = stageRef.current?.toDataURL();

    if (dataURL) {
      onImageChange(dataURL, base64ToArrayBuffer(dataURL.split(",")[1]));
    }
  };

  const scale = useMemo(() => {
    const usableInnerWidth = window.innerWidth - 200;
    const usableInnerHeight = window.innerHeight - 200;

    if (image?.height && image.width) {
      return Math.min(
        usableInnerWidth / image?.width,
        usableInnerHeight / image?.height
      );
    }

    return 1;
  }, [image]);

  return (
    <Stage
      ref={stageRef}
      width={image?.width}
      height={image?.height}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{ transform: `scale(${scale})` }}
    >
      <Layer>
        <Image image={image} />

        {line && (
          <Line
            key="0"
            points={line}
            stroke="red"
            strokeWidth={2}
            tension={0.5}
            lineCap="round"
            globalCompositeOperation="source-over"
          />
        )}

        {arrow && (
          <Arrow
            key="1"
            points={arrow}
            stroke="red"
            fill="red"
            strokeWidth={2}
          />
        )}

        {text && (
          <Text
            key="2"
            x={text.x}
            y={text.y}
            text={text.text}
            fontSize={text.fontSize}
            fill={text.fill}
            draggable
          />
        )}
      </Layer>
    </Stage>
  );
};

export default DrawingApp;
