import { useEffect } from "react";
import { load } from "react-cookies";
import { useNavigate } from "react-router-dom";
import { getRouteByName } from "lib";
import { MainLayout } from "./MainLayout";

export const PrivateLayer = () => {
  const navigate = useNavigate();

  const loadedToken = load("access-token");

  useEffect(() => {
    if (!loadedToken) {
      navigate(getRouteByName("LogInPage"));
    }
  }, [loadedToken, navigate]);

  if (loadedToken) return <MainLayout />;

  return <></>;
};
