import { today } from "lib";
import { DatePicker, Form, Typography } from "antd";
import { TInspectionQuestion } from "api/templates.types";
import dayjs from "dayjs";

interface IInspectionDatePicker {
  question: TInspectionQuestion;
}

export const InspectionDatePicker = ({ question }: IInspectionDatePicker) => {
  return (
    <Form.Item
      name={[question.id, "textResponse"]}
      key={question.id}
      label={<Typography.Text strong>{question.text}</Typography.Text>}
      required={question.required}
      rules={[
        { required: question.required, message: "This field is required" },
      ]}
      initialValue={
        question.inspectionQuestionAnswers[0]?.textResponse
          ? dayjs(question.inspectionQuestionAnswers[0]?.textResponse)
          : undefined
      }
    >
      <DatePicker
        placeholder="Date/Time"
        disabledDate={(data) => data < today}
        showNow={false}
        showTime
      />
    </Form.Item>
  );
};
