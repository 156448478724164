import { Dispatch, ReactNode, SetStateAction } from "react";
import { Flex } from "antd";
import { SortOrder } from "antd/lib/table/interface";

export interface ISort {
  fieldKey: string;
  sortKey: TSortKeys;
}

export type TSortKeys = "asc" | "desc" | null;

export const useSorter = (
  sort: ISort,
  setSort: Dispatch<SetStateAction<ISort>>
) => ({
  returnSorterProps: (fieldKey: string, title: ReactNode) => ({
    title: (
      <Flex
        justify="space-between"
        onClick={() =>
          setSort((prevState) => ({
            fieldKey: fieldKey,
            sortKey:
              prevState.fieldKey !== fieldKey
                ? "asc"
                : getNextSortKeyValue(prevState.sortKey),
          }))
        }
        style={{ cursor: "pointer" }}
      >
        {title}
      </Flex>
    ),
    sorter: true,
    sortOrder:
      fieldKey === sort.fieldKey ? getAntDTableSortKey(sort.sortKey) : null,
  }),
});

const getNextSortKeyValue = (currenKey: TSortKeys) => {
  if (currenKey === "asc") return "desc";

  if (currenKey === "desc") return null;

  return "asc";
};

const getAntDTableSortKey = (sortKey: TSortKeys): SortOrder => {
  if (sortKey === "asc") return "ascend";

  if (sortKey === "desc") return "descend";

  return null;
};
