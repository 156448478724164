import { useState } from "react";
import { App, Button, Card, Flex } from "antd";
import { downloadFileFromUrl } from "lib";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { TSummary } from "api/inspections.types";
import { useDeleteLabResultMutation } from "api";
import { AddLabResultsModal } from "../AddLabResultsModal";

interface ISummaryLabResultSection {
  summaryData?: TSummary;
  refetch: () => void;
}

export const SummaryLabResultSection = ({
  summaryData,
  refetch,
}: ISummaryLabResultSection) => {
  const { modal } = App.useApp();

  const [showAddModal, setShowAddModal] = useState(false);

  const [deleteLabResultMutation] = useDeleteLabResultMutation();

  return (
    <>
      <Card title="Lab results" id="labResults">
        <Flex gap={12} vertical>
          {summaryData?.inspectionData.labResults.map((labResult, key) => (
            <Card key={key}>
              <Flex justify="space-between" align="center">
                <div>{labResult.name}</div>

                <Flex gap={24}>
                  <Button
                    size="small"
                    onClick={() =>
                      downloadFileFromUrl(
                        `${process.env.REACT_APP_S3_URL}/lab/${labResult.fileName}`,
                        labResult.fileName
                      )
                    }
                  >
                    <DownloadOutlined /> {labResult.fileName}
                  </Button>

                  <DeleteOutlined
                    style={{ color: "red" }}
                    onClick={() =>
                      modal.confirm({
                        title: "Delete lab result",
                        content: (
                          <div style={{ margin: "8px 0" }}>
                            Are you sure you want to delete this lab result?
                          </div>
                        ),
                        onOk: () =>
                          deleteLabResultMutation({
                            inspectionId: summaryData.inspectionData.id,
                            labResultId: labResult.id,
                          }),
                      })
                    }
                  />
                </Flex>
              </Flex>
            </Card>
          ))}
        </Flex>

        <Flex justify="center" style={{ marginTop: 12 }}>
          <Button onClick={() => setShowAddModal(true)}>Add lab result</Button>
        </Flex>
      </Card>

      {showAddModal && (
        <AddLabResultsModal
          onCancel={() => setShowAddModal(false)}
          onOk={refetch}
        />
      )}
    </>
  );
};
