import { useEffect, useMemo, useState } from "react";
import {App, Flex, Input, Layout, Modal, Space, Table, theme} from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  FormOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { EditUserForm } from "components";
import {IUser, useDeleteUserMutation, useGetUsersQuery, useResetUserPasswordMutation} from "api";
import { EUserRoles } from "lib";
import { ISort, useSorter } from "hooks";

export const ClientsPage = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { notification } = App.useApp();
  const { token } = theme.useToken();

  const [showUserForm, setShowUserForm] = useState(false);
  const [editUser, setEditUser] = useState<IUser>();
  const [sort, setSort] = useState<ISort>({
    sortKey: "desc",
    fieldKey: "createdAt",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState<string>();

  const { returnSorterProps } = useSorter(sort, setSort);

  const {
    data: usersData,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useGetUsersQuery({
    page,
    itemsPerPage: pageSize,
    roles: EUserRoles.ROLE_INDOOR_CLIENT,
    search,
    [`order[${sort.fieldKey}]`]: sort.sortKey,
  });

  const [
    resetPassMutation,
    { isSuccess: isResetPassSuccess, error: resetPassError },
  ] = useResetUserPasswordMutation();

  const [
    deleteUserMutation,
    { isSuccess: isDeleteUserSuccess, error: deleteUserError },
  ] = useDeleteUserMutation();

  useEffect(() => {
    if (isResetPassSuccess) {
      notification.success({ message: "Password will be changed" });
      Modal.destroyAll();
    }
  }, [isResetPassSuccess, notification]);

  useEffect(() => {
    if (resetPassError) {
      notification.error({ message: "We can't submit this request right now" });
    }
  }, [resetPassError, notification]);

  useEffect(() => {
    if (isDeleteUserSuccess) {
      notification.success({ message: "User was deleted" });
      Modal.destroyAll();
    }
  }, [isDeleteUserSuccess, notification]);

  useEffect(() => {
    if (deleteUserError) {
      notification.error({ message: "We can not delete this user" });
    }
  }, [deleteUserError, notification]);

  const usersColumns = useMemo<ColumnsType<IUser>>(
    () => [
      {
        dataIndex: "id",
        ...returnSorterProps("id", "ID"),
      },
      {
        dataIndex: "firstName",
        ...returnSorterProps("firstName", "First name"),
      },
      {
        dataIndex: "lastName",
        ...returnSorterProps("lastName", "Last name"),
      },
      {
        dataIndex: "email",
        ...returnSorterProps("email", "Email"),
      },
      {
        title: "Phone",
        sorter: true,
        dataIndex: "phone",
      },
      {
        title: "Actions",
        align: "right",
        dataIndex: "id",
        render: (_, user) => (
          <Space size={24}>
            <UndoOutlined
              className="with-action"
              onClick={() =>
                modal.confirm({
                  title: "Do you want to reset this user password?",
                  okText: "Yes",
                  onOk: () => resetPassMutation({ id: user.id }),
                })
              }
            />

            <FormOutlined
              className="with-action"
              onClick={() => {
                setShowUserForm(true);
                setEditUser(user);
              }}
            />

            <DeleteOutlined
              style={{ color: token.red5 }}
              onClick={() =>
                modal.confirm({
                  title: "Do you want to delete this user?",
                  icon: <ExclamationCircleFilled />,
                  okText: "Yes",
                  okType: "danger",
                  cancelText: "No",
                  onOk: () => deleteUserMutation({ id: user.id }),
                })
              }
            />
          </Space>
        ),
      },
    ],
    [resetPassMutation, modal, returnSorterProps]
  );

  return (
    <>
      {contextHolder}

      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search
            placeholder="Search"
            style={{ width: 200 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            allowClear
          />
        </Space>
      </Flex>
      <Layout style={{ backgroundColor: "white" }}>
        <Table
          columns={usersColumns}
          loading={isUsersLoading || isUsersFetching}
          dataSource={usersData?.data}
          rowKey="id"
          scroll={{ x: 800 }}
          pagination={{
            total: usersData?.pagination.totalItems,
            current: usersData?.pagination.current,
            pageSize: pageSize,
            onChange: (nextPage, pageSize) => {
              if (nextPage) setPage(nextPage);
              if (pageSize) setPageSize(pageSize);
            },
            showSizeChanger: true,
          }}
        />
      </Layout>

      {showUserForm && (
        <EditUserForm
          user={editUser}
          onClose={() => {
            setShowUserForm(false);
            setEditUser(undefined);
          }}
        />
      )}
    </>
  );
};
