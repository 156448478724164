import { Form, FormInstance } from "antd";
import { TQuestion } from "api/templates.types";
import { ViewTemplatesQuestion } from "./ViewTemplatesQuestion";

export const ViewTemplateQuestions = ({
  form,
  questions,
  parent,
}: {
  form: FormInstance;
  questions: TQuestion[];
  parent?: TQuestion;
}) => {
  const triggerAnswer = Form.useWatch(["labResultsAnswers", parent?.id, 'selectedOrders'], form);

  return (
    <>
      {questions?.map((question, key) => (
        <ViewTemplatesQuestion
          question={question}
          questionKey={key}
          triggerAnswer={triggerAnswer}
          parent={parent}
          form={form}
          key={key}
        />
      ))}
    </>
  );
};
