import { ViewTemplate } from "./ViewTemplate";
import { Form, Modal } from "antd";

interface IViewTemplateModal {
  id: number;
  onCancel: () => void;
}

export const ViewTemplateModal = ({ id, onCancel }: IViewTemplateModal) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title="View Template"
      open
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={onCancel}
      okText="Viewed"
      onOk={onCancel}
    >
      <Form form={form} layout="vertical">
        <ViewTemplate id={id} form={form} />
      </Form>
    </Modal>
  );
};
