import { simplePOSTRequest } from "../api";
import {
  UploadProgressEvent,
  UploadRequestError,
} from "rc-upload/lib/interface";
import { RcFile } from "antd/lib/upload";

export const uploadFileToS3 = async (
  file: string | Blob | RcFile,
  fileName: string,
  onProgress?: (event: UploadProgressEvent) => void,
  onSuccess?: (body: any, xhr?: XMLHttpRequest) => void,
  onError?: (event: UploadRequestError | ProgressEvent, body?: any) => void
) =>
  await simplePOSTRequest("s3/generate-url", {
    filename: fileName,
    expiration: 60 * 5,
  })
    .then((res) => res.json())
    .then((res) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percent = Math.round((event.loaded / event.total) * 100);
          onProgress && onProgress({ percent });
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          onSuccess && onSuccess(xhr.responseText);
        } else {
          onError && onError(new Error("Upload failed."));
        }
      };

      xhr.onerror = () => {
        onError && onError(new Error("Upload error."));
      };

      xhr.open("PUT", res.url);

      xhr.send(file);
    });
