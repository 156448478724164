import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import { useGetSummaryQuery } from "../api";
import {
  Button,
  Card,
  Collapse,
  ConfigProvider,
  Flex,
  Form,
  Result,
  Spin,
} from "antd";
import {
  ClientSummaryAnswer,
  SummaryCommentsSection,
  SummaryOverviewSection,
} from "components";
import {
  downloadFileFromUrl,
  format,
  fullDateTimeFormat,
  getRouteByName,
} from "lib";

import { DownloadOutlined } from "@ant-design/icons";
import { ColorLogo } from "../assets/svgs";

export const ClientSummaryPage = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { data: summaryData, isLoading } = useGetSummaryQuery({
    id: Number(params.id),
  });

  const {
    flaggedAnswers = [],
    restAnswers = [],
    labResults = [],
  } = useMemo(
    () => ({
      flaggedAnswers:
        summaryData?.flaggedAnswers &&
        Object.keys(summaryData?.flaggedAnswers).map((pageKey) => ({
          key: summaryData?.flaggedAnswers[pageKey].id,
          label: summaryData?.flaggedAnswers[pageKey].title,
          children: (
            <Form.List name="inspectionAnswers">
              {() =>
                summaryData?.flaggedAnswers[pageKey].answers.map((answer) => (
                  <ClientSummaryAnswer answer={answer} key={answer.id} />
                ))
              }
            </Form.List>
          ),
        })),
      restAnswers:
        summaryData?.restAnswers &&
        Object.keys(summaryData?.restAnswers).map((pageKey) => ({
          key: summaryData?.restAnswers[pageKey].id,
          label: summaryData?.restAnswers[pageKey].title,
          children: (
            <Form.List name="inspectionAnswers">
              {() =>
                summaryData?.restAnswers[pageKey].answers.map((answer) => (
                  <ClientSummaryAnswer answer={answer} key={answer.id} />
                ))
              }
            </Form.List>
          ),
        })),
      labResults: summaryData?.inspectionData.labResults.map((labResult) => ({
        key: labResult.id,
        label: (
          <Flex justify="space-between" align="center">
            <div>{labResult.name}</div>
            <Button
              size="small"
              onClick={() =>
                downloadFileFromUrl(
                  `${process.env.REACT_APP_S3_URL}/lab/${labResult.fileName}`,
                  labResult.fileName
                )
              }
            >
              <DownloadOutlined /> {labResult.fileName}
            </Button>
          </Flex>
        ),
      })),
    }),
    [summaryData]
  );

  const { toPDF, targetRef: printTargetRef } = usePDF({
    filename:
      `Completed at ${
        summaryData?.inspectionData.completedAt &&
        format(summaryData?.inspectionData.completedAt, fullDateTimeFormat)
      }` || "page.pdf",
    page: {
      margin: 5,
    },
  });

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            headerBg: "var(--ant-blue-1)",
          },
          Form: {
            itemMarginBottom: 0,
          },
          Timeline: {
            itemPaddingBottom: 0,
          },
        },
      }}
    >
      <Spin spinning={isLoading}>
        <Card
          style={{ backgroundColor: "var(--ant-blue-1)", marginBottom: 16 }}
        >
          <Flex justify="space-between">
            <Flex style={{ margin: 12 }} gap={12} vertical>
              <Card
                style={{ background: "white", paddingRight: 36, fontSize: 18 }}
              >
                <div style={{ fontSize: 24 }}>
                  <strong>
                    {summaryData?.inspectionData.client.firstName}{" "}
                    {summaryData?.inspectionData.client.lastName}
                  </strong>
                </div>
                <div>
                  {[
                    summaryData?.inspectionData.addressStateFullName,
                    summaryData?.inspectionData.addressCity,
                    summaryData?.inspectionData.addressZip,
                    summaryData?.inspectionData.address,
                  ].join(", ")}
                </div>
                <div>{summaryData?.inspectionData.client.email}</div>
                <div>{summaryData?.inspectionData.client.phone}</div>
              </Card>

              <Card
                style={{ background: "white", paddingRight: 36, fontSize: 18 }}
              >
                <div style={{ fontSize: 20 }}>
                  <strong>Contact Your Inspector</strong>
                </div>
                <div>
                  {summaryData?.inspectionData.agent.firstName}{" "}
                  {summaryData?.inspectionData.agent.lastName}
                </div>
                <div>{summaryData?.inspectionData.agent.email}</div>
                <div>{summaryData?.inspectionData.agent.phone}</div>
              </Card>
            </Flex>

            <ColorLogo style={{ fontSize: 30 }} />
          </Flex>
        </Card>

        <div>
          <Flex justify="space-between">
            <div>
              <div style={{ fontSize: 20, fontWeight: "bold" }}>
                Inspection summary for{" "}
                {summaryData?.inspectionData.client.firstName}{" "}
                {summaryData?.inspectionData.client.lastName}
              </div>
              <div
                style={{
                  marginBottom: 24,
                  color: "var(--ant-color-text-secondary)",
                }}
              >
                Completed at{" "}
                {summaryData?.inspectionData.completedAt &&
                  format(
                    summaryData?.inspectionData.completedAt,
                    fullDateTimeFormat
                  )}
              </div>
            </div>

            <div className="pdf-hide">
              <Button onClick={() => toPDF()}>Download PDF</Button>
            </div>
          </Flex>

          {summaryData ? (
            <Form layout="vertical" form={form}>
              <Flex gap={12} vertical>
                <Flex ref={printTargetRef} gap={12} vertical>
                  {summaryData?.inspectionData.inspectionSummary && (
                    <Card title="Inspection summary">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: summaryData.inspectionData.inspectionSummary,
                        }}
                      />
                    </Card>
                  )}

                  {Boolean(flaggedAnswers.length) && (
                    <Card title="Flagged items">
                      <Collapse
                        items={flaggedAnswers}
                        defaultActiveKey={flaggedAnswers.map((i) => i.key)}
                      />
                    </Card>
                  )}

                  {Boolean(restAnswers.length) && (
                    <Card title="Inspections">
                      <Collapse
                        items={restAnswers}
                        defaultActiveKey={restAnswers.map((i) => i.key)}
                      />
                    </Card>
                  )}

                  {Boolean(labResults.length) && (
                    <Card title="Lab results">
                      <Collapse
                        items={labResults}
                        defaultActiveKey={labResults.map((i) => i.key)}
                      />
                    </Card>
                  )}
                </Flex>

                <SummaryOverviewSection summaryData={summaryData} />

                <SummaryCommentsSection summaryData={summaryData} />
              </Flex>
            </Form>
          ) : (
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={
                <Button
                  type="primary"
                  onClick={() => navigate(getRouteByName("InspectionsPage"))}
                >
                  Go back
                </Button>
              }
            />
          )}
        </div>
      </Spin>
    </ConfigProvider>
  );
};
