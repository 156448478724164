import { EditTemplatePage } from "../pages/EditTemplatePage";
import { TemplatesPage } from "../pages/TemplatesPage";
import { InspectionsPage } from "../pages/InspectionsPage";
import { UsersPage } from "../pages/UsersPage";
import { LogInPage } from "../pages/LogInPage";
import { RequestResetPassPage } from "../pages/RequestResetPassPage";
import { SetPasswordPage } from "../pages/SetPasswordPage";

import {
  AuditOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  TeamOutlined,
  SettingOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { MyProfilePage } from "../pages/MyProfilePage";
import { CreateInspectionPage } from "../pages/CreateInspectionPage";
import { EditInspectionPage } from "../pages/EditInspectionPage";
import { CompleteInspectionPage } from "../pages/CompleteInspectionPage";
import { EUserRoles } from "./types";
import { EditTemplateForm } from "../components";
import { SummaryPage } from "../pages/SummaryPage";
import { ClientSummaryPage } from "../pages/ClientSummaryPage";
import { ClientSummaryRedirectPage } from "../pages/ClientSummaryRedirectPage";
import { AllReportsPage } from "../pages/AllReportsPage";
import { ByTypeReportsPage } from "../pages/ByTypeReportsPage";
import { ClientsPage } from "../pages/ClientsPage";
import { ProductsPage } from "../pages/ProductsPage";
import { ReadyAnswersPage } from "../pages/ReadyAnswersPage";
import { SamplePage } from "../pages/SamplePage";

enum RoutesCategories {
  NONE = "none",
  USERS = "users",
  CLIENTS = "clients",
  TEMPLATES = "templates",
  INSPECTIONS = "inspections",
  REPORTS = "reports",
  REPORTS2 = "reports2",
  SETTINGS = "settings",
  SETTINGS2 = "settings2",
  SAMPLE = "sample",
}

export const publicRoutes = [
  {
    name: "LogInPage",
    path: "/logIn",
    element: <LogInPage />,
  },
  {
    name: "RequestResetPassPage",
    path: "/requestResetPass",
    element: <RequestResetPassPage />,
  },
  {
    name: "SetPasswordPage",
    path: "/setPassword",
    element: <SetPasswordPage />,
  },
  {
    name: "ClientSummaryRedirectPage",
    path: "/summary/:id",
    element: <ClientSummaryRedirectPage />,
  },
];

export const adminRoutes = [
  {
    label: "Users",
    name: "UsersPage",
    path: "/users",
    icon: <TeamOutlined />,
    category: RoutesCategories.USERS,
    element: <UsersPage />,
    roles: [EUserRoles.ROLE_INDOOR_ADMIN],
  },
  {
    label: "Clients",
    name: "ClientsPage",
    path: "/users-clients",
    icon: <TeamOutlined />,
    category: RoutesCategories.CLIENTS,
    element: <ClientsPage />,
    roles: [EUserRoles.ROLE_INDOOR_ADMIN],
  },
  {
    label: "Inspections",
    name: "InspectionsPage",
    path: "/inspections",
    icon: <AuditOutlined />,
    category: RoutesCategories.INSPECTIONS,
    element: <InspectionsPage />,
    roles: [
      EUserRoles.ROLE_INDOOR_ADMIN,
      EUserRoles.ROLE_INDOOR_MANAGER,
      EUserRoles.ROLE_INDOOR_AGENT,
      EUserRoles.ROLE_INDOOR_CLIENT,
    ],
  },
  {
    name: "CreateInspectionPage",
    path: "/inspections/create",
    category: RoutesCategories.INSPECTIONS,
    element: <CreateInspectionPage />,
    roles: [],
  },
  {
    name: "EditInspectionPage",
    path: "/inspections/:id",
    category: RoutesCategories.INSPECTIONS,
    element: <EditInspectionPage />,
    roles: [],
  },
  {
    name: "CompleteInspectionPage",
    path: "/inspections/:id/completing",
    category: RoutesCategories.INSPECTIONS,
    element: <CompleteInspectionPage />,
    roles: [],
  },
  {
    name: "SummaryPage",
    path: "/inspection/:id/summary",
    category: RoutesCategories.INSPECTIONS,
    element: <SummaryPage />,
    roles: [],
  },
  {
    name: "ClientSummaryPage",
    path: "/inspection/:id/client-summary",
    category: RoutesCategories.INSPECTIONS,
    element: <ClientSummaryPage />,
    roles: [],
  },
  {
    label: "Templates",
    name: "TemplatesPage",
    path: "/templates",
    icon: <FileTextOutlined />,
    category: RoutesCategories.TEMPLATES,
    element: <TemplatesPage />,
    roles: [EUserRoles.ROLE_INDOOR_ADMIN],
  },
  {
    name: "CreateTemplatePage",
    path: "/templates/create",
    category: RoutesCategories.TEMPLATES,
    element: <EditTemplateForm />,
    roles: [],
  },
  {
    name: "EditTemplatePage",
    path: "/templates/:id",
    category: RoutesCategories.TEMPLATES,
    element: <EditTemplatePage />,
    roles: [],
  },
  {
    name: "MyProfilePage",
    path: "/profile/",
    category: RoutesCategories.NONE,
    element: <MyProfilePage />,
    roles: [],
  },
  {
    label: "All reports",
    name: "AllInspectionSummaryReportPage",
    path: "/reports-all",
    icon: <FileDoneOutlined />,
    category: RoutesCategories.REPORTS,
    element: <AllReportsPage />,
    roles: [EUserRoles.ROLE_INDOOR_ADMIN, EUserRoles.ROLE_INDOOR_AGENT],
  },
  {
    label: "By type reports",
    name: "InspectionTypeReportPage",
    path: "/type-reports",
    icon: <FileProtectOutlined />,
    category: RoutesCategories.REPORTS2,
    element: <ByTypeReportsPage />,
    roles: [EUserRoles.ROLE_INDOOR_ADMIN, EUserRoles.ROLE_INDOOR_AGENT],
  },
  {
    label: "Ready answers",
    name: "SettingsPage",
    path: "/defined-settings",
    icon: <SettingOutlined />,
    category: RoutesCategories.SETTINGS,
    element: <ReadyAnswersPage />,
    roles: [EUserRoles.ROLE_INDOOR_ADMIN],
  },
  {
    label: "Products",
    name: "ProductsPage",
    path: "/products",
    icon: <SettingOutlined />,
    category: RoutesCategories.SETTINGS2,
    element: <ProductsPage />,
    roles: [EUserRoles.ROLE_INDOOR_ADMIN],
  },
  {
    label: "Sample types",
    name: "SamplePage",
    path: "/sample",
    icon: <ProjectOutlined />,
    category: RoutesCategories.SAMPLE,
    element: <SamplePage />,
    roles: [EUserRoles.ROLE_INDOOR_ADMIN],
  },
];

export const routes = [...publicRoutes, ...adminRoutes];
