import { configureStore } from "@reduxjs/toolkit";
import AppSettingsSlice from "./appSettingsSlice";
import {
  authApi,
  inspectionsApi,
  productsApi,
  readyAnswersApi,
  reportsApi,
  summariesApi,
  templatesApi,
  usersApi,
  sampleTypesApi,
} from "api";
import LoggedAdminSlice from "./loggedAdminSlice";

export const store = configureStore({
  reducer: {
    appSettings: AppSettingsSlice,
    loggedAdmin: LoggedAdminSlice,
    [usersApi.reducerPath]: usersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [inspectionsApi.reducerPath]: inspectionsApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [summariesApi.reducerPath]: summariesApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [readyAnswersApi.reducerPath]: readyAnswersApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [sampleTypesApi.reducerPath]: sampleTypesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      usersApi.middleware,
      authApi.middleware,
      inspectionsApi.middleware,
      templatesApi.middleware,
      summariesApi.middleware,
      reportsApi.middleware,
      readyAnswersApi.middleware,
      productsApi.middleware,
      sampleTypesApi.middleware,
    ]),
});
