import { Badge, Flex, InputNumber, Select } from "antd";
import { ENumericFieldConditions } from "lib/types";
import { useEffect, useState } from "react";
import { FlagOutlined } from "@ant-design/icons";

interface INumericFieldConditions {
  value?: string;
  onChange?: (val: string) => void;
}

export const NumericFieldConditions = ({
  value,
  onChange,
}: INumericFieldConditions) => {
  const [firstNumber, setFirstNumber] = useState<string | number | null>();
  const [secondNumber, setSecondNumber] = useState<string | number | null>();
  const [type, setType] = useState<ENumericFieldConditions>(
    ENumericFieldConditions.DIFFERENT
  );

  useEffect(() => {
    if (onChange) {
      onChange(JSON.stringify({ firstNumber, type, secondNumber }));
    }
  }, [firstNumber, secondNumber, type]);

  useEffect(() => {
    if (value) {
      const newValues = JSON.parse(value);

      setFirstNumber(newValues.firstNumber);
      setSecondNumber(newValues.secondNumber);
      setType(newValues.type);
    }
  }, []);

  return (
    <Flex style={{ marginLeft: 10 }} align="baseline">
      <Badge
        count={<FlagOutlined style={{ color: "red" }} />}
        offset={[-4, -6]}
      >
        {" "}
      </Badge>

      {type === ENumericFieldConditions.BETWEEN && (
        <InputNumber
          controls={false}
          style={{
            height: "fit-content",
            width: 50,
          }}
          value={firstNumber}
          onChange={(val) => setFirstNumber(val)}
          placeholder="100"
          variant="borderless"
          className="input-number-center"
        />
      )}

      <>
        <Select
          options={[
            { label: "X <>", value: ENumericFieldConditions.DIFFERENT },
            { label: "< X <", value: ENumericFieldConditions.BETWEEN },
          ]}
          defaultValue={type}
          onChange={(val) => setType(val)}
          suffixIcon={null}
          popupClassName="small-padding-dropdown"
          variant="borderless"
        />

        <InputNumber
          controls={false}
          style={{
            height: "fit-content",
            width: 60,
            textAlign: "center",
          }}
          value={secondNumber}
          onChange={(val) => setSecondNumber(val)}
          placeholder="100"
          variant="borderless"
          className="input-number-center"
        />
      </>
    </Flex>
  );
};
