import { Button, Form, FormInstance } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { EditQuestionForm } from "./EditQuestionForm";
import { TQuestion } from "../../api/templates.types";

interface IEditQuestionsForm {
  listKey: (string | number)[];
  parentName?: (string | number)[];
  form: FormInstance;
  isChildList?: boolean;
  defaultQuestion?: TQuestion[];
}

export const EditQuestionsForm = ({
  listKey,
  parentName = [],
  form,
  isChildList = true,
  defaultQuestion,
}: IEditQuestionsForm) => (
  <Form.List
    name={listKey}
    initialValue={defaultQuestion !== undefined ? undefined : [{ title: "" }]}
  >
    {(fields, { add, remove }) => (
      <>
        {fields.map((field) => (
          <EditQuestionForm
            parentName={[...parentName, ...listKey]}
            name={field.name}
            key={field.key}
            onRemove={remove}
            form={form}
            isChild={isChildList}
            defaultQuestion={defaultQuestion?.[field.name]}
          />
        ))}

        <Button onClick={() => add()} type="dashed">
          <PlusOutlined />
          Add question
        </Button>
      </>
    )}
  </Form.List>
);
