import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig, IWithPagination } from "./index";
import { defaultQS } from "lib";
import { TInspection } from "./inspections.types";

export const readyAnswersApi = createApi({
  ...defaultApiConfig,
  reducerPath: "readyAnswersApi",
  tagTypes: ["ReadyAnswers", "ReadyAnswer"],
  endpoints: (builder) => ({
    getReadyAnswers: builder.query<
      IWithPagination<any>,
      Record<string, any>
    >({
      query: (params) => `template_preset_options?itemsPerPage=10&${defaultQS(params)}`,
      providesTags: ["ReadyAnswers"],
    }),
    getReadyAnswer: builder.query<TInspection, { id: number }>({
      query: ({ id }) => `template_preset_options/${id}`,
      providesTags: ["ReadyAnswer"],
    }),
    createReadyAnswer: builder.mutation({
      query: (body) => ({
        url: "template_preset_options",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ReadyAnswers"],
    }),
    updateReadyAnswer: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `template_preset_options/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ReadyAnswers", "ReadyAnswer"],
    }),
    deleteReadyAnswer: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `template_preset_options/${id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["ReadyAnswers"],
    }),
  }),
  keepUnusedDataFor: 60 * 60 * 5,
});

export const {
  useGetReadyAnswersQuery,
  useGetReadyAnswerQuery,
  useUpdateReadyAnswerMutation,
  useDeleteReadyAnswerMutation,
  useCreateReadyAnswerMutation,
} = readyAnswersApi;
