import { DatePicker, Form, FormInstance, Input, InputNumber } from "antd";
import { TQuestion } from "api/templates.types";
import { QuestionTypes } from "lib";
import { ViewCheckableFieldsForm } from "./ViewCheckableFieldsForm";
import { ViewTemplateQuestions } from "./ViewTemplateQuestions";
import { useShowField } from "hooks";
import { ViewNumberQuestion } from "./ViewNumberQuestion";

interface IViewTemplatesQuestion {
  question: TQuestion;
  questionKey: number;
  form: FormInstance;
  triggerAnswer?: number | number[];
  parent?: TQuestion;
}

export const ViewTemplatesQuestion = ({
  question,
  triggerAnswer,
  parent,
  form,
}: IViewTemplatesQuestion) => {
  const showField = useShowField(question, parent, triggerAnswer);

  return (
    <div key={question.id}>
      {showField && (
        <>
          {generateQuestionOptions(question, form)}

          <Form.Item
            name={[question.id, "question"]}
            initialValue={question.id}
            hidden
            noStyle
          >
            <InputNumber />
          </Form.Item>
        </>
      )}

      {Boolean(question.childQuestions.length) && (
        <ViewTemplateQuestions
          form={form}
          questions={question.childQuestions}
          parent={question}
          key={question.id}
        />
      )}
    </div>
  );
};

const generateQuestionOptions = (question: TQuestion, form: FormInstance) => {
  switch (question.type) {
    case QuestionTypes.TEXT_ANSWER:
      return (
        <Form.Item
          name={[question.id, 'textResponse']}
          key={question.id}
          label={question.text}
          required={question.required}
        >
          <Input.TextArea placeholder="Text answer" />
        </Form.Item>
      );
    case QuestionTypes.NUMBER:
      return <ViewNumberQuestion question={question} form={form} />;
    case QuestionTypes.CHECKBOX:
    case QuestionTypes.RADIOBUTTON:
      return (
        <ViewCheckableFieldsForm
          question={question}
          form={form}
        />
      );
    case QuestionTypes.DATE_TIME:
      return (
        <Form.Item
          name={[question.id, 'textResponse']}
          key={question.id}
          label={question.text}
          required={question.required}
        >
          <DatePicker placeholder="Date/Time" />
        </Form.Item>
      );
  }
};
