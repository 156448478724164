import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./index";

export const authApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl }),
  reducerPath: "authApi",
  endpoints: (builder) => ({
    logIn: builder.mutation({
      query: (body) => ({
        url: "login",
        method: "POST",
        body,
      }),
    }),
    sendRecoverEmail: builder.mutation<{ error: string }, { email?: string }>({
      query: (body) => ({
        url: "reset-password",
        method: "POST",
        body,
      }),
    }),
    updateAdminPassword: builder.mutation({
      query: (body) => ({
        url: "admin/admin/password",
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useLogInMutation,
  useSendRecoverEmailMutation,
  useUpdateAdminPasswordMutation,
} = authApi;
