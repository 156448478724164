import React, { useState, useRef, useEffect } from "react";
import { Button, Flex, Modal } from "antd";
import { base64ToArrayBuffer, CameraMedia } from "../lib";
import {useParams} from "react-router-dom";

interface ICameraCapture {
  onClose: () => void;
  onAddMedia: (media: CameraMedia) => void;
}

const CameraCapture = ({ onClose, onAddMedia }: ICameraCapture) => {
  const params = useParams<{ id: string }>()

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [recording, setRecording] = useState<boolean>(false);
  const chunksRef = useRef<Blob[]>([]);

  useEffect(() => {
    const startCamera = async () => {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          setStream(stream);

          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch((err) => {
          console.error(`An error occurred: ${err}`);
        });
    };

    startCamera();
  }, []);

  const takePhoto = (save?: boolean) => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext("2d");

      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const dataUrl = canvas.toDataURL("image/png");

        if (save)
          onAddMedia({
            base64: dataUrl,
            type: "image/png",
            binary: base64ToArrayBuffer(dataUrl.split(",")[1]),
            name: `camera-photo-${Date.now()}`
          });

        return dataUrl;
      }
    }
  };

  const startRecording = () => {
    const base64CoverImage = takePhoto();

    if (stream) {
      const recorder = new MediaRecorder(stream);

      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      recorder.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: "video/mp4" });

        onAddMedia({
          videoUrl: URL.createObjectURL(blob),
          type: "video/mp4",
          base64: base64CoverImage,
          file: new File([blob], "video-from-camera.mp4", {
            type: "video/mp4",
          }),
          name: `camera-video-${Date.now()}.mp4`
        });

        chunksRef.current = [];
      };

      recorder.start();

      setRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && recording) {
      mediaRecorder.stop();

      setRecording(false);
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      console.log("camera was stopped");

      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }

      setStream(null);
    }
  };

  return (
    <Modal
      open
      cancelButtonProps={{ style: { display: "none" } }}
      okText="Finish"
      onCancel={() => {
        stopCamera();
        onClose();
      }}
      onOk={() => {
        stopCamera();
        onClose();
      }}
      destroyOnClose
    >
      <div>
        <h1>Camera Capture</h1>
        <div>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{ width: "100%", borderRadius: 8 }}
          />
        </div>
        <div>
          <Flex justify="center" gap={16}>
            <Button onClick={() => takePhoto(true)}>Take Photo</Button>

            {recording ? (
              <Button onClick={stopRecording}>Stop Recording</Button>
            ) : (
              <Button onClick={startRecording}>Start Recording</Button>
            )}
          </Flex>
        </div>

        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
    </Modal>
  );
};

export default CameraCapture;
