import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App as AntdApp, ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { store } from "store/store";
import { AppSettingsLayer, PublicLayout } from "components";
import { adminRoutes, publicRoutes } from "lib/routes";
import { NotFoundPage } from "./pages/NotFoundPage";
import { IndexPage } from "./pages/IndexPage";
import { PrivateLayer } from "./components/PrivateLayer";
import { MediaUploaderProvider } from "./contexts";

const App = () => {
  useEffect(() => {
    const requestNotificationsPermissions = () => {
      if ("Notification" in window && Notification.permission !== "granted") {
        Notification.requestPermission().then((permission) => {
          console.log("Notification permission status:", permission);
        });
      }
    };

    requestNotificationsPermissions();
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider theme={{ cssVar: true, hashed: false }}>
          <AntdApp>
            <AppSettingsLayer>
              <MediaUploaderProvider>
                <Routes>
                  <Route key="public" Component={PublicLayout}>
                    {publicRoutes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                      />
                    ))}
                  </Route>

                  <Route key="main" Component={PrivateLayer}>
                    {adminRoutes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                      />
                    ))}
                  </Route>

                  <Route key="NotFoundPage" path="*" element={<IndexPage />} />
                  <Route
                    key="NotFoundPage"
                    path="*"
                    element={<NotFoundPage />}
                  />
                </Routes>
              </MediaUploaderProvider>
            </AppSettingsLayer>
          </AntdApp>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
