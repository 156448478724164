import { useEffect, useRef, useState } from "react";
import { Form, FormInstance, InputNumber } from "antd";
import { EditQuestionsForm } from "./EditQuestionsForm";
import { TPage as TEditPage } from "lib/types";
import { TPage } from "api/templates.types";

export const EditPagesForm = ({
  form,
  defaultPages,
}: {
  form: FormInstance;
  defaultPages?: TPage[];
  isEditMode?: boolean;
}) => {
  const newTabIndex = useRef(0);

  const [pages, setPages] = useState<TEditPage[]>([
    { title: "", key: 0, closable: false },
  ]);

  useEffect(() => {
    if (defaultPages) {
      setPages(
        defaultPages.map((i, key) => ({ title: i.title, key, closable: false }))
      );

      newTabIndex.current = defaultPages.length - 1;
    }
  }, [defaultPages]);

  return (
    <div>
      <Form.Item name={["pages", pages[0].key, "title"]} hidden noStyle />

      <Form.Item name={["pages", pages[0].key, "id"]} hidden noStyle>
        <InputNumber />
      </Form.Item>

      <Form.Item
        name={["pages", pages[0].key, "orderPage"]}
        initialValue={defaultPages?.[0].orderPage !== undefined ? undefined : 0}
        hidden
        noStyle
      >
        <InputNumber />
      </Form.Item>

      <EditQuestionsForm
        listKey={["pages", pages[0].key, "questions"]}
        defaultQuestion={defaultPages?.[0].questions}
        form={form}
        isChildList={false}
      />
    </div>
  );
};
