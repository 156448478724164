import { useEffect } from "react";
import { Card, Form, FormInstance, InputNumber } from "antd";
import { TInspectionQuestion } from "api/templates.types";
import { QuestionTypes } from "lib";

import { InspectionQuestions } from "./InspectionQuestions";
import { useShowField } from "hooks";
import {
  AdditionalQuestionSection,
  InspectionCheckableOptions,
  InspectionDatePicker,
  InspectionInstructionQuestion,
  InspectionNumberQuestion,
  InspectionTextQuestion,
} from "components";

interface IViewTemplatesQuestion {
  question: TInspectionQuestion;
  questionKey: number;
  form: FormInstance;
  triggerAnswer?: number | number[];
  parent?: TInspectionQuestion;
}

export const InspectionQuestion = ({
  question,
  questionKey,
  triggerAnswer,
  parent,
  form,
}: IViewTemplatesQuestion) => {
  const showField = useShowField(question, parent, triggerAnswer);

  useEffect(() => {
    if (!showField) {
      form.setFieldValue(["inspectionAnswers", question.id], undefined);
    }
  }, [showField, form, question]);

  return (
    <div key={question.id}>
      {showField && (
        <Card style={{ marginBottom: 16 }}>
          {generateInspectionQuestionOptions(question, form)}

          <Form.Item
            name={[question.id, "question"]}
            initialValue={question.id}
            hidden
            noStyle
          >
            <InputNumber />
          </Form.Item>

          {question.type !== QuestionTypes.INSTRUCTION_TYPE && (
            <Form.Item name={[question.id, "mediaFiles"]}>
              <AdditionalQuestionSection
                notes={{ name: [question.id, "notes"] }}
              />
            </Form.Item>
          )}
        </Card>
      )}

      {Boolean(question.childInspectionQuestions.length) && (
        <InspectionQuestions
          form={form}
          questions={question.childInspectionQuestions}
          parent={question}
          parentKey={questionKey}
          key={question.id}
        />
      )}
    </div>
  );
};

export const generateInspectionQuestionOptions = (
  question: TInspectionQuestion,
  form: FormInstance
) => {
  switch (question.type) {
    case QuestionTypes.TEXT_ANSWER:
      return <InspectionTextQuestion question={question} />;
    case QuestionTypes.NUMBER:
      return <InspectionNumberQuestion question={question} form={form} />;
    case QuestionTypes.CHECKBOX:
    case QuestionTypes.RADIOBUTTON:
      return <InspectionCheckableOptions question={question} form={form} />;
    case QuestionTypes.DATE_TIME:
      return <InspectionDatePicker question={question} />;
    case QuestionTypes.INSTRUCTION_TYPE:
      return <InspectionInstructionQuestion question={question} form={form} />;
  }
};
