import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { load, remove } from "react-cookies";
import { RootState } from "store/types";
import { SerializedError } from "@reduxjs/toolkit";

export const baseUrl = `${process.env.REACT_APP_API_URL}/api/`;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).loggedAdmin.auth.token;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  try {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      window.location.replace("/login");
      remove("access-token");
    }

    return result;
  } catch (e) {
    console.log("request 2 error", e);
  }

  return {} as any;
};

export const defaultApiConfig = {
  baseQuery: baseQueryWithReauth,
};

export const handleRTKQueryError = (
  error: FetchBaseQueryError | SerializedError
) => {
  if (
    "data" in error &&
    typeof error.data === "object" &&
    error.data !== null
  ) {
    const serverError = error.data as { message?: string; errors?: string[] };

    return serverError.message || serverError.errors || "Unknown error";
  } else if ("message" in error) {
    return error.message;
  } else {
    return "An unknown error occurred";
  }
};

export const simpleApiRequest = (url: string, parsedParams: string) =>
  fetch(`${baseUrl}${url}?${parsedParams}`, {
    headers: { Authorization: `Bearer ${load("access-token")}` },
  });

export const simplePOSTRequest = (url: string, body: Record<string, any>) =>
  fetch(`${baseUrl}${url}`, {
    method: "POST",
    headers: { Authorization: `Bearer ${load("access-token")}` },
    body: JSON.stringify(body)
  });
