import { useMemo } from "react";
import { Form, FormInstance } from "antd";
import { ENumericFieldConditions } from "lib";
import { TInspectionQuestion, TQuestion } from "../api/templates.types";

export const useQuestionFlag = (
  question: TInspectionQuestion | TQuestion,
  form: FormInstance
) => {
  const questionValue = Form.useWatch(question.id, form);

  return useMemo(() => {
    if (questionValue && question.flaggedQuestionCondition) {
      const storedNumericProps = JSON.parse(question.flaggedQuestionCondition);

      switch (storedNumericProps.type) {
        case ENumericFieldConditions.DIFFERENT:
          return storedNumericProps.secondNumber !== questionValue;
        case ENumericFieldConditions.BETWEEN:
          return (
            storedNumericProps.firstNumber < questionValue &&
            questionValue < storedNumericProps.secondNumber
          );
      }
    }

    return false;
  }, [question.flaggedQuestionCondition, questionValue]);
};
