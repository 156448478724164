import { PropsWithChildren, useEffect } from "react";
import { App } from "antd";
import { setOnLine, useAppDispatch } from "store";

export const AppSettingsLayer = ({ children }: PropsWithChildren) => {
  const { notification } = App.useApp();

  const dispatch = useAppDispatch();

  const onOnlineChange = () => {
    dispatch(setOnLine(true));
    notification.success({ message: "You're online" });
  };

  const onOfflineChange = () => {
    dispatch(setOnLine(false));
    notification.error({ message: "You're offline" });
  };

  useEffect(() => {
    window.addEventListener("online", onOnlineChange);

    window.addEventListener("offline", onOfflineChange);
  }, []);

  return <>{children}</>;
};
