import { useMemo, useState } from "react";
import { Button, Flex, Input, Layout, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import {
  TProduct,
  useDeleteReadyAnswerMutation,
  useGetReadyAnswersQuery,
} from "api";
import { ISort, useSorter } from "hooks";
import { EditReadyAnswersForm } from "../components/readyAnswers/EditReadyAnswersForm";

export const ReadyAnswersPage = () => {
  const [modal, contextHolder] = Modal.useModal();

  const [showUserForm, setShowUserForm] = useState(false);
  const [editProduct, setEditProduct] = useState<TProduct>();
  const [sort, setSort] = useState<ISort>({
    sortKey: "desc",
    fieldKey: "createdAt",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState<string>();

  const { returnSorterProps } = useSorter(sort, setSort);

  const {
    data: usersData,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useGetReadyAnswersQuery({
    page,
    itemsPerPage: pageSize,
    search,
    [`order[${sort.fieldKey}]`]: sort.sortKey,
  });

  const [deleteMutation] = useDeleteReadyAnswerMutation();

  const usersColumns = useMemo<ColumnsType<TProduct>>(
    () => [
      {
        dataIndex: "name",
        ...returnSorterProps("name", "Name"),
      },
      {
        dataIndex: "options",
        ...returnSorterProps("options", "Options"),
        render: (options) => (
          <Flex gap={4}>
            {options?.map(
              (option: any) =>
                option?.text && (
                  <div
                    style={{
                      backgroundColor: option?.color || "#bfbfbf",
                      padding: "2px 6px",
                      borderRadius: 16,
                    }}
                  >
                    {option?.text}
                  </div>
                )
            )}
          </Flex>
        ),
      },
      {
        title: "Actions",
        align: "right",
        dataIndex: "id",
        width: 125,
        render: (id, product) => (
          <Space size={24}>
            <FormOutlined
              className="with-action"
              onClick={() => {
                setShowUserForm(true);
                setEditProduct(product);
              }}
            />

            <DeleteOutlined
              style={{ color: "var(--ant-red-4)" }}
              onClick={() =>
                modal.confirm({
                  content: "Are you sure about deleting this product?",
                  onOk: () => deleteMutation({ id }),
                  okButtonProps: { danger: true },
                })
              }
            />
          </Space>
        ),
      },
    ],
    [modal, returnSorterProps]
  );

  return (
    <>
      {contextHolder}

      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search
            placeholder="Search"
            style={{ width: 200 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            allowClear
          />
        </Space>

        <Button type="primary" onClick={() => setShowUserForm(true)}>
          Add ready answer
        </Button>
      </Flex>

      <Layout style={{ backgroundColor: "white" }}>
        <Table
          columns={usersColumns}
          loading={isUsersLoading || isUsersFetching}
          dataSource={usersData?.data}
          rowKey="id"
          scroll={{ x: 800 }}
          pagination={{
            total: usersData?.pagination.totalItems,
            current: usersData?.pagination.current,
            pageSize: pageSize,
            onChange: (nextPage, pageSize) => {
              if (nextPage) setPage(nextPage);
              if (pageSize) setPageSize(pageSize);
            },
            showSizeChanger: true,
          }}
        />
      </Layout>

      {showUserForm && (
        <EditReadyAnswersForm
          product={editProduct}
          onClose={() => {
            setShowUserForm(false);
            setEditProduct(undefined);
          }}
        />
      )}
    </>
  );
};
