import { EditTemplateForm } from "components";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { useGetTemplateQuery } from "api";

export const EditTemplatePage = () => {
  const params = useParams();

  const { data, isLoading } = useGetTemplateQuery({ id: Number(params.id) });

  return (
    <Spin spinning={isLoading} style={{ minHeight: 200 }}>
      <EditTemplateForm data={data} isEditMode />
    </Spin>
  );
};
