export const locales = {
  TEXT_ANSWER: "Text",
  NUMBER: "Number",
  CHECKBOX: "Checkboxes",
  RADIOBUTTON: "Radio buttons",
  DATE_TIME: "Date/time",
  INSTRUCTION_TYPE: "Instruction",
  ROLE_INDOOR_ADMIN: "Admin",
  ROLE_INDOOR_MANAGER: "Manager",
  ROLE_INDOOR_AGENT: "Inspector",
  ROLE_INDOOR_CLIENT: "Client",
};
