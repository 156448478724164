import { useEffect, useState } from "react";
import { Flex, Form, FormInstance } from "antd";
import { ViewCheckableComponent } from "./ViewCheckableComponent";
import { TQuestion } from "api/templates.types";
import { CheckableButtonWrapper } from "../CheckableButtonWrapper";
import { displayQuestionLabel } from "../inspections";

interface IViewCheckableFieldsForm {
  question: TQuestion;
  form: FormInstance;
}

export const ViewCheckableFieldsForm = ({
  question,
  form,
}: IViewCheckableFieldsForm) => {
  const [showFlag, setFlag] = useState<boolean>(false);

  const selectedOptionsOrders = Form.useWatch(
    ["labResultsAnswers", question.id, "selectedOrders"],
    form
  );

  const getFlaggedCheckedItems = () => {
    const flaggedCheckedItems = selectedOptionsOrders.some((order: number) =>
      question.questionOptions.find(
        (option) => option.orderOption === order && option.flag
      )
    );

    setFlag(flaggedCheckedItems);

    return flaggedCheckedItems;
  };

  useEffect(() => {
    if (selectedOptionsOrders) {
      form.setFields([
        {
          name: ["labResultsAnswers", question.id, "questionOptions"],
          value: selectedOptionsOrders
            .map(
              (order: number) =>
                question.questionOptions.find(
                  (option) => option.orderOption === order
                )?.id
            )
            .filter((i: number) => i),
        },
        {
          name: ["labResultsAnswers", question.id, "flagged"],
          value: getFlaggedCheckedItems(),
        },
      ]);
    }
  }, [selectedOptionsOrders, getFlaggedCheckedItems]);

  return (
    <>
      <Form.Item
        name={[question.id, "selectedOrders"]}
        key={question.id}
        label={displayQuestionLabel(question.text, showFlag)}
        required={question.required}
        rules={[
          { required: question.required, message: "This field is required" },
        ]}
        className="input-label-without-overflow"
      >
        {/*todo replace orderOption value with the entire order object*/}
        <CheckableButtonWrapper>
          <Flex
            style={{ width: "100%", justifyContent: "space-between", gap: 8 }}
            wrap
          >
            {question.questionOptions.map((field) => (
              <ViewCheckableComponent
                field={field}
                type={question.type}
                key={field.id}
              />
            ))}
          </Flex>
        </CheckableButtonWrapper>
      </Form.Item>
    </>
  );
};
