import {Form, Input, Typography} from "antd";
import { TInspectionQuestion } from "api/templates.types";

interface IInspectionTextQuestion {
  question: TInspectionQuestion;
}

export const InspectionTextQuestion = ({
  question,
}: IInspectionTextQuestion) => {
  return (
    <Form.Item
      name={[question.id, "textResponse"]}
      key={question.id}
      label={<Typography.Text strong>{question.text}</Typography.Text>}
      required={question.required}
      rules={[
        { required: question.required, message: "This field is required" },
      ]}
      initialValue={question.inspectionQuestionAnswers[0]?.textResponse}
    >
      <Input.TextArea placeholder="Text answer" />
    </Form.Item>
  );
};
