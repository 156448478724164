import { ColorPicker, Flex, Form, FormInstance, Input, Space } from "antd";
import { selectablePresets } from "lib";

interface IEditCheckboxComponent {
  option: { name: number; key: number };
  onAdd: () => void;
  onRemove: (name: number) => void;
  isLastField: boolean;

  form: FormInstance;
  isEditMode?: boolean;
}

export const EditReadyOption = ({
  option,
  onAdd,
  isLastField,

  form,
}: IEditCheckboxComponent) => {
  const colorValue = Form.useWatch(["options", option.key, "color"], form);

  return (
    <Space
      id={option.name.toString()}
      style={{
        width: "25%",
        minWidth: "220px",
        borderRadius: 4,
      }}
      key={option.name}
    >
      <Form.Item name="color" noStyle>
        <ColorPicker
          trigger={"click"}
          presets={selectablePresets}
          onChange={(_, hex) =>
            form.setFieldValue(["options", option.key, "color"], hex)
          }
          panelRender={(panel, extra) => <extra.components.Presets />}
          destroyTooltipOnHide
        >
          <div
            style={{
              width: 16,
              height: 16,
              backgroundColor: colorValue || "#bfbfbf",
              borderRadius: 4,
              cursor: "pointer",
            }}
          />
        </ColorPicker>
      </Form.Item>

      <Flex align="center">
        <Form.Item name={[option.name, "text"]} noStyle>
          <Input
            variant="borderless"
            placeholder="Label"
            onClick={isLastField ? () => onAdd() : undefined}
            style={{ width: "100%", minWidth: "200px", paddingLeft: 0 }}
            autoComplete="off"
            onDrop={(e) => {
              e.preventDefault();
            }}
          />
        </Form.Item>
      </Flex>
    </Space>
  );
};
