import {Badge, Form, FormInstance, InputNumber, Typography} from "antd";
import {useQuestionFlag} from "hooks";
import {FlagOutlined} from "@ant-design/icons";
import {TQuestion} from "../../api/templates.types";

interface IViewNumberQuestion {
  question: TQuestion,
  form: FormInstance;
}

export const ViewNumberQuestion = ({ question, form }: IViewNumberQuestion) => {
  const showFlag = useQuestionFlag(question, form);

  return (
    <Form.Item
      name={[question.id, 'textResponse']}
      key={question.id}
      label={displayQuestionLabel(question.text, showFlag)}
      required={question.required}
      className="input-label-without-overflow"
    >
      <InputNumber placeholder="Number answer" style={{ width: 150 }} />
    </Form.Item>
  );
}

const displayQuestionLabel = (text: string, showFlag: boolean) => {
  const label = <Typography.Text strong>{text}</Typography.Text>;

  if (showFlag) {
    return (
      <>
        <Badge
          count={<FlagOutlined style={{ color: "red" }} />}
          offset={[-10, 0]}
        >
          {" "}
        </Badge>

        {label}
      </>
    );
  }

  return label;
};