export * from "./template";
export * from "./inspections";
export * from "./samples";

export enum EUserRoles {
  ROLE_INDOOR_ADMIN = "ROLE_INDOOR_ADMIN",
  ROLE_INDOOR_MANAGER = "ROLE_INDOOR_MANAGER",
  ROLE_INDOOR_AGENT = "ROLE_INDOOR_AGENT",
  ROLE_INDOOR_CLIENT = "ROLE_INDOOR_CLIENT",
}
