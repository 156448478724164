import { useEffect } from "react";
import {
  App,
  Button,
  ConfigProvider,
  Flex,
  Form,
  Input,
  InputNumber,
} from "antd";
import { useNavigate } from "react-router-dom";
import { EditPagesForm } from "./EditPagesForm";
import {
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
} from "api/templates";
import { getRouteByName } from "lib";
import isNumeric from "antd/es/_util/isNumeric";
import { TTemplate } from "api/templates.types";

export const EditTemplateForm = ({
  data,
  isEditMode,
}: {
  data?: TTemplate;
  isEditMode?: boolean;
}) => {
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [
    createTemplateMutation,
    {
      isLoading: isCreateTemplateLoading,
      isSuccess: isCreateTemplateSuccess,
      error: createTemplateError,
    },
  ] = useCreateTemplateMutation();

  const [
    editTemplateMutation,
    {
      isLoading: isEditTemplateLoading,
      isSuccess: isEditTemplateSuccess,
      error: editTemplateError,
    },
  ] = useUpdateTemplateMutation();

  useEffect(() => {
    if (isCreateTemplateSuccess || isEditTemplateSuccess) {
      notification.success({ message: "Template was saved" });
      navigate(getRouteByName("TemplatesPage"));
    }
  }, [isCreateTemplateSuccess, isEditTemplateSuccess, navigate, notification]);

  useEffect(() => {
    const error = createTemplateError || editTemplateError;

    if (error) {
      notification.error({
        message: "We can't create this template right now",
      });

      form.setFields(
        (error as any).data.errors.map((i: any) => ({
          name: i.source.pointer
            .split("/")[2]
            .split(/[\[\]\.]+/)
            .filter(Boolean)
            .map((i: any) => (isNumeric(i) ? Number(i) : i)),
          errors: [i.detail],
        }))
      );
    }
  }, [createTemplateError, editTemplateError, form, notification]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (form.isFieldsTouched()) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [form]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: { headerBg: "var(--ant-blue-1)" },
        },
      }}
    >
      {((isEditMode && data) || !isEditMode) && (
        <Form
          form={form}
          onFinish={() => {
            const formValuesWithHidden = form.getFieldsValue(true);

            const submitRequest = data?.id
              ? editTemplateMutation
              : createTemplateMutation;

            submitRequest({
              ...formValuesWithHidden,
              pages: { ...data?.pages, ...formValuesWithHidden.pages },
            });
          }}
          initialValues={data}
        >
          <Form.Item name="id" hidden>
            <InputNumber />
          </Form.Item>

          <Form.Item name="name" style={{ marginBottom: 0 }}>
            <Input
              variant="borderless"
              placeholder="Template name"
              style={{ fontSize: 40 }}
            />
          </Form.Item>

          <EditPagesForm
            form={form}
            defaultPages={data?.pages}
            isEditMode={isEditMode}
          />

          <Flex justify="flex-end">
            <Button
              type="primary"
              htmlType="submit"
              loading={isCreateTemplateLoading || isEditTemplateLoading}
            >
              Save template
            </Button>
          </Flex>
        </Form>
      )}
    </ConfigProvider>
  );
};
