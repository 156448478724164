import { useState } from "react";
import { Select, SelectProps, Spin } from "antd";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import { IWithPagination } from "../api";

interface SmartSelectInterface extends SelectProps {
  query: UseQuery<
    QueryDefinition<any, any, string, IWithPagination<any>, string>
  >;
  mode?: "multiple" | "tags";
  labelKey?: string;
}

export const SmartSelectPagination = ({
  query,
  mode,
  labelKey = "label",
  ...props
}: SmartSelectInterface) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();

  const { data, isLoading, isFetching } = query({ page, search });

  return (
    <Select
      loading={isLoading || isFetching}
      mode={mode}
      className="capitalize"
      dropdownRender={(menu) => (
        <>
          {menu}

          <Spin
            spinning={isFetching}
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%)",
              bottom: ".5rem",
            }}
          />
        </>
      )}
      onPopupScroll={(e) => {
        const { target }: { target: any } = e;

        if (
          target.scrollTop + target.offsetHeight === target.scrollHeight &&
          !isFetching &&
          (data?.pagination.current || 1) < (data?.pagination.last || 1)
        ) {
          setPage((prevState) => ++prevState);
        }
      }}
      onSearch={(val) => {
        setSearch(val);
        setPage(1);
      }}
      showSearch
      optionFilterProp="children"
      {...props}
    >
      {data?.data?.map((i) => (
        <Select.Option value={i.value} title={i[labelKey]} key={i.value}>
          {i[labelKey]}
        </Select.Option>
      ))}
    </Select>
  );
};
