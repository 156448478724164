import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { AttachedFile } from "../lib";
import { useAppSelector } from "../store";
import {
  deleteFileFromIndexedDB,
  getFilesFromIndexedDB,
  saveFileToIndexedDB,
} from "../lib/indexedDb";
import { simplePOSTRequest } from "../api";

interface IMediaUploaderContext {
  filesToUpload: AttachedFile[];
  setFilesToUpload?: Dispatch<SetStateAction<AttachedFile[]>>;
}

interface IMediaUploaderProvider {
  filesToUpload?: AttachedFile[];
  setFilesToUpload?: Dispatch<SetStateAction<AttachedFile[]>>;
}

export const MediaUploaderContext = createContext<IMediaUploaderContext>({
  filesToUpload: [],
  setFilesToUpload: () => null,
});

export const MediaUploaderProvider = ({
  children,
}: PropsWithChildren<IMediaUploaderProvider>) => {
  const [filesToUpload, setFilesToUpload] = useState<AttachedFile[]>([]);

  const { onLine } = useAppSelector((state) => state.appSettings);

  useEffect(() => {
    if (filesToUpload.length) {
      filesToUpload.forEach((file) => uploadFile(file));
    }
  }, [filesToUpload]);

  useEffect(() => {
    const uploadPendingFiles = async () => {
      const files = await getFilesFromIndexedDB();

      if (files.length === 0) {
        return;
      }

      for (const storedFile of files) {
        try {
          uploadToS3(storedFile.file).then(() =>
            deleteFileFromIndexedDB(storedFile.id)
          );
        } catch (error) {
          console.error(`Failed to upload ${storedFile.file.name}.`, error);
        }
      }
    };

    if (onLine) {
      uploadPendingFiles();
    }
  }, [onLine]);

  const uploadFile = async (file: AttachedFile) => {
    if (onLine) {
      await uploadToS3(file);

      return;
    }

    await saveFileToIndexedDB(file);
  };

  const uploadToS3 = async (file: AttachedFile) => {
    await simplePOSTRequest("s3/generate-url", {
      filename: file?.name,
      expiration: 60 * 5,
    })
      .then((res) => res.json())
      .then(async (res) => {
        if ("originFileObj" in file) {
          return await fetch(res.url, {
            method: "PUT",
            headers: {
              "Content-Type": file.type || "",
            },
            body: file.originFileObj,
          });
        }

        if ("binary" in file) {
          return await fetch(res.url, {
            method: "PUT",
            headers: {
              "Content-Type": file.type || "",
            },
            body: file.binary,
          });
        }

        if ("file" in file) {
          return await fetch(res.url, {
            method: "PUT",
            headers: {
              "Content-Type": file.type || "",
            },
            body: file.file,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <MediaUploaderContext.Provider value={{ filesToUpload, setFilesToUpload }}>
      {children}
    </MediaUploaderContext.Provider>
  );
};
