import { App, Form, Input, Modal, Select, Switch } from "antd";
import { IUser, useCreateUserMutation, useUpdateUserMutation } from "api";
import { useEffect, useMemo } from "react";
import { PhoneInput } from "../PhoneInput";
import { EUserRoles, locales } from "lib";

interface IEditUserForm {
  user?: IUser;
  onClose: () => void;
  onCreateUserSuccess?: (user: IUser) => void;
  isCreateClientMode?: boolean;
}

export const EditUserForm = ({
  user,
  onClose,
  onCreateUserSuccess,
  isCreateClientMode,
}: IEditUserForm) => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const [
    createUserMutation,
    {
      data: createUserData,
      error: createUserError,
      isLoading: isCreateUserLoading,
    },
  ] = useCreateUserMutation();

  const [
    updateUserMutation,
    {
      isSuccess: isUpdateUserSuccess,
      error: updateUserError,
      isLoading: isUpdateUserLoading,
    },
  ] = useUpdateUserMutation();

  useEffect(() => {
    if (user) form.setFieldsValue(user);
  }, [user]);

  useEffect(() => {
    if (createUserData || isUpdateUserSuccess) {
      notification.success({ message: "Changes was saved" });

      onCreateUserSuccess && onCreateUserSuccess(createUserData);

      onClose();
    }
  }, [createUserData, isUpdateUserSuccess]);

  useEffect(() => {
    if (createUserError || updateUserError) {
      notification.error({ message: "Changes can not be saved" });
    }
  }, [createUserError, updateUserError]);

  const isEditMode = useMemo(() => Boolean(user), []);
  const modalText = useMemo(
    () => (isEditMode ? "Edit user" : "Create user"),
    [isEditMode]
  );

  const onFinish = () => {
    const formValues = form.getFieldsValue();
    const mutation = isEditMode ? updateUserMutation : createUserMutation;

    form
      .validateFields()
      .then(() =>
        mutation({
          ...formValues,
          id: user?.id,
          roles:
            typeof formValues.roles === "string"
              ? [formValues.roles]
              : formValues.roles,
        })
      )
      .catch(() => null);
  };

  const selectedRole = Form.useWatch(["roles"], form);

  return (
    <Modal
      title={modalText}
      okText={modalText}
      okButtonProps={{
        onClick: onFinish,
        loading: isCreateUserLoading || isUpdateUserLoading,
      }}
      onCancel={onClose}
      styles={{ body: { margin: "2rem 0" } }}
      open
    >
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
        <Form.Item
          name="firstName"
          label="First name"
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last name"
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <PhoneInput />
        </Form.Item>

        <Form.Item
          name="roles"
          label="Role"
          initialValue={
            isCreateClientMode
              ? EUserRoles.ROLE_INDOOR_CLIENT
              : EUserRoles.ROLE_INDOOR_AGENT
          }
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <Select
            options={Object.keys(EUserRoles).map((i) => ({
              label: locales[EUserRoles[i as keyof typeof EUserRoles]],
              value: i,
            }))}
            disabled={isCreateClientMode}
          />
        </Form.Item>

        {selectedRole === EUserRoles.ROLE_INDOOR_CLIENT && (
          <>
            <Form.Item name="companyName" label="Company name">
              <Input placeholder="Company name" />
            </Form.Item>

            <Form.Item name="ccEmailList" label="CC">
              <Select
                mode="tags"
                showSearch={false}
                dropdownStyle={{ display: "none" }}
                placeholder="Press enter after each email"
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="isActive"
          label="Is active"
          rules={[{ required: true, message: "This field can not be empty" }]}
          initialValue={true}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
