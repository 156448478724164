import {
  App,
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Upload,
} from "antd";
import {
  simplePOSTRequest,
  TProduct,
  useCreateProductMutation,
  useUpdateProductMutation,
} from "api";
import { useEffect, useMemo } from "react";
import { PhoneInput } from "../PhoneInput";
import { EUserRoles, locales, normFile } from "lib";
import { PlusOutlined } from "@ant-design/icons";

interface IEditUserForm {
  product?: TProduct;
  onClose: () => void;
}

export const EditProductsForm = ({ product, onClose }: IEditUserForm) => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  const [
    createUserMutation,
    {
      isSuccess: isCreateUserSuccess,
      error: createUserError,
      isLoading: isCreateUserLoading,
    },
  ] = useCreateProductMutation();

  const [
    updateUserMutation,
    {
      isSuccess: isUpdateUserSuccess,
      error: updateUserError,
      isLoading: isUpdateUserLoading,
    },
  ] = useUpdateProductMutation();

  useEffect(() => {
    if (product)
      form.setFieldsValue({
        ...product,
        image: [{ url: product.image }],
      });
  }, [product]);

  useEffect(() => {
    if (isCreateUserSuccess || isUpdateUserSuccess) {
      notification.success({ message: "Changes was saved" });
      onClose();
    }
  }, [isCreateUserSuccess, isUpdateUserSuccess]);

  useEffect(() => {
    if (createUserError || updateUserError) {
      notification.error({ message: "Changes can not be saved" });
    }
  }, [createUserError, updateUserError]);

  const isEditMode = useMemo(() => Boolean(product), []);
  const modalText = useMemo(
    () => (isEditMode ? "Edit product" : "Create product"),
    [isEditMode]
  );

  const onFinish = () => {
    const formValues = form.getFieldsValue();
    const mutation = isEditMode ? updateUserMutation : createUserMutation;

    form
      .validateFields()
      .then(() => {
        console.log(formValues, "here");

        mutation({
          ...formValues,
          id: product?.id,
          image: formValues.image?.[0]?.url
            ? formValues.image[0].url
            : `${process.env.REACT_APP_S3_URL}/${formValues?.image[0].name}`,
        });
      })
      .catch((e) => console.log(e, "e"));
  };

  return (
    <Modal
      title={modalText}
      okText={modalText}
      okButtonProps={{
        onClick: onFinish,
        loading: isCreateUserLoading || isUpdateUserLoading,
      }}
      onCancel={onClose}
      styles={{ body: { margin: "2rem 0" } }}
      open
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="link"
          label="Link"
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "This field can not be empty" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="image"
          label="Image"
          rules={[{ required: true, message: "This field can not be empty" }]}
          getValueFromEvent={normFile}
          valuePropName="fileList"
        >
          <Upload
            accept=".jpg,.png"
            multiple={false}
            listType="picture-card"
            customRequest={async ({ file, onProgress, onSuccess, onError }) => {
              await simplePOSTRequest("s3/generate-url", {
                filename: `lab/${
                  typeof file === "object" && "name" in file ? file.name : ""
                }`,
                expiration: 60 * 5,
              })
                .then((res) => res.json())
                .then((res) => {
                  const xhr = new XMLHttpRequest();

                  xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                      const percent = Math.round(
                        (event.loaded / event.total) * 100
                      );
                      onProgress && onProgress({ percent });
                    }
                  };

                  xhr.onload = () => {
                    if (xhr.status === 200) {
                      onSuccess && onSuccess(xhr.responseText);
                    } else {
                      onError && onError(new Error("Upload failed."));
                    }
                  };

                  xhr.onerror = () => {
                    onError && onError(new Error("Upload error."));
                  };

                  xhr.open("PUT", res.url);

                  xhr.send(file);
                });
            }}
          >
            {product?.image ? null : (
              <Flex gap={2}>
                <PlusOutlined />
                Upload
              </Flex>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
