import { useEffect, useState } from "react";
import {
  App,
  Button,
  Flex,
  Form,
  Input,
  Layout,
  Select,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { SetPasswordModal, PhoneInput } from "components";
import { useAppSelector } from "store";
import { useQueryParams } from "hooks";
import { EUserRoles, getRouteByName, locales } from "lib";
import { useUpdateMyProfileMutation } from "api";

export const MyProfilePage = () => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const queryParams = useQueryParams();

  const [showResetPassModal, setShowResetPassModal] = useState<boolean>(
    Boolean(queryParams.showReset)
  );

  const { data } = useAppSelector((state) => state.loggedAdmin);

  const [updateProfileMutation, { isLoading, isSuccess, error }] =
    useUpdateMyProfileMutation();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: "Profile data was successfully saved" });
      navigate(getRouteByName("InspectionsPage"));
    }
  }, [isSuccess, notification, navigate]);

  useEffect(() => {
    if (error) {
      notification.success({
        message: "Profile data cloud not be saved",
      });
    }
  }, [error, notification]);

  return (
    <>
      <Layout.Content>
        <Flex justify="space-between">
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            My profile
          </Typography.Title>
          <Button onClick={() => setShowResetPassModal(true)}>
            Reset password
          </Button>
        </Flex>

        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 6 }}
          onFinish={(values) => updateProfileMutation(values)}
        >
          <Form.Item
            name="firstName"
            label="First name"
            rules={[{ required: true, message: "This field can not be empty" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last name"
            rules={[{ required: true, message: "This field can not be empty" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "This field can not be empty" }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone"
            rules={[{ required: true, message: "This field can not be empty" }]}
          >
            <PhoneInput />
          </Form.Item>

          <Form.Item
            name="roles"
            label="Role"
            initialValue={[EUserRoles.ROLE_INDOOR_AGENT]}
            rules={[{ required: true, message: "This field can not be empty" }]}
          >
            <Select
              mode="multiple"
              options={Object.keys(EUserRoles).map((i) => ({
                label: locales[EUserRoles[i as keyof typeof EUserRoles]],
                value: i,
              }))}
              disabled
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>

      {showResetPassModal && (
        <SetPasswordModal onCancel={() => setShowResetPassModal(false)} />
      )}
    </>
  );
};
