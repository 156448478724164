import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig, IWithPagination } from "./index";
import { defaultQS } from "lib";
import { TSample } from "./sample.types";

export const sampleTypesApi = createApi({
  ...defaultApiConfig,
  reducerPath: "sampleTypesApi",
  tagTypes: ["SampleTypes", "SampleType"],
  endpoints: (builder) => ({
    getSampleTypes: builder.query<
      IWithPagination<TSample>,
      Record<string, any>
    >({
      query: (params) => `sample_types?${defaultQS(params)}`,
      providesTags: ["SampleTypes"],
    }),
    getSampleType: builder.query<TSample, { id: number }>({
      query: ({ id }) => `sample_types/${id}`,
      providesTags: ["SampleType"],
    }),
    createSampleType: builder.mutation({
      query: (body) => ({
        url: "sample_types",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SampleTypes"],
    }),
    updateSampleType: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `sample_types/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["SampleTypes", "SampleType"],
    }),
    deleteSampleType: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `sample_types/${id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["SampleTypes"],
    }),
  }),
  keepUnusedDataFor: 60 * 60 * 5,
});

export const {
  useGetSampleTypesQuery,
  useGetSampleTypeQuery,
  useCreateSampleTypeMutation,
  useUpdateSampleTypeMutation,
  useDeleteSampleTypeMutation,
} = sampleTypesApi;
