import { TPage } from "api/templates.types";
import { Form, FormInstance } from "antd";
import { ViewTemplateQuestions } from "./ViewTemplateQuestions";

export const ViewTemplatePage = ({
  page,
  form,
}: {
  page: TPage;
  form: FormInstance;
}) => {
  return (
    <Form.List name="labResultsAnswers">
      {() => (
        <ViewTemplateQuestions
          form={form}
          questions={page.questions}
          key={page.id}
        />
      )}
    </Form.List>
  );
};
