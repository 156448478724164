import { Form, FormInstance } from "antd";
import { TInspectionQuestion } from "api/templates.types";
import { InspectionQuestion } from "./InspectionQuestion";

export const InspectionQuestions = ({
  form,
  questions,
  parent,
}: {
  form: FormInstance;
  questions: TInspectionQuestion[];
  parentKey?: number;
  parent?: TInspectionQuestion;
}) => {
  const triggerAnswer = Form.useWatch(
    ["inspectionAnswers", parent?.id, "selectedOrders"],
    form
  );

  return (
    <>
      {questions?.map((question, key) => (
        <InspectionQuestion
          question={question}
          questionKey={key}
          triggerAnswer={triggerAnswer}
          parent={parent}
          form={form}
          key={key}
        />
      ))}
    </>
  );
};
