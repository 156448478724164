import { createContext, PropsWithChildren } from "react";

interface ICheckableButtonsContext {
  selected: any[];
  onChange?: (value: number, type: "radio" | "checkbox") => void;
}

interface ICheckableButtonsProvider {
  selected: any;
  onChange?: (value: number, type: "radio" | "checkbox") => void;
}

export const CheckableButtonsContext = createContext<ICheckableButtonsContext>({
  selected: [],
  onChange: () => null,
});

export const CheckableButtonsProvider = ({
  selected,
  onChange,
  children,
}: PropsWithChildren<ICheckableButtonsProvider>) => {
  return (
    <CheckableButtonsContext.Provider value={{ selected, onChange }}>
      {children}
    </CheckableButtonsContext.Provider>
  );
};
