import React, { PropsWithChildren, useEffect, useMemo } from "react";
import {
  Layout,
  theme,
  Menu,
  Button,
  Avatar,
  Dropdown,
  Flex,
  Space,
  Grid,
  Modal,
} from "antd";
import { remove } from "react-cookies";
import {
  matchRoutes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { EUserRoles, getRouteByName } from "lib";
import { adminRoutes } from "lib/routes";
import {
  changeShowSideBar,
  removeAuthToken,
  setLoggedAdminData,
  useAppDispatch,
  useAppSelector,
} from "store";
import {
  useGetAvailableStatusesQuery,
  useGetMyProfileQuery,
  usersApi,
} from "api";

import { Logo, LogoBig } from "../assets/png";

export const MainLayout = ({ children }: PropsWithChildren) => {
  const { token } = theme.useToken();
  const [modal, contextHolder] = Modal.useModal();

  const location = useLocation();
  const navigate = useNavigate();
  const breakpoints = Grid.useBreakpoint();

  const dispatch = useAppDispatch();
  const { showSideBar, onLine } = useAppSelector((state) => state.appSettings);

  const { data: adminProfileData } = useGetMyProfileQuery();

  useGetAvailableStatusesQuery();

  const matches = matchRoutes(adminRoutes, location.pathname);

  useEffect(() => {
    dispatch(
      setLoggedAdminData({
        data: adminProfileData,
        parsedRoles: {
          [EUserRoles.ROLE_INDOOR_ADMIN]: adminProfileData?.roles.includes(
            EUserRoles.ROLE_INDOOR_ADMIN
          ),
          [EUserRoles.ROLE_INDOOR_CLIENT]: adminProfileData?.roles.includes(
            EUserRoles.ROLE_INDOOR_CLIENT
          ),
          [EUserRoles.ROLE_INDOOR_AGENT]: adminProfileData?.roles.includes(
            EUserRoles.ROLE_INDOOR_AGENT
          ),
          [EUserRoles.ROLE_INDOOR_MANAGER]: adminProfileData?.roles.includes(
            EUserRoles.ROLE_INDOOR_MANAGER
          ),
        },
      })
    );
  }, [adminProfileData, dispatch]);

  useEffect(() => {
    if (adminProfileData?.resetPassword) {
      modal.confirm({
        title: "You are using a one time password, please set a new password",
        okText: "Yes",
        onOk: () =>
          navigate({
            pathname: getRouteByName("MyProfilePage"),
            search: "showReset=true",
          }),
      });
    }
  }, [adminProfileData, modal, navigate]);

  const isClient = useMemo(
    () => adminProfileData?.roles.includes(EUserRoles.ROLE_INDOOR_CLIENT),
    [adminProfileData]
  );

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
      {!isClient && (
        <Layout.Sider
          trigger={null}
          collapsible
          collapsedWidth={breakpoints.xs ? 0 : 80}
          collapsed={showSideBar}
          style={{
            position: "relative",
            backgroundColor: token.colorBgContainer,
            width: 0,
            borderRight: `1px solid ${token.colorBgLayout}`,
          }}
          breakpoint="sm"
        >
          <Flex justify="center" align="center" style={{ margin: "1rem 0" }}>
            <img
              src={showSideBar ? Logo : LogoBig}
              alt="logo"
              style={{ height: 32 }}
            />
          </Flex>

          <Menu
            mode="inline"
            selectedKeys={matches?.map((i) => i.route.category || "")}
            items={adminRoutes
              .filter(
                (i) =>
                  i.label &&
                  adminProfileData?.roles.some((role: any) =>
                    i.roles.includes(role)
                  )
              )
              .map((i) => ({
                label: i.label,
                key: i.category || "",
                icon: i.icon,
                onClick: () => navigate(i.path),
              }))}
            style={{ borderInlineEnd: 0 }}
          />
        </Layout.Sider>
      )}

      <Layout>
        <Layout.Header
          style={{ padding: 0, background: token.colorBgContainer }}
        >
          <Flex justify="space-between" align="center">
            {!isClient && (
              <Button
                type="text"
                icon={
                  showSideBar ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                }
                onClick={() => dispatch(changeShowSideBar())}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                  borderRadius: 0,
                }}
              />
            )}

            <div />

            <Space size="large">
              <Dropdown
                menu={{
                  items: [
                    {
                      label: "My profile",
                      key: "profile",
                      onClick: () => navigate(getRouteByName("MyProfilePage")),
                    },
                    {
                      label: "Log out",
                      key: "logOut",
                      onClick: () => {
                        remove("access-token");

                        dispatch(removeAuthToken(undefined));
                        dispatch(usersApi.util.resetApiState());

                        navigate(getRouteByName("LogInPage"));
                      },
                    },
                  ],
                }}
                trigger={["click"]}
                placement="bottomRight"
                arrow
              >
                <Flex gap={8} align="center">
                  {!isClient && (
                    <Flex align="center" gap={4}>
                      <div style={{ fontSize: 10 }}>
                        {onLine ? "(Online)" : "(Offline)"}
                      </div>
                      <div
                        style={{
                          width: 6,
                          height: 6,
                          borderRadius: 6,
                          backgroundColor: onLine
                            ? token.green5
                            : token.colorTextSecondary,
                        }}
                      />
                    </Flex>
                  )}

                  <span>
                    {adminProfileData?.firstName} {adminProfileData?.lastName}
                  </span>

                  <Avatar
                    size={28}
                    icon={<UserOutlined />}
                    style={{ marginRight: "1rem", cursor: "pointer" }}
                  />
                </Flex>
              </Dropdown>
            </Space>
          </Flex>
        </Layout.Header>
        <Layout.Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "cacl(100vh - 4rem - 8rem)",
            background: token.colorBgContainer,
            borderRadius: token.borderRadiusLG,
          }}
        >
          {children}

          <Outlet />
        </Layout.Content>
      </Layout>

      {contextHolder}
    </Layout>
  );
};
