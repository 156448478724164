import { Card, Flex, Form, InputNumber, Typography } from "antd";
import {
  AdditionalQuestionSection,
  SummaryCheckableComponent,
} from "components";
import { CheckableButtonWrapper } from "../CheckableButtonWrapper";
import { TAnswer } from "api/inspections.types";
import { format, QuestionTypes } from "lib";

interface IViewTemplatesQuestion {
  answer: TAnswer;
}

export const ClientSummaryAnswer = ({ answer }: IViewTemplatesQuestion) => {
  return (
    <div>
      <Card
        style={{ marginBottom: 16 }}
        title={
          <Flex justify="space-between">
            <Typography.Text strong>
              {answer.inspectionQuestion.text}
            </Typography.Text>
          </Flex>
        }
        size="small"
      >
        {generateSummaryQuestion(answer)}

        <Form.Item
          name={[answer.id, "id"]}
          initialValue={answer.id}
          hidden
          noStyle
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name={[answer.id, "mediaFiles"]}
          initialValue={answer.mediaFiles}
        >
          <AdditionalQuestionSection
            notes={{ name: [answer.id, "notes"], initialValue: answer.notes }}
            showActions={false}
          />
        </Form.Item>
      </Card>
    </div>
  );
};

const generateSummaryQuestion = (answer: TAnswer) => {
  switch (answer.inspectionQuestion.type) {
    case QuestionTypes.TEXT_ANSWER:
    case QuestionTypes.NUMBER:
      return (
        <Form.Item required={answer.inspectionQuestion.required}>
          {answer.textResponse || "–"}
        </Form.Item>
      );
    case QuestionTypes.CHECKBOX:
    case QuestionTypes.RADIOBUTTON: {
      const selectedOptionsOrders = answer.questionOptions.map(
        (i) => i.orderOption
      );

      return (
        <Form.Item
          name={[answer.id, "questionOptions"]}
          required={answer.inspectionQuestion.required}
          initialValue={selectedOptionsOrders}
        >
          <CheckableButtonWrapper>
            <Flex style={{ width: "100%", gap: 8 }} wrap>
              {answer.inspectionQuestion.inspectionQuestionOptions
                .filter((option) =>
                  selectedOptionsOrders.includes(option.orderOption)
                )
                .map((field) => (
                  <SummaryCheckableComponent field={field} key={field.id} />
                ))}
            </Flex>
          </CheckableButtonWrapper>
        </Form.Item>
      );
    }
    case QuestionTypes.DATE_TIME:
      return (
        <Form.Item required={answer.inspectionQuestion.required}>
          <>{answer.textResponse ? format(answer.textResponse) : "–"}</>
        </Form.Item>
      );
  }
};
