import { useEffect } from "react";
import {
  App,
  Button,
  Form,
  Input,
  Layout,
  Space,
  theme,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getRouteByName } from "lib";
import { useSendRecoverEmailMutation } from "api";

export const RequestResetPassPage = () => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const [generateCodeMutation, { isLoading, isSuccess, error }] =
    useSendRecoverEmailMutation();

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Your new password was sent to you",
      });
      navigate(getRouteByName("LogInPage"));
    }
  }, [isSuccess, notification, form, navigate]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "We can not reset your pass right now",
      });
    }
  }, [error, notification]);

  return (
    <Layout
      style={{
        padding: "3rem",
        flex: "inherit",
        borderRadius: ".5rem",
        backgroundColor: "white",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Space direction="vertical" style={{ width: "20rem", gap: 0 }}>
          <Typography.Title level={2} style={{ margin: 0 }}>
            Reset password
          </Typography.Title>
          <Typography.Text style={{ color: token.colorPrimary }}>
            Please input your email
          </Typography.Text>

          <Form
            form={form}
            style={{ marginTop: "2rem" }}
            onFinish={(values: Record<string, any>) =>
              generateCodeMutation(values)
            }
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  // eslint-disable-next-line
                  pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Wrong email format",
                },
              ]}
            >
              <Input placeholder="Email" type="email" size="large" />
            </Form.Item>

            <Button
              type="primary"
              style={{ width: "100%" }}
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              Receive email
            </Button>
          </Form>

          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "1rem 0 0",
            }}
          >
            <Link to={getRouteByName("LogInPage")}>
              <Typography.Text style={{ color: token.colorPrimary }}>
                Log in
              </Typography.Text>
            </Link>
          </Space>
        </Space>
      </div>
    </Layout>
  );
};
