import { KeyboardEvent, MouseEvent } from "react";

export enum QuestionTypes {
  TEXT_ANSWER = "TEXT_TYPE",
  NUMBER = "NUMBER_TYPE",
  CHECKBOX = "CHECKBOX_TYPE",
  RADIOBUTTON = "RADIOBUTTON_TYPE",
  DATE_TIME = "DATETIME_TYPE",
  INSTRUCTION_TYPE = "INSTRUCTION_TYPE"
}

export type TargetKey = MouseEvent | KeyboardEvent | string;

export interface IField {
  type?: QuestionTypes;
  text?: string;
  flag?: boolean;
  flagData?: { type: ENumericFieldConditions; values?: number[] };
  color?: string;
  hasResponse?: boolean;
}

export type TPage = {
  title: string;
  key: number;
  closable: boolean;
};

export enum ENumericFieldConditions {
  DIFFERENT = "different",
  BETWEEN = "between",
}
