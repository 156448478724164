import { useContext } from "react";
import { Checkbox, CheckboxProps } from "antd";
import { CheckableButtonsContext } from "contexts";
import { TQuestionOption } from "../api/templates.types";

interface ICheckboxButton extends CheckboxProps {
  field: TQuestionOption;
}

export const CheckboxButton = ({
  field,
  children,
  ...props
}: ICheckboxButton) => {
  const { selected, onChange } = useContext(CheckableButtonsContext);

  const isSelected = selected.includes(field.orderOption);

  return (
    <Checkbox
      {...props}
      checked={isSelected}
      onChange={() => onChange && onChange(field.orderOption, "checkbox")}
      style={{
        border: "1px solid var(--ant-color-border)",
        padding: "4px 8px",
        borderRadius: 4,
        backgroundColor: isSelected ? field.color || "#d9d9d9" : "transparent",
        width: "100%",
      }}
    >
      {children}
    </Checkbox>
  );
};
