import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Image,
  Input,
  theme,
  Timeline,
  Upload,
} from "antd";
import DrawingApp from "./DrawingApp";
import { AttachedFile, CameraMedia, getBase64, ToolTypes } from "lib";
import CameraCapture from "../CameraCapture";
import { getFilePreview } from "./helpers";

import {
  ArrowRightOutlined,
  DeleteOutlined,
  EyeOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
  HighlightOutlined,
  PictureOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

interface IAdditionalQuestionSection {
  notes: { name: (string | number)[]; initialValue?: string };
  showActions?: boolean;
  onChange?: Dispatch<SetStateAction<AttachedFile[]>>;
  value?: { id: number; filename: string; type: string }[];
}

export const AdditionalQuestionSection = ({
  notes,
  showActions = true,
  onChange,
  value,
}: IAdditionalQuestionSection) => {
  const { token } = theme.useToken();

  const [showNote, setShowNote] = useState(Boolean(notes.initialValue));
  const [showCamera, setShowCamera] = useState(false);
  const [fileList, setFileList] = useState<AttachedFile[]>(
    value?.map((i) => ({ ...i, name: i.filename?.split("/")[4] })) || []
  );
  const [activeTool, setActiveTool] = useState<ToolTypes>();
  const [currentVisible, setCurrentVisible] = useState<number>();
  const [textToolValue, setTextToolValue] = useState<string>();

  useEffect(() => {
    onChange && onChange(fileList);
  }, [fileList]);

  const onMediaDelete = (toDeleteKey: number) =>
    setFileList((prevState) =>
      prevState.filter((i, key) => key !== toDeleteKey)
    );

  const items = useMemo(() => {
    const defaultItems = [];

    if (fileList.length) {
      defaultItems.push({
        dot: <PictureOutlined style={{ fontSize: 20 }} />,
        children: (
          <>
            <span style={{ fontWeight: "bold", fontSize: 16 }}>Media</span>

            <Image.PreviewGroup
              preview={{
                imageRender: (originalNode, info) => {
                  if (
                    (info as any).image?.url?.startsWith("blob") ||
                    (info as any).image?.url?.endsWith(".mp4")
                  ) {
                    return (
                      <video
                        src={
                          (info as any).image?.url ||
                          (info as any).image?.filename
                        }
                        controls
                      />
                    );
                  }

                  return (
                    <DrawingApp
                      imageUrl={
                        ((info as any).image as { url: string }).url ||
                        (info as any).image?.filename
                      }
                      activeTool={activeTool}
                      onImageChange={(
                        newBase64: string,
                        binary: ArrayBufferLike
                      ) =>
                        setFileList((prevState) =>
                          prevState.map((i, key) =>
                            key === info.current
                              ? { ...i, base64: newBase64, binary }
                              : i
                          )
                        )
                      }
                      textToolValue={textToolValue}
                    />
                  );
                },
                toolbarRender: (_, info) => {
                  if (
                    !(info.image as any)?.url?.startsWith("blob") &&
                    !(info as any).image?.url?.endsWith(".mp4")
                  ) {
                    return (
                      <Flex
                        style={{
                          borderRadius: "1rem",
                          fontSize: 20,
                          backgroundColor: token.controlItemBgActiveDisabled,
                          padding: "0.2rem 2rem",
                        }}
                        gap={32}
                      >
                        <HighlightOutlined
                          onClick={() => setActiveTool("pen")}
                          style={{
                            color: activeTool === "pen" ? "white" : "",
                          }}
                        />

                        <ArrowRightOutlined
                          onClick={() => setActiveTool("arrow")}
                          style={{
                            color: activeTool === "arrow" ? "white" : "",
                          }}
                        />

                        <Flex gap={8}>
                          <FontSizeOutlined
                            onClick={() => setActiveTool("text")}
                            style={{
                              color: activeTool === "text" ? "white" : "",
                            }}
                          />
                          <Input
                            variant="borderless"
                            size="small"
                            placeholder="Text to insert"
                            width={100}
                            value={textToolValue}
                            onChange={(e) => setTextToolValue(e.target.value)}
                            style={{
                              backgroundColor: token.colorFillTertiary,
                              color: token.colorWhite,
                            }}
                          />
                        </Flex>
                      </Flex>
                    );
                  }
                },
                onChange: (current) => {
                  setCurrentVisible(current);
                },
                onVisibleChange: (value) => {
                  if (!value) setCurrentVisible(undefined);
                },
                current: currentVisible,
                visible: currentVisible !== undefined,
              }}
            >
              <Flex gap={8} style={{ marginTop: 12 }} wrap>
                {fileList.map((file, current) => {
                  if (file.videoUrl || file.type === "video/mp4") {
                    return (
                      <div
                        style={{ position: "relative" }}
                        className="ant-image"
                        key={current}
                      >
                        <div
                          style={{
                            width: 90,
                            height: 90,
                            borderRadius: 8,
                            zIndex: 2,
                          }}
                          className="ant-image-mask"
                        >
                          <Flex gap={12}>
                            <EyeOutlined
                              style={{ fontSize: 20 }}
                              onClick={() => setCurrentVisible(current)}
                            />
                            <DeleteOutlined
                              style={{ fontSize: 20 }}
                              onClick={() => onMediaDelete(current)}
                            />
                          </Flex>
                        </div>

                        <PlayCircleOutlined
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            zIndex: 1,
                            color: "white",
                            transform: "translate(-50%, -50%)",
                            fontSize: 32,
                          }}
                        />

                        <video
                          src={file.videoUrl || file.filename}
                          width={90}
                          height={90}
                          style={{ objectFit: "cover", borderRadius: 8 }}
                        />

                        <Image
                          src={file.videoUrl || file.filename}
                          width={0}
                          height={0}
                        />
                      </div>
                    );
                  }

                  return (
                    <Image
                      width={90}
                      height={90}
                      src={getFilePreview(file) || file.filename}
                      style={{ objectFit: "cover", borderRadius: 8 }}
                      alt=""
                      key={current}
                      preview={{
                        mask: (
                          <Flex gap={12}>
                            <EyeOutlined
                              style={{ fontSize: 20 }}
                              onClick={() => setCurrentVisible(current)}
                            />
                            <DeleteOutlined
                              style={{ fontSize: 20 }}
                              onClick={() => onMediaDelete(current)}
                            />
                          </Flex>
                        ),
                        maskClassName: "rounded-lg",
                      }}
                    />
                  );
                })}
              </Flex>
            </Image.PreviewGroup>
          </>
        ),
      });
    }

    if (showNote) {
      defaultItems.push({
        dot: <InfoCircleOutlined style={{ fontSize: 20 }} />,
        children: (
          <>
            <span style={{ fontWeight: "bold", fontSize: 16 }}>Notes</span>

            <Form.Item
              name={notes.name}
              style={{ marginTop: 12 }}
              initialValue={notes.initialValue}
              rules={[
                {
                  required: true,
                  max: 2048,
                  message: "Max length 2048 characters!",
                },
              ]}
            >
              <Input.TextArea placeholder="For notes" />
            </Form.Item>
          </>
        ),
      });
    }

    return defaultItems;
  }, [
    showNote,
    textToolValue,
    fileList,
    currentVisible,
    activeTool,
    notes,
    token,
  ]);

  return (
    <>
      {Boolean(items.length) && (
        <Timeline
          items={items}
          style={{ marginLeft: 12, marginTop: showActions ? 0 : 12 }}
        />
      )}

      {showActions && (
        <Flex gap={8}>
          <Button onClick={() => setShowNote((prevState) => !prevState)}>
            {showNote ? "Hide notes" : "Add notes"}
          </Button>
          <Button onClick={() => setShowCamera((prevState) => !prevState)}>
            Take a photo or video
          </Button>

          <Upload
            beforeUpload={async (file) => {
              if (file.type?.startsWith("video")) {
                Object.assign(file, {
                  videoUrl: URL.createObjectURL(file),
                });

                return;
              }

              Object.assign(file, {
                base64: await getBase64(file),
              });
            }}
            customRequest={() => null}
            showUploadList={false}
            onChange={({ fileList }) =>
              setFileList((prevState) => [
                ...prevState,
                ...fileList.slice(prevState.filter((i) => "uid" in i).length),
              ])
            }
            multiple
          >
            <Button>Upload media</Button>
          </Upload>
        </Flex>
      )}

      {showCamera && (
        <CameraCapture
          onClose={() => setShowCamera(false)}
          onAddMedia={(photo: CameraMedia) =>
            setFileList((prevState) => [...prevState, photo])
          }
        />
      )}
    </>
  );
};
