import { App, Form, Input } from "antd";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import {
  handleRTKQueryError,
  useSetInspectionHoldFlagMutation,
} from "../../api";
import { useEffect, useState } from "react";

interface ISetOnHoldFlag {
  isOnHold: boolean;
  inspectionId: number;
}

export const SetOnHoldFlag = ({ inspectionId, isOnHold }: ISetOnHoldFlag) => {
  const { notification } = App.useApp();
  const { modal } = App.useApp();
  const [form] = Form.useForm();

  const [
    setInspectionOnHoldMutation,
    {
      isSuccess: isSetInspectionOnHoldSuccess,
      error: setInspectionOnHoldError,
    },
  ] = useSetInspectionHoldFlagMutation();

  useEffect(() => {
    if (isSetInspectionOnHoldSuccess) {
      notification.success({ message: "Inspection was successfully changed" });

      form.resetFields();
    }
  }, [isSetInspectionOnHoldSuccess, notification]);

  useEffect(() => {
    if (setInspectionOnHoldError) {
      notification.error({
        message: handleRTKQueryError(setInspectionOnHoldError),
      });
    }
  }, [setInspectionOnHoldError, notification]);

  if (isOnHold) {
    return (
      <PlayCircleOutlined
        className="with-action"
        onClick={() =>
          modal.confirm({
            title: "Set on hold flag",
            content: (
              <div style={{ margin: "8px 0" }}>
                Are you sure you want to resume this inspection?
              </div>
            ),
            onOk: () =>
              setInspectionOnHoldMutation({
                inspectionId,
                onHold: false,
              }),
          })
        }
      />
    );
  }

  return (
    <PauseCircleOutlined
      className="with-action"
      onClick={() =>
        modal.confirm({
          title: "Set on hold flag",
          content: (
            <Form form={form} style={{ margin: "1rem 0 3rem" }}>
              <Form.Item
                name="reason"
                label="Are you sure you want to set this inspection as on hold?"
                layout="vertical"
              >
                <Input placeholder="Write a reason" />
              </Form.Item>
            </Form>
          ),
          onOk: () => {
            const values = form.getFieldsValue();

            setInspectionOnHoldMutation({
              ...values,
              inspectionId,
              onHold: true,
            });
          },
          width: 500,
        })
      }
    />
  );
};
