import { Divider, Flex, Form, Grid, Input } from "antd";
import { QuestionTypes } from "lib/types";
import { TQuestionOption } from "api/templates.types";
import { CheckboxButton } from "../CheckboxButton";
import { RadioButton } from "../RadioButton";
import { Breakpoint } from "antd/es/_util/responsiveObserver";

interface IInspectionCheckableComponent {
  field: TQuestionOption;
  type: QuestionTypes;
  disabled?: boolean;
}

export const InspectionCheckableComponent = ({
  field,
  type,
  disabled,
}: IInspectionCheckableComponent) => {
  const Component =
    type === QuestionTypes.CHECKBOX ? CheckboxButton : RadioButton;

  const breakpoints = Grid.useBreakpoint();

  const getOptionWidth = ({
    xxl,
    xl,
    md,
  }: Partial<Record<Breakpoint, boolean>>) => {
    if (xxl) return "24.5%";

    if (xl) return "33%";

    if (md) return "49%";

    return "100%";
  };

  return (
    <Flex align="center" style={{ width: getOptionWidth(breakpoints) }}>
      <Flex
        justify="space-between"
        align="center"
        style={{
          width: "100%",
        }}
      >
        <Component field={field} disabled={disabled}>
          {field.text}
        </Component>
      </Flex>

      <Flex align="center" justify="space-between" style={{ width: "100%" }}>
        {field.hasResponse && (
          <>
            <Divider type="vertical" />
            <Form.Item name={`${field.id}_response`} noStyle>
              <Input placeholder="Additonal" size="small" />
            </Form.Item>
          </>
        )}
      </Flex>
    </Flex>
  );
};
