import { Form, FormInstance, Modal, Select } from "antd";
import { useGetSampleTypesQuery } from "api";
import { SampleType } from "lib";
import { useEffect } from "react";

const formName = "inspections-sample-form";

interface IAddSamplesModal {
  onClose: () => void;
  parentForm: FormInstance;
  pageId?: number;
  sampleIdx?: number;
}

export const AddSamplesModal = ({
  onClose,
  parentForm,
  pageId,
  sampleIdx,
}: IAddSamplesModal) => {
  const [form] = Form.useForm();

  const { data: samplesTypesData } = useGetSampleTypesQuery({ pageSize: 100 });
  const samples = Form.useWatch("samples", parentForm);

  const sampleKey =
    sampleIdx !== undefined ? sampleIdx : samples?.length ? samples.length : 0;

  useEffect(() => {
    if (sampleIdx !== undefined) {
      form.setFieldsValue({
        sampleTypeId: samples?.[sampleIdx]?.sampleTypeId,
        name: samples?.[sampleIdx]?.name,
      });
    }
  }, [form, sampleIdx, samples]);

  const onValuesChange = (values: SampleType) => {
    if ("sampleTypeId" in values) {
      if (values.sampleTypeId) {
        const foundedSampleTypeName = samplesTypesData?.data?.find(
          (sampleType) => sampleType.id === values.sampleTypeId
        )?.name;

        const foundedSampleTypesLength = samples?.filter(
          (sampleType: SampleType) =>
            sampleType.sampleTypeId === values.sampleTypeId
        )?.length;

        const name = !foundedSampleTypesLength
          ? foundedSampleTypeName
          : `${foundedSampleTypeName}#${foundedSampleTypesLength + 1}`;

        form.setFieldValue("name", name);
      }
    }
  };
  const handleSaveSample = () => {
    parentForm.setFieldValue(["samples", sampleKey], {
      ...form.getFieldsValue(),
      pageId,
    });

    onClose();
  };

  return (
    <Modal
      open
      onCancel={onClose}
      title="Add sample"
      styles={{ body: { margin: "2rem 0" } }}
      destroyOnClose
      afterClose={form.resetFields}
      okButtonProps={{
        htmlType: "submit",
        form: formName,
      }}
    >
      <Form
        form={form}
        name={formName}
        onValuesChange={onValuesChange}
        onFinish={handleSaveSample}
        layout="vertical"
      >
        <Form.Item
          label="Type"
          name="sampleTypeId"
          rules={[{ required: true }]}
        >
          <Select
            options={samplesTypesData?.data.map((sample) => ({
              value: sample.id,
              label: sample.name,
            }))}
            placeholder="Choose a type"
          />
        </Form.Item>

        <Form.Item name="name" style={{ display: "none" }} />
      </Form>
    </Modal>
  );
};
