import { App, Button, Card, Flex, Input } from "antd";
import { TSummary } from "../../../api/inspections.types";
import { useSaveSummaryCommentMutation } from "../../../api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "../../../lib";

interface ISummaryCommentsSection {
  summaryData?: TSummary;
}

export const SummaryCommentsSection = ({
  summaryData,
}: ISummaryCommentsSection) => {
  const params = useParams<{ id: string }>();
  const { notification } = App.useApp();

  const [comment, setComment] = useState<string>();

  const [saveCommentMutation, { isSuccess, error, isLoading }] =
    useSaveSummaryCommentMutation();

  const onSaveClick = () =>
    saveCommentMutation({ id: Number(params.id), message: comment });

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: "Comment was successfully saved" });
      setComment(undefined);
    }
  }, [isSuccess, notification]);

  useEffect(() => {
    if (error) {
      notification.error({ message: "We can't save comment right now" });
    }
  }, [error, notification]);

  return (
    <Card className="pdf-hide" title="Comments">
      <Flex gap={24} vertical>
        <Flex gap={8} vertical>
          {summaryData?.inspectionData.inspectionMessages.map((message) => (
            <div
              style={{
                border: "1px solid var(--ant-color-border)",
                borderRadius: 8,
                padding: 12,
              }}
            >
              <div>{message.message}</div>
              <div
                style={{
                  fontSize: 12,
                  color: "var(--ant-color-text-tertiary)",
                }}
              >
                {message.createdAt && format(message.createdAt)}
              </div>
            </div>
          ))}
        </Flex>

        <Flex vertical gap={12}>
          <div style={{ fontWeight: "bold" }}>Write a comment:</div>

          <Input.TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Write comment here"
          />

          <Flex justify="flex-end">
            <Button type="primary" onClick={onSaveClick} loading={isLoading}>
              Save comment
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
