import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig, IUser, IWithPagination } from "./index";
import { defaultQS } from "lib";
import { TTemplate, TTemplateShort } from "./templates.types";
import {TInspectionClient} from "./inspections.types";

export const templatesApi = createApi({
  ...defaultApiConfig,
  reducerPath: "templatesApi",
  tagTypes: ["Templates", "Template", "Templates Infinite"],
  endpoints: (builder) => ({
    getTemplates: builder.query<
      IWithPagination<TTemplateShort>,
      Record<string, any>
    >({
      query: (params) => `templates?${defaultQS(params)}`,
      providesTags: ["Templates"],
    }),
    getInfiniteTemplates: builder.query<
      IWithPagination<{ label: string, value: number }>,
      Record<string, any>
    >({
      query: (params) => `templates?${defaultQS(params)}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        if (otherArgs.arg.search) {
          currentCacheData.pagination = responseData.pagination;
          currentCacheData.data = responseData.data;

          return;
        }

        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (
        baseQueryReturnValue: IWithPagination<TTemplateShort>
      ) => ({
        data: baseQueryReturnValue.data.map((template) => ({
          label: template.name,
          value: template.id,
        })),
        pagination: baseQueryReturnValue.pagination,
      }),
      providesTags: ["Templates Infinite"],
    }),
    getTemplate: builder.query<TTemplate, { id: number }>({
      query: ({ id }) => `templates/${id}`,
      providesTags: ["Template"],
    }),
    createTemplate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: "templates",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Templates"],
    }),
    updateTemplate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `templates/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Templates", "Template"],
    }),
    deleteTemplate: builder.mutation({
      query: (id) => ({
        url: `templates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Templates"],
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  useGetInfiniteTemplatesQuery,
} = templatesApi;
