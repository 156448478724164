import { TablePaginationConfig } from "antd";
import { PaginationTypes } from "api";

export const defaultPaginationState = { page: 1, pageSize: 50 };

export const defaultPaginationConfig = (
  pagination?: PaginationTypes
): TablePaginationConfig => {
  return {
    showSizeChanger: true,
    total: pagination?.totalItems,
    current: pagination?.current,
    defaultPageSize: 50,
  };
};
