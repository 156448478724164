import { useLayoutEffect, useRef, useState } from "react";

export const useImage = (
  url: string,
  crossOrigin?: string,
  referrerpolicy?: string
): [HTMLImageElement | undefined, string] => {
  const statusRef = useRef("loading");
  const imageRef = useRef<HTMLImageElement>();

  const [_, setStateToken] = useState(0);

  const oldUrl = useRef<string>();
  const oldCrossOrigin = useRef<string>();
  const oldReferrerPolicy = useRef<string>();

  if (
    oldUrl.current !== url ||
    oldCrossOrigin.current !== crossOrigin ||
    oldReferrerPolicy.current !== referrerpolicy
  ) {
    statusRef.current = "loading";
    imageRef.current = undefined;
    oldUrl.current = url;
    oldCrossOrigin.current = crossOrigin;
    oldReferrerPolicy.current = referrerpolicy;
  }

  useLayoutEffect(() => {
    if (!url) return;

    const img = document.createElement("img");

    function onload() {
      statusRef.current = "loaded";
      imageRef.current = img;
      setStateToken(Math.random());
    }

    function onerror() {
      statusRef.current = "failed";
      imageRef.current = undefined;
      setStateToken(Math.random());
    }

    img.addEventListener("load", onload);
    img.addEventListener("error", onerror);
    crossOrigin && (img.crossOrigin = crossOrigin);
    referrerpolicy && (img.referrerPolicy = referrerpolicy);
    img.src = url;

    return function cleanup() {
      img.removeEventListener("load", onload);
      img.removeEventListener("error", onerror);
    };
  }, [url, crossOrigin, referrerpolicy]);

  return [imageRef.current, statusRef.current];
};
