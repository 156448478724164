import { useContext, useEffect, useMemo, useState } from "react";
import { App, Button, Collapse, Flex, Form, Modal } from "antd";
import { EditOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { TInspectionPage } from "api/templates.types";
import { InspectionQuestions } from "./InspectionQuestions";
import {
  useGetInfiniteProductsQuery,
  useSetInspectionAnswersMutation,
} from "api";
import { AttachedFile, getRouteByName, SampleType } from "lib";
import { useAppSelector } from "store";
import { MediaUploaderContext } from "contexts";
import { SmartSelectPagination } from "../SmartSelectWithPagination";
import { AddSamplesModal } from "./AddSamplesModal";
import { AdditionalQuestionSection } from "./AdditionalQuestionSection";

interface IInspectionPage {
  page: TInspectionPage;
  viewMode?: boolean;
  onAddRoomClick?: () => void;
  submitPageId?: number;
  onAfterSubmit: () => void;
}

export const InspectionPage = ({
  page,
  viewMode = false,
  onAddRoomClick,
  submitPageId,
  onAfterSubmit,
}: IInspectionPage) => {
  const params = useParams<{ id: string }>();
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const samples = Form.useWatch("samples", form);

  const [isFinished, setIsFinished] = useState(false);
  const [showSampleModal, setShowSampleModal] = useState(false);
  const [sampleIdx, setSampleIdx] = useState<number>();

  const { setFilesToUpload } = useContext(MediaUploaderContext);

  const inspectionsQueries = useAppSelector(
    (state) => state.inspectionsApi.queries
  );

  const [saveInspectionAnswersMutation, { isLoading, isSuccess, error }] =
    useSetInspectionAnswersMutation();

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: "Page was successfully saved!" });

      if (isFinished) navigate(getRouteByName("InspectionsPage"));
    }
  }, [isSuccess, isFinished]);

  useEffect(() => {
    if (error) {
      if (!navigator.onLine) {
        navigate(getRouteByName("InspectionsPage"));
      }

      notification.error({ message: "We could not save the page" });
    }
  }, [error, inspectionsQueries, notification, navigate]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (submitPageId === page.id) {
      onSubmit();

      onAfterSubmit();
    }
  }, [submitPageId]);

  const onSubmit = (finish = false) => {
    const values = form.getFieldsValue();

    const filteredValues = values?.inspectionAnswers?.filter((i: any) => i);
    const filteredSamplesValues = values?.samples?.filter((i: any) => i);

    filteredValues?.map((i: AttachedFile, key: number) => {
      filteredValues[key].mediaFiles = [];

      if (filteredValues[key].mediaFiles) {
        filteredValues[key].mediaFiles = filteredValues[key].mediaFiles.map(
          (media: AttachedFile) => ({
            ...media,
            filename: `${process.env.REACT_APP_S3_URL}/${params.id}/${media.name}`,
          })
        );
      }
    });

    filteredSamplesValues?.map((i: AttachedFile, key: number) => {
      filteredSamplesValues[key].mediaFiles = [];

      if (filteredSamplesValues[key].mediaFiles) {
        filteredSamplesValues[key].mediaFiles = filteredSamplesValues[
          key
        ].mediaFiles.map((media: AttachedFile) => ({
          ...media,
          filename: `${process.env.REACT_APP_S3_URL}/${params.id}/${media.name}`,
        }));
      }
    });

    setFilesToUpload &&
      setFilesToUpload(
        filteredValues.flatMap((i: any) =>
          i?.mediaFiles.map((media: AttachedFile) => ({
            ...media,
            name: `${params.id}/${media.name}`,
          }))
        )
      );

    form.validateFields().then(() =>
      saveInspectionAnswersMutation({
        id: params.id,
        ...(finish ? { completedAt: dayjs().format() } : {}),
        ...values,
        inspectionAnswers: filteredValues,
        samples: filteredSamplesValues,
      })
    );

    finish && setIsFinished(true);
  };

  const collapseItems = useMemo(
    () =>
      samples?.map((sample: SampleType, index: number) => ({
        key: index,
        label: (
          <Flex gap={8}>
            {sample.name}
            <EditOutlined
              onClick={(event) => {
                event.stopPropagation();
                setShowSampleModal(true);
                setSampleIdx(index);
              }}
            />
          </Flex>
        ),
        children: (
          <Form.Item
            name={[index, "mediaFiles"]}
            initialValue={sample.mediaFiles}
          >
            <AdditionalQuestionSection
              notes={{
                name: [index, "notes"],
                initialValue: sample.notes,
              }}
            />
          </Form.Item>
        ),
      })),

    [samples]
  );

  const handleCloseSampleModal = () => {
    setShowSampleModal(false);
    setSampleIdx(undefined);
  };

  return (
    <Form form={form} layout="vertical">
      <Form.List name="inspectionAnswers">
        {() => (
          <InspectionQuestions
            form={form}
            questions={page.inspectionQuestions}
            key={page.id}
          />
        )}
      </Form.List>

      <Form.List name="samples">
        {() => (
          <>
            {!!samples?.length && (
              <Collapse items={collapseItems} style={{ marginBottom: 16 }} />
            )}
          </>
        )}
      </Form.List>

      {!viewMode && (
        <Flex justify="space-between" gap={12}>
          <SmartSelectPagination
            query={useGetInfiniteProductsQuery}
            mode="tags"
            placeholder="Select some related products"
            style={{ width: 300 }}
            onChange={(values) =>
              form.setFieldValue(
                "products",
                values.map((value: number) => ({
                  productId: value,
                  pageId: page.id,
                }))
              )
            }
          />

          <Button
            onClick={() => setShowSampleModal(true)}
            style={{
              position: "fixed",
              bottom: 100,
              right: 40,
              zIndex: 1,
              borderRadius: "100%",
              height: 80,
              opacity: 0.7,
            }}
            type="primary"
            size="large"
          >
            Sample
          </Button>

          <Form.Item name="products" noStyle hidden />

          <Flex gap={12}>
            <Button onClick={onAddRoomClick} type="primary">
              Add room
            </Button>

            <Button
              type="primary"
              onClick={() =>
                Modal.confirm({
                  title: "Are you sure that all rooms have been completed?",
                  icon: <ExclamationCircleFilled />,
                  okText: "Yes",
                  cancelText: "No",
                  cancelButtonProps: {
                    style: {
                      color: "#ff4d4f",
                      borderColor: "#ff4d4f",
                    },
                  },
                  okButtonProps: {
                    style: {
                      color: "#73d13d",
                      backgroundColor: "transparent",
                      borderColor: "#73d13d",
                    },
                  },
                  onOk: () => onSubmit(true),
                })
              }
              loading={isLoading}
            >
              Finish Inspection
            </Button>
          </Flex>
        </Flex>
      )}
      {showSampleModal && (
        <AddSamplesModal
          onClose={handleCloseSampleModal}
          parentForm={form}
          pageId={page.id}
          sampleIdx={sampleIdx}
        />
      )}
    </Form>
  );
};
