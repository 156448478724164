import { Divider, Flex, Form, Input } from "antd";
import { QuestionTypes } from "lib/types";
import { TQuestionOption } from "api/templates.types";
import { CheckboxButton } from "../CheckboxButton";
import { RadioButton } from "../RadioButton";

interface IViewCheckableComponent {
  field: TQuestionOption;
  type: QuestionTypes;
}

export const ViewCheckableComponent = ({
  field,
  type,
}: IViewCheckableComponent) => {
  const Component =
    type === QuestionTypes.CHECKBOX ? CheckboxButton : RadioButton;

  return (
    <Flex align="center" style={{ width: "49%" }}>
      <Flex
        justify="space-between"
        align="center"
        style={{
          borderRadius: 4,
          width: "100%",
        }}
      >
        <Component field={field}>{field.text}</Component>
      </Flex>

      <Flex align="center" justify="space-between" style={{ width: "100%" }}>
        {field.hasResponse && (
          <>
            <Divider type="vertical" />
            <Form.Item name={`${field.id}_response`} noStyle>
              <Input placeholder="Additonal" size="small" />
            </Form.Item>
          </>
        )}
      </Flex>
    </Flex>
  );
};
