import { Checkbox, Flex, FormInstance, theme } from "antd";
import { TInspectionQuestion } from "api/templates.types";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface IInspectionInstructionQuestion {
  question: TInspectionQuestion;
  form: FormInstance;
}

export const InspectionInstructionQuestion = ({
  question,
  form,
}: IInspectionInstructionQuestion) => {
  const { token } = theme.useToken();

  return (
    <Flex gap={12} vertical>
      <Flex align="center" gap={12}>
        <ExclamationCircleOutlined
          style={{ fontSize: 20, color: token.yellow7 }}
        />

        <span>{question.text}</span>
      </Flex>

      <Checkbox
        onChange={(e) =>
          form.setFields([
            {
              name: ["inspectionAnswers", question.id, "flagged"],
              value: e.target.checked,
            },
          ])
        }
      >
        Priority
      </Checkbox>
    </Flex>
  );
};
