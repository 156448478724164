import { createSlice } from "@reduxjs/toolkit";

interface AppSettingsState {
  showSideBar: boolean;
  onLine: boolean
}

const initialState: AppSettingsState = {
  showSideBar: false,
  onLine: navigator.onLine
};

export const appSettingsSlice = createSlice({
  name: "app-settings",
  initialState,
  reducers: {
    changeShowSideBar: (state) => ({
      ...state,
      showSideBar: !state.showSideBar,
    }),
    setShowSideBar: (state, { payload }) => ({
      ...state,
      showSideBar: payload,
    }),
    setOnLine: (state, { payload }) => ({
      ...state,
      onLine: payload
    })
  },
});

export const { changeShowSideBar, setShowSideBar, setOnLine } = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
