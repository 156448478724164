import { FormInstance, Select, Tag } from "antd";
import { useGetReadyAnswersQuery } from "../../api";

interface IPredefinedQuestionOptions {
  form: FormInstance;
  nameToChange: (string | number)[];
}

const COLORS = {
  green: "#73d13d",
  red: "#ff4d4f",
  orange: "#ffa940",
  gold: "#ffc53d",
  gray: "#bfbfbf",
};

export const PredefinedQuestionOptions = ({
  form,
  nameToChange,
}: IPredefinedQuestionOptions) => {
  const { data } = useGetReadyAnswersQuery({});

  return (
    <Select
      options={data?.data.map((options) => createOption(options.options))}
      style={{ width: "100%" }}
      placeholder="Ready answers"
      onChange={(val) => {
        const newFields = JSON.parse(val);

        form.setFieldValue(nameToChange, newFields);
      }}
    />
  );
};

const createOption = (tags: { text: string; color?: string }[]) => ({
  label: tags.map(
    (tag, index) =>
      tag && (
        <Tag
          key={index}
          color={tag.color}
          bordered={false}
          style={{
            color: "black",
            backgroundColor: tag.color ? tag.color : COLORS.gray,
            borderRadius: "0.75rem",
            display: "inline-flex",
            alignItems: "center",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100px",
          }}
        >
          {tag.text}
        </Tag>
      )
  ),
  value: JSON.stringify(
    tags.map(
      (tag, index) =>
        tag && {
          text: tag.text,
          color: tag.color || "",
          orderOption: index,
        }
    )
  ),
});
