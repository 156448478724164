import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig } from "./index";
import { defaultQS } from "lib";

export const reportsApi = createApi({
  ...defaultApiConfig,
  reducerPath: "reportsApi",
  endpoints: (builder) => ({
    getAllInspections: builder.query({
      query: ({ id, ...params }) => `inspections/report?${defaultQS(params)}`,
    }),
    getByInspectionType: builder.query({
      query: ({ id, ...params }) =>
        `inspections/type-report?${defaultQS(params)}`,
    }),
  }),
});

export const { useGetAllInspectionsQuery, useGetByInspectionTypeQuery } =
  reportsApi;
